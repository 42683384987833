import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Avatar from "../../../assets/avatar.svg";
import ContactDetails from "../../../components/LeadCenter/LeadDetails/ContactDetails/ContactDetails";
import DetailTabs from "../../../components/LeadCenter/LeadDetails/DetailTabs/DetailTabs";
import { CallingIcon, CloseIcon } from "../../../components/icons/svg";
import {
  getPowerDialerCampaign,
  getPowerDialerContacts,
  lockPowerDialer,
  skipPWContact,
  togglePWCampaign,
  updatePowerDialer,
} from "../../../utils/api/power-dialer.api";
import { useHistoryDispatch } from "../../../utils/context/HistoryContext";
import {
  handleHangUp,
  useTwilioDispatch,
  useTwilioState,
} from "../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DialerSettings from "../DialerSettings";
import LineModal from "./LineModal";
import { Select } from "../../common";
import { warmUpOptions } from "../../../config";
import Loader from "../../common/Spinner";
import { fetchSettings } from "../../../utils/api/dealership-setting.api";
import { isTimeBetween } from "../../../utils";
import { disableOpenPhone } from "../../../utils/api/dealership-setting.api";
import moment from "moment";

const durationMapping: any = {
  after_hang_up: 3000,
  fifteen_seconds: 15000,
  thirty_seconds: 30000,
};

export default function PowerDialerDetail() {
  const navigate = useNavigate();
  const [startDialing, setStartDialing] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [contacts, setContacts] = useState<any[]>([]);
  const [foundIndex, setFoundIndex] = useState(0);
  const [clickedRow, setClickedRow] = useState<any | null>(null);
  const [from, setFrom] = useState("");
  const [isLineModalOpen, setIsLineOpen] = useState(false);

  const user = useUserInfo("user");
  const toast = useToast();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const shouldStart = searchParams.get("shouldStart");

  useEffect(() => {
    if (!!contacts?.length && shouldStart === "true") {
      handleTogglePWCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStart, contacts]);

  const dispatch = useTwilioDispatch();

  useEffect(() => {
    if (/^\/power-dialer\/\d+$/.test(window.location.pathname)) {
      dispatch({
        type: "SET_SELECTED_CAMPAIGN",
        payload: { selectedCampaign: String(id || "") },
      });
    }
  }, [dispatch, id]);

  const historyDispatch = useHistoryDispatch();
  const {
    call,
    startCallTimestamp,
    shouldUpdateCampaignContacts,
    campaignParams,
    campaignInterval,
  } = useTwilioState();

  const { data: powerDialerData, refetch: refetchData } = useQuery({
    queryKey: ["fetchPowerDialerCampaign", id],
    queryFn: () =>
      getPowerDialerCampaign({
        dealership_id: user?.dealership?.id,
        id,
      }),
    retry: false,
  });
  const { data: isOpenPhone } = useQuery({
    queryKey: ["openPhone", user?.dealership?.id],
    queryFn: disableOpenPhone,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        await lockPowerDialer({
          dealership_id: user?.dealership?.id,
          campaignId: id,
          status: payload.status,
          userId: user?.id,
        });
      } catch (error) {
        
      }
    },
  });

  const toggleMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        await togglePWCampaign({
          dealership_id: user?.dealership?.id,
          id: id,
          status: payload.status,
          userId: user?.id,
        });
        refetchData();
      } catch (error) {
        throw error;
      }
    },
  });

  const skipMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        await skipPWContact({
          dealership_id: user?.dealership?.id,
          id: id,
          contactId: payload.contactId,
        });
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Contact skipped, refreshing the listing.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetchData();
    },
    onError: (error: any) => {
      toast({
        description: `Error skipping the contact: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const updateMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updatePowerDialer({
          dealershipId: user?.dealership?.id,
          id,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const onChange = async (values: any) => {
    await updateMutation.mutateAsync({
      power_dialer_campaign: {
        warmup_duration: values.value,
      },
    });
  };

  const { data, refetch } = useQuery({
    queryKey: ["fetchDialerContacts", id, page],
    queryFn: () =>
      getPowerDialerContacts({
        dealership_id: user?.dealership?.id,
        id,
        page,
        perPage: 10,
      }),
    retry: false,
    onSuccess(data) {
      if (!data?.data?.contacts?.length) {
        return navigate("/power-dialer");
      }
      if (page === 1 && campaignParams.running) {
        dispatch({
          type: "SET_CAMPAIGN_PARAMS",
          payload: {
            campaignParams: {
              ...campaignParams,
              contacts: data?.data?.contacts,
              running: campaignParams.running
                ? campaignParams.running
                : !!contacts?.length,
            },
          },
        });
      }
      setClickedRow(page === 1 ? data?.data?.contacts?.[0] : clickedRow);
      setFoundIndex(foundIndex < 0 ? 0 : foundIndex);
      setContacts((prev: any) =>
        page === 1
          ? [...data?.data?.contacts]
          : [...prev, ...data?.data?.contacts]
      );
    },
  });

  const { data: settingsData } = useQuery({
    queryKey: ["fetchGeneralSettings", user?.dealership?.id],
    queryFn: () => fetchSettings(user?.dealership?.id, "general_settings"),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (shouldUpdateCampaignContacts) {
      setPage(1);
      refetch();
      dispatch({
        type: "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS",
        payload: { shouldUpdateCampaignContacts: false },
      });
    }
  }, [dispatch, refetch, shouldUpdateCampaignContacts]);

  const handleCampaignLock = useCallback(
    async (status: string) => {
      await mutation.mutateAsync({ status });
    },
    [mutation]
  );

  useEffect(() => {
    handleCampaignLock("lock");

    return () => {
      !campaignParams.running && handleCampaignLock("unlock");
      dispatch({
        type: "SET_SHOULD_UPDATE_PW_LISTING",
        payload: { shouldUpdatePWListing: true },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignParams, campaignParams.running]);

  const handlePausePWCampaign = () => {
    dispatch({
      type: "SET_CAMPAIGN_PARAMS",
      payload: {
        campaignParams: {
          contacts: [],
          from: from || "",
          running: false,
          id: "",
          warmUpDuration: 0,
          firstContact: true,
        },
      },
    });
    clearInterval(campaignInterval);
    dispatch({
      type: "SET_CAMPAIGN_INTERVAL",
      payload: {
        campaignInterval: null,
      },
    });
    if (call) {
      handleHangUp(dispatch, call, startCallTimestamp);
      historyDispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    }
    setFrom("");
  };

  const handleTogglePWCampaign = async () => {
    await toggleMutation.mutateAsync({
      status:
        powerDialerData?.data?.status === "in_progress" ? "pause" : "resume",
    });

    if (powerDialerData?.data?.status !== "in_progress") {
      dispatch({
        type: "SET_CAMPAIGN_PARAMS",
        payload: {
          campaignParams: {
            contacts: contacts,
            from: powerDialerData?.data?.line,
            running: true,
            id: id || "",
            warmUpDuration:
              durationMapping[powerDialerData?.data?.warmup_duration],
            firstContact: true,
            business_hours: isOpenPhone?.data?.within_hours,
          },
        },
      });
      toast({
        description: "Starting Power Dialer Campaign...",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      handlePausePWCampaign();
    }
  };

  const handleContactSkip = async (id: string) => {
    await skipMutation.mutateAsync({ contactId: id });
    setPage(1);
    refetch();
  };

  const handleExitPWCampaign = async () => {
    await toggleMutation.mutateAsync({
      status: "pause",
    });

    handlePausePWCampaign();
    navigate("/power-dialer");
  };

  return (
    <VStack w="100%" h="100%">
      <HStack
        padding="1rem 1.5rem"
        w="100%"
        background="white"
        justifyContent="space-between"
      >
        {updateMutation.isLoading && <Loader />}
        <HStack>
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => navigate(-1)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
          </Button>
          <Text textStyle="h4" fontWeight="700">
            SimpSocial Power Dialer - {powerDialerData?.data?.name}
          </Text>
        </HStack>

        <Button
          variant="outline"
          color="var(--red-700)"
          onClick={handleExitPWCampaign}
        >
          Exit Campaign
        </Button>
      </HStack>
      <Box padding="1rem 1.5rem" h="100%" w="100%" position="relative">
        <HStack
          h="100%"
          w="100%"
          position="relative"
          alignItems="flex-start"
          gap="0"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          {/* { data?.data?. } */}
          <VStack w="20rem" h="100%" borderRight="1px solid var(--grey-300)">
            {contacts?.length ? (
              <HStack w="100%" padding="1rem" paddingBottom={0}>
                {powerDialerData?.data?.status === "in_progress" ? (
                  <Button
                    w="100%"
                    onClick={() => handleTogglePWCampaign()}
                    background="var(--red-600)"
                    borderColor="var(--red-600)"
                  >
                    Pause Power Dialer
                  </Button>
                ) : (
                  <Tooltip
                    hasArrow
                    label={
                      !isOpenPhone?.data?.within_hours
                        ? "Outside business hours, outbound calls are not permitted."
                        : ""
                    }
                    borderRadius="0.5rem"
                    placement="top"
                    background="var(--grey-900)"
                    padding="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    color="white"
                  >
                    <Button
                      w="100%"
                      background="var(--secondary-600)"
                      borderColor="var(--secondary-600)"
                      onClick={() => handleTogglePWCampaign()}
                      isDisabled={!isOpenPhone?.data?.within_hours}
                    >
                      Start Power Dialer
                    </Button>
                  </Tooltip>
                )}
              </HStack>
            ) : (
              <></>
            )}
            <HStack w="100%" padding="1rem">
              <Select
                label="Warm Up Duration"
                options={warmUpOptions}
                placeholder="Select"
                variant="default"
                w="100%"
                onChange={onChange}
                value={powerDialerData?.data?.warmup_duration}
                isDisabled={campaignParams.running}
              />
            </HStack>
            <HStack
              w="100%"
              padding="0rem 1rem 1rem"
              borderBottom="1px solid var(--grey-300)"
            >
              <Button w="100%" onClick={() => setStartDialing(true)}>
                Scripts
              </Button>
            </HStack>
            <div
              id="powerDialerContacts"
              style={{ height: "calc(100vh - 284px)", overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={contacts?.length || 0}
                next={() => {
                  setPage((prev: any) => prev + 1);
                }}
                hasMore={
                  (contacts.length || 0) < (data?.data?.total_records || 0)
                }
                loader={<h4>Loading...</h4>}
                scrollableTarget={"powerDialerContacts"}
              >
                <VStack gap="0">
                  {contacts?.map((item: any, index: number) => (
                    <Box
                      key={item?.id}
                      w="100%"
                      padding="1rem"
                      cursor="pointer"
                      borderBottom="1px solid var(--grey-300)"
                      background={
                        foundIndex === index ? "var(--primary-50)" : ""
                      }
                      onClick={() => {
                        setFoundIndex(index);
                        setClickedRow({
                          ...item,
                          imageUrl: `https://source.unsplash.com/random/1920x1080/?abstract,pattern&${item?.id}`,
                        });
                      }}
                    >
                      <HStack
                        w="100%"
                        justifyContent="space-between"
                        key={item}
                      >
                        <HStack>
                          <Image
                            src={Avatar}
                            width={38}
                            height={38}
                            alt="avatar"
                          />
                          <VStack alignItems="flex-start" gap="0.19rem">
                            <Text textStyle="h6" fontWeight="700">
                              {item.name}
                            </Text>
                            <Text textStyle="h6" color="var(--grey-800)">
                              {item.phone_number}
                            </Text>
                            {/* <HStack>
                          <Text textStyle="h6" color="var(--secondary-600)">
                            New York, NY
                          </Text>
                        </HStack> */}
                          </VStack>
                        </HStack>
                        {campaignParams.running && !index ? (
                          <VStack gap="0.38rem">
                            <VStack
                              padding="0.5rem"
                              background="var(--primary-600)"
                              borderRadius="50%"
                            >
                              <CallingIcon />
                            </VStack>
                            <Text textStyle="captionSmall">Calling..</Text>
                          </VStack>
                        ) : (
                          <VStack
                            padding="0.38rem"
                            borderRadius="0.5rem"
                            border="1px solid var(--grey-300)"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleContactSkip(item.id);
                            }}
                          >
                            <CloseIcon />
                          </VStack>
                        )}
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </InfiniteScroll>
            </div>
          </VStack>

          {data?.data?.contacts?.length < 1 || !data ? (
            <HStack
              width="100%"
              height="calc(100vh - 120px)"
              alignItems="center"
              padding="10rem"
              justifyContent="center"
            >
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                src="https://lottie.host/embed/c13879c0-69af-45b2-89f7-a73beec5a874/GnmxokbVbv.json"
                width="100%"
                height="100%"
              />
            </HStack>
          ) : (
            <HStack alignItems="flex-start" w="100%" gap="0">
              <ContactDetails
                id={clickedRow?.id}
                refetch={() => {
                  setContacts([]);
                  setPage(1);
                  refetch();
                }}
              />

              <DetailTabs clickedRow={clickedRow} />
            </HStack>
          )}
        </HStack>
      </Box>

      {startDialing && (
        <DialerSettings
          isOpen={startDialing}
          onClose={() => setStartDialing(false)}
        />
      )}

      {/* {isLineModalOpen && (
        <LineModal
          line={from}
          setLine={(from: string) => {
            setFrom(from);
            setIsLineOpen(false);
            handleTogglePWCampaign();
          }}
          isOpen={isLineModalOpen}
          onClose={() => setIsLineOpen(false)}
        />
      )} */}
    </VStack>
  );
}
