import { Button, HStack, Image, Text, Tooltip, VStack } from "@chakra-ui/react";
import ClearIcon from "@mui/icons-material/Clear";
import MicOffIcon from "@mui/icons-material/MicOff";
import { useCallback, useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import Avatar from "../../assets/avatar.svg";
import {
  handleHangUp,
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import Stopwatch from "../common/Stopwatch";
import {
  AppointmentIcon,
  ChatIcon,
  MicIcon,
  VoicemailIcon,
} from "../icons/svg";
import { useHistoryDispatch } from "../../utils/context/HistoryContext";
import { useMutation } from "@tanstack/react-query";
import { declineTwoLeggedCall } from "../../utils/api/twilio.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import ActionCable from "actioncable";
import { SERVICES } from "../../config";
import { ActionCableContext } from "../../App";

const IncomingCallWidget = () => {
  const {
    caller,
    startCallTimestamp,
    incomingCall,
    call,
    currentDevice,
    twoLeggedCall,
  } = useTwilioState();
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const { customersPhone, callSid, start } = twoLeggedCall || {};

  const dispatch = useTwilioDispatch();
  const historyDispatch = useHistoryDispatch();

  const callObj = incomingCall ? incomingCall : call;

  const callerNumber = incomingCall
    ? caller
      ? caller
      : call?.customParameters?.get("From")
    : call?.customParameters?.get("To");

  const dealershipId = useUserInfo("dealership")?.id;

  const { cable } = useContext(ActionCableContext);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await declineTwoLeggedCall(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
  });

  const handleTwoLeggedCall = useCallback(() => {
    dispatch({
      type: "START_TWO_LEGGED_CALL",
      payload: {
        twoLeggedCall: {
          customersPhone: "",
          from: "",
          start: false,
          callSid: "",
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    try {
      if (!cable) return;
      cable.subscriptions.create("CallChannel", {
        connected: () => {
          console.log("connected");
        },
        disconnected: () => {
          console.log("disconnected");
        },
        received: (data: any) => {
          // Handle incoming data from Action Cable
          const { call_sid } = data || {};
          if (call_sid === callSid) {
            handleTwoLeggedCall();
            historyDispatch({
              type: "SET_SHOULD_REFETCH",
              payload: { shouldRefetch: true },
            });
          }
        },
      });
    } catch (error) {}
  }, [cable, callSid, handleTwoLeggedCall, historyDispatch]);

  return call || startCallTimestamp || start ? (
    <Draggable
      bounds="parent"
      allowAnyClick
      defaultPosition={{
        x: window.innerWidth * 0.95 - 380,
        y: window.innerHeight * 0.1,
      }}
    >
      <VStack
        borderRadius="1rem"
        zIndex="9999"
        position="absolute"
        w="380px"
        background="#22272D"
        color="white"
        gap="0"
      >
        <HStack
          padding="1rem"
          w="100%"
          borderBottom="1px solid #303740"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <HStack gap="0.88rem">
            <Image
              src={Avatar}
              width="3.5rem"
              height="3.5rem"
              borderRadius="50%"
            />
            <VStack gap="0.23rem" alignItems="flex-start">
              <Text textStyle="h4" fontWeight="600">
                {callerNumber || customersPhone}
              </Text>
              {call && (
                <Text textStyle="h6" fontWeight="400">
                  {startCallTimestamp || start
                    ? "In Progress..."
                    : "Calling..."}
                </Text>
              )}
            </VStack>
          </HStack>
          {(startCallTimestamp || start) && (
            <Stopwatch style={{ color: "#DDEAF7" }} />
          )}
        </HStack>
        <HStack w="100%" justifyContent="space-between" padding="1rem">
          <Tooltip label="Hangup" placement="bottom" zIndex="99999">
            <Button
              variant="none"
              display="flex"
              background="#FF3D33"
              borderRadius="50%"
              padding="0.5rem"
              alignItems="center"
              justifyContent="center"
              disabled={!startCallTimestamp}
              onClick={async () => {
                if (start) {
                  if (callSid)
                    await mutation.mutateAsync({
                      callSid: callSid,
                      dealershipId: dealershipId,
                    });
                  handleTwoLeggedCall();
                } else {
                  handleHangUp(
                    dispatch,
                    callObj,
                    startCallTimestamp,
                    !!incomingCall
                  );
                }

                setIsMicEnabled(true);
                historyDispatch({
                  type: "SET_SHOULD_REFETCH",
                  payload: { shouldRefetch: true },
                });
              }}
            >
              <ClearIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            </Button>
          </Tooltip>
          <HStack>
            <Tooltip
              label={isMicEnabled ? "Mute" : "Unmute"}
              placement="bottom"
              zIndex="99999"
            >
              <Button
                variant="none"
                display="flex"
                borderRadius="50%"
                background="#303740"
                padding="0.8rem 1rem"
                onClick={() => {
                  if (!isMicEnabled) {
                    callObj?.mute(false);
                    setIsMicEnabled(true);
                  } else {
                    callObj?.mute(true);
                    setIsMicEnabled(false);
                  }
                }}
              >
                {isMicEnabled ? <MicIcon /> : <MicOffIcon fontSize="medium" />}
              </Button>
            </Tooltip>
            <Tooltip label="Send SMS" placement="bottom" zIndex="99999">
              <Button
                variant="none"
                display="flex"
                padding="0.9rem 0.7rem"
                borderRadius="50%"
                background="#303740"
                onClick={() => {
                  dispatch({ type: "OPEN_SMS", payload: { openSMS: true } });
                  dispatch({
                    type: "SET_SMS_PARAMS",
                    payload: {
                      smsParams: {
                        called: callerNumber || "",
                        caller: currentDevice.phoneNumber,
                      },
                    },
                  });
                }}
              >
                <ChatIcon color="white" />
              </Button>
            </Tooltip>
            <Tooltip
              label="Create an Appointment"
              placement="bottom"
              zIndex="99999"
            >
              <Button
                variant="none"
                display="flex"
                padding="0.9rem 0.7rem"
                borderRadius="50%"
                background="#303740"
                onClick={() =>
                  dispatch({
                    type: "OPEN_APPOINTMENT",
                    payload: { openAppointment: true },
                  })
                }
              >
                <AppointmentIcon color="white" />
              </Button>
            </Tooltip>
            {/* <Tooltip label="Send Voicemail" placement="bottom" zIndex="99999">
              <Button
                variant="none"
                display="flex"
                padding="0.9rem 0.7rem"
                borderRadius="50%"
                background="#303740"
                disabled
                pointerEvents="none"
              >
                <VoicemailIcon color="white" />
              </Button>
            </Tooltip> */}
          </HStack>
        </HStack>
      </VStack>
    </Draggable>
  ) : (
    <></>
  );
};

export default IncomingCallWidget;
