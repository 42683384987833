import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { NotificationIcon } from "../../icons/svg";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getReminderNotification,
  markReminderAsRead,
} from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
interface RemindersProps {
  page?: number;
  perPage?: number;
  refetch?: boolean;
  onClose?: () => void;
}

export const ReminderCard = ({
  page,
  perPage,
  refetch,
  onClose,
}: RemindersProps) => {
  const { refetchNotifications } = useNotificationContext();
  const dealerId = useUserInfo("dealership");
  const navigate = useNavigate();
  const mutation = useMutation(markReminderAsRead);
  const { data, isLoading } = useQuery({
    queryKey: ["reminderNotification", dealerId?.id, page, perPage, refetch],
    queryFn: getReminderNotification,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: page !== undefined,
  });
  const toast = useToast();
  const handleMarkAsRead = (item: any) => {
    if (!item?.read) {
      mutation.mutate(
        { dId: dealerId?.id, id: item?.id },
        {
          onSuccess: (data: any) => {
            refetchNotifications(); // This will trigger the refetch of the parent's query
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
    if (item?.notification_object?.contact_id) {
      navigate(
        `/leads/lead-details?contact_id=${item?.notification_object?.contact_id}&reminder_id=${item?.notification_object?.id}&active_index=13`
      );
    } else {
      navigate("/meeting-calendar", {
        state: {
          personal: item?.notification_object?.personal,
          reminder_id: item?.notification_object?.id,
        },
      });
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0.59rem"
        maxH="calc(100vh - 450px)"
        overflow="auto"
        padding="1rem"
        pt="0"
      >
        {data?.data?.notifications?.map((item: any) => (
          <HStack
            padding="1rem"
            w="100%"
            key={item}
            background={item?.read ? "white" : "var(--grey-100)"}
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            position="relative"
            onClick={() => {
              handleMarkAsRead(item);
            }}
            cursor="pointer"
          >
            {!item?.read && (
              <Box
                w="0.47rem"
                h="0.47rem"
                borderRadius="50%"
                background="#F44336"
                position="absolute"
                top="0.5rem"
                left="0.5rem"
              ></Box>
            )}
            <Box
              padding="0.5rem"
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              background="white"
              sx={{
                svg: {
                  path: {
                    fill: "var(--secondary-600)",
                  },
                },
              }}
            >
              <NotificationIcon />
            </Box>
            <VStack gap="0.25rem" w="100%" alignItems="flex-start">
              <Text textStyle="h5" fontWeight="500">
                {item?.message}
              </Text>
              {/* <Text
                          textStyle="h6"
                          fontWeight="400"
                          color="var(--grey-800)"
                        >
                          With: Marvin carranza
                        </Text> */}
              <Text textStyle="captionSmall" fontWeight="500">
                {moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")}
                {/* <Box as="span" color="var(--primary-600)">
                            • 6:06 pm
                          </Box> */}
              </Text>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
};
