import { Box } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { CustomRadio } from "../../common";
import DatePicker from "../../common/DateRangePicker";

type ContactDateProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  prefix?: string;
};

function ContactDate({ control, setValue, prefix = "" }: ContactDateProps) {
  const allTime = useWatch({
    control,
    name: `${prefix}filter_and_sort.all_time`,
  });
  const datePicked = useWatch({
    control,
    name: `${prefix}filter_and_sort.datePicked`,
  });
  const createdDate = useWatch({
    control,
    name: `${prefix}filter_and_sort.created_date`,
  });

  const sendOptions = [
    { label: " All Time", value: "all_time" },
    { label: "Custom Date Range", value: "date_range" },
  ];
  const customDateRangeOptions = [
    { label: " Apply On Created Date", value: "created_date" },
    { label: "Association Date", value: "association_date" },
  ];

  return (
    <>
      <Box padding="1rem">
        <CustomRadio
          options={sendOptions}
          value={datePicked ? "date_range" : "all_time"}
          setValue={(val) => {
            if (val === "all_time") {
              setValue(`${prefix}filter_and_sort.all_time`, true);
              setValue(`${prefix}filter_and_sort.datePicked`, false);
              setValue(`${prefix}filter_and_sort.date_range`, {
                start_date: "",
                end_date: "",
              });
              setValue(`${prefix}filter_and_sort.created_date`, false);
              setValue(`${prefix}filter_and_sort.association_date`, false);
            } else {
              setValue(`${prefix}filter_and_sort.datePicked`, true);
              setValue(`${prefix}filter_and_sort.created_date`, true);
              setValue(`${prefix}filter_and_sort.all_time`, false);
            }
          }}
        />
      </Box>

      {datePicked && (
        <>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box padding="1rem">
                  <DatePicker
                    label="Select Date Range"
                    value={{
                      startDate: value.start_date as Date,
                      endDate: value.end_date as Date,
                    }}
                    onChange={(range: { startDate: Date; endDate: Date }) => {
                      setValue(`${prefix}filter_and_sort.date_range`, {
                        start_date: range?.startDate,
                        end_date: range?.endDate,
                      });
                      setValue(
                        `${prefix}filter_and_sort.datePicked`,
                        !!range?.startDate
                      );
                    }}
                    w="100%"
                    shouldShowClear
                    isRange
                    isDisabled={allTime}
                  />
                </Box>
              );
            }}
            name={`${prefix}filter_and_sort.date_range`}
            control={control}
            rules={{
              required: true,
            }}
          />
          <Box display="flex">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Box padding="1rem">
                    <CustomRadio
                      options={customDateRangeOptions}
                      value={createdDate ? "created_date" : "association_date"}
                      setValue={(val) => {
                        setValue(
                          `${prefix}filter_and_sort.created_date`,
                          val === "created_date"
                        );
                        setValue(
                          `${prefix}filter_and_sort.association_date`,
                          val === "association_date"
                        );
                      }}
                    />
                  </Box>
                );
              }}
              name={`${prefix}filter_and_sort.created_date`}
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ContactDate;
