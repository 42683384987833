import {
  Box,
  Button,
  DrawerFooter,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { loadOutboundLine } from "../../../pages/(dashboard-routes)/bdc-campaign/CreateCampaign/utils";
import ContactListing from "../../../pages/(dashboard-routes)/leads/lead-details/ContactListing";
import { getTransformedFilters } from "../../../utils";
import {
  createBroadcastRVM,
  editBroadcastRVM,
  getBroadcastRVM,
} from "../../../utils/api/broadcast.api";
import { fetchContacts } from "../../../utils/api/contact-details";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import SearchAndFilterDrawer from "../../Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import { Drawer, Input, Select } from "../../common";
import AudioPlayer from "../../common/AudioPlayer";
import FileUploader from "../../common/FileUploader";
import Loader from "../../common/Spinner";
import VoiceRecorder from "../../common/VoiceRecorder";
import {
  CalendarIcon,
  FilterIcon,
  SearchIcon,
  TimeIcon,
} from "../../icons/svg";
import {
  CreateBulkMessage,
  createBulkMessageValidation,
  defaultValues,
} from "./utils";

const throttlingOptions = [
  { label: "200 per hour", value: "two_hundred" },
  { label: "400 per hour", value: "four_hundred" },
  { label: "600 per hour", value: "six_hundred" },
  { label: "800 per hour", value: "eight_hundred" },
];

const sendOptions = [
  { label: "Send Now", value: "send_now" },
  { label: "Future Schedule", value: "scheduled" },
];

type CreateBulkMessageFormProps = {
  onCloseMsg: () => void;
  isEdit?: boolean;
  id?: string;
};

const CreateBulkMessageForm = ({
  onCloseMsg,
  isEdit,
  id,
}: CreateBulkMessageFormProps) => {
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [text, setText] = useState("");
  const [audioData, setAudioData] = useState<string | File>("");
  const [transcribed, setTranscribed] = useState<boolean | undefined>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  const isImpersonating = !!useUserInfo("user")?.previousUserId;

  // const { devices } = useTwilioState();
  // const lineDevices = filterByUniqueKey(
  //   devices.filter((device) => device.phoneNumber),
  //   "phoneNumber"
  // );

  // useEffect(() => {
  //   const defaultLine =
  //     lineDevices?.find((line) => line.is_default)?.phoneNumber ||
  //     lineDevices?.[0]?.phoneNumber;
  //   setValue("from", defaultLine);
  // }, [lineDevices, setValue]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const user = useUserInfo("user");
  const toast = useToast();

  const { dealership } = user || {};

  const { handleSubmit, control, setValue, reset, getValues } =
    useForm<CreateBulkMessage>({
      defaultValues: defaultValues,
      resolver: yupResolver(createBulkMessageValidation) as any,
    });

  const filters = useWatch({ control, name: "filters_and_sort" });
  const filterApplied = useWatch({ control, name: "filterApplied" });
  const scheduleType = useWatch({ control, name: "broadcast_type" });

  useEffect(() => {
    const fetchDefaultOption = async () => {
      const options = await loadOutboundLine("", dealership?.id);
      const defaultOption = options.find(
        (option: any) => option.default === true
      );
      if (defaultOption && !id) {
        setValue("from", defaultOption);
      }
    };

    fetchDefaultOption();
  }, [id, setValue]);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (isEdit ? editBroadcastRVM : createBroadcastRVM)(
          {
            payload: payload.formData,
            dealership_id: dealership?.id,
            id,
          }
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Broadcast RVM initiated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onCloseMsg();
    },
    onError: (error: any) => {
      toast({
        description: `Error initiating broadcast messages: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data } = useQuery({
    queryKey: ["fetchContacts", page, filters, searchStr],
    queryFn: () => {
      const params: any = {};

      if (!searchStr) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchStr;
      }

      if (filters) {
        const tempFilters = getTransformedFilters(filters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        params.filter_and_sort = tempFilters;
      }
      return fetchContacts(dealership?.id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: filterApplied,
    onSuccess(data) {
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
      setTotalCount(data?.data?.contacts_count);
    },
  });

  useQuery({
    queryKey: ["fetchBulkRVM", id],
    queryFn: () => getBroadcastRVM({ dealership_id: user?.dealership?.id, id }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setRemovedContacts(data?.data?.ringless_voicemail?.removed_users)
      setValue("name", data?.data.ringless_voicemail.campaign_name);
      setValue("broadcast_date", data?.data.ringless_voicemail.start_date);
      setValue("broadcast_time", data?.data.ringless_voicemail.start_time);
      setValue(
        "throttling_rate",
        data?.data?.ringless_voicemail.throttling_rate
      );
      setValue(
        "filters_and_sort",
        JSON.parse(data?.data?.ringless_voicemail.filter_string)
      );
      setValue("filterApplied", true);
      setAudioData(data?.data?.ringless_voicemail.url);
      setTranscribed(true);
      setTotalCount(data?.data?.ringless_voicemail?.total_voicemails);

      if (data?.data?.line?.id) {
        setValue("from", {
          value: data?.data?.line?.id,
          label: data?.data?.line?.phone_number,
        });
      }
    },
  });

  const handleBulkSendMessage = async (values: CreateBulkMessage) => {
    if (
      !filterApplied ||
      !data?.data?.contacts_count ||
      data?.data?.contacts_count === removedContacts.length
    ) {
      return toast({
        description: "Please apply filters to fetch contacts",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    if (values.broadcast_type === "send_now") {
      delete (values as any).schedule_time;
      delete (values as any).schedule_date;
    }

    if (!audioData && !text) {
      return toast({
        description: "Please select an audio file or record an audio file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    let formData = new FormData();

    const response = await fetch(audioData as any);
    const blob = await response.blob();

    formData.append(
      "ringless_voicemail_campaign[start_date]",
      values.broadcast_date
        ? values.broadcast_date
        : moment().add(15, "minute").toISOString()
    );
    formData.append(
      "ringless_voicemail_campaign[start_time]",
      values.broadcast_time
        ? moment(values.broadcast_date + " " + values.broadcast_time)
            .add(15, "minute")
            .format("HH:mm")
        : moment().add(15, "minute").format("HH:mm")
    );
    formData.append("ringless_voicemail_campaign[campaign_name]", values.name);
    formData.append(
      "ringless_voicemail_campaign[dealership_id]",
      user?.dealership?.id
    );
    formData.append(
      "ringless_voicemail_campaign[throttling_rate]",
      values.throttling_rate
    );
    formData.append(
      "ringless_voicemail_campaign[filter_string]",
      JSON.stringify(values.filters_and_sort)
    );
    formData.append(
      "filter_and_sort",
      JSON.stringify(getTransformedFilters(values.filters_and_sort)) as any
    );
    formData.append(
      "removed_contacts",
      JSON.stringify(removedContacts?.length ? removedContacts : [])
    );
    formData.append("audio_file", blob);
    formData.append("ringless_voicemail_campaign[line_id]", values.from?.label);
    formData.append("ringless_voicemail_campaign[text_to_speech]", text);
    formData.append(
      "ringless_voicemail_campaign[reduce_count]",
      values.reduce_count as unknown as string
    );

    mutation.mutate({
      formData,
    });
  };

  const handleFormReset = () => {
    reset();
    setAttachmentUrls([]);
    setRemovedContacts([]);
  };

  const transformedThrottlingOptions = useMemo(() => {
    if (isImpersonating) {
      return [...throttlingOptions, { label: "Or Max", value: "all_time" }];
    }

    return throttlingOptions;
  }, [isImpersonating]);

  return (
    <Box w="100%">
      {mutation.isLoading && <Loader />}
      <Box padding="1.25rem">
        <Box>
          <Text textStyle="caption" color="var(--grey-600)" mb={"1rem"}>
            The created campaign will be rolled out at America/NewYork time .
          </Text>
        </Box>
        <Box mb="1rem">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Type name here"
                  label="Broadcast Name"
                  hasIcon={false}
                  type="text"
                  maxW="lg"
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
            name="name"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>

        <Box mb="1rem">
          <Button
            variant="outlineBlue"
            leftIcon={<FilterIcon />}
            rightIcon={<ChevronRightIcon />}
            justifyContent="space-between"
            color="var(--grey-900)"
            width="100%"
            size="xl"
            onClick={onOpen}
          >
            <Box as="span" mr="auto">
              Filter & Sort
            </Box>
          </Button>
        </Box>

        {filterApplied && (
          <Box mb="1rem">
            <Button
              variant="outlineBlue"
              rightIcon={<ChevronRightIcon />}
              justifyContent="space-between"
              color="var(--grey-900)"
              width="100%"
              size="xl"
              onClick={onContactsOpen}
            >
              <Box as="span" mr="auto">
                {(data?.data?.contacts_count || 0) - removedContacts.length}{" "}
                Contacts Selected
              </Box>
            </Button>
          </Box>
        )}

        {/* <Box mb="1rem">
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <CustomRadio
                  options={sendOptions}
                  value={value}
                  setValue={(value: any) => setValue("broadcast_type", value)}
                />
              );
            }}
            name="broadcast_type"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box> */}
        {scheduleType === "scheduled" && (
          <Box mb="1rem" display="flex" alignItems="" gap="1rem">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Select Date"
                    onChange={onChange}
                    type="date"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<CalendarIcon />}
                    maxW="lg"
                    value={value}
                    isRequired={true}
                    error={error?.message}
                  />
                );
              }}
              name="broadcast_date"
              control={control}
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    onChange={onChange}
                    label="Select Time"
                    hasIcon={true}
                    value={value}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    type="time"
                    maxW="lg"
                    isRequired
                    error={error?.message}
                  />
                );
              }}
              name="broadcast_time"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        )}
        <Box mb="1rem" position="relative" zIndex={5}>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <AsyncSelect
                    loadOptions={(searchStr) =>
                      loadOutboundLine(searchStr, dealership?.id)
                    }
                    defaultOptions
                    value={value}
                    onChange={(val) => onChange(val)}
                    placeholder="Select line"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100%",
                        marginBottom: "0.5rem",
                      }),
                    }}
                  />
                </>
              );
            }}
            name="from"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
        {isImpersonating&&<Box mb="1rem">
          <Controller
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Throttling"
                  onChange={(value: any) =>
                    setValue("throttling_rate", value.value)
                  }
                  placeholder="Select Throttling"
                  options={transformedThrottlingOptions}
                  variant="default"
                  w="100%"
                  value={value}
                  isRequired
                  error={error?.message}
                />
              );
            }}
            name="throttling_rate"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>}

        {isEdit && (
          <Box mb="1rem">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Enter Number"
                    label="Reduce Count"
                    hasIcon={false}
                    type="number"
                    maxW="lg"
                    isRequired
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    min={0}
                    max={totalCount}
                  />
                );
              }}
              name="reduce_count"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        )}

        <Box
          mb="1rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          <VoiceRecorder
            transcribed={transcribed}
            handleTranscribed={() => {
              setTranscribed(true);
              setText("");
            }}
            handleAudioData={(src: string) => setAudioData(src)}
            isDisabled={!!text}
          />
          <FileUploader
            transcribed={transcribed}
            handleTranscribed={() => {
              setTranscribed(true);
              setText("");
            }}
            handleAudioData={(src: string) => setAudioData(src)}
            isDisabled={!!text}
          />
        </Box>
        <Box mb="1rem">
          <Input
            placeholder="Type here"
            label="Transcribe from text to voice"
            hasIcon={false}
            type="text"
            maxW="lg"
            isTextarea={true}
            onChange={(e) => setText(e.target.value)}
            isDisabled={(transcribed && audioData)?true:false}
            mb="0.5rem"
          />
          {/* <Button
            onClick={() => setTranscribed(true)}
            maxW="100%"
            width="100%"
            my="0.5rem"
            isDisabled={transcribed || !text.length}
          >
            Transcribe
          </Button> */}

          {transcribed && audioData && (
            <AudioPlayer
              audioSrc={audioData}
              transcribed={transcribed}
              handleTranscribed={() => {
                setTranscribed(false);
                setAudioData("");
              }}
            />
          )}
        </Box>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button
            variant="outline"
            w="100%"
            size="xl"
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={() => {
              handleSubmit((values) => handleBulkSendMessage(values))();
            }}
          >
            Send Broadcast RVM
          </Button>
        </DrawerFooter>
      </Box>

      {isOpen && (
        <SearchAndFilterDrawer
          isOpen={isOpen}
          onClose={onClose}
          setFilters={(searchAndFilters) => {
            setValue("filters_and_sort", searchAndFilters);
            setValue("filterApplied", true);
            setRemovedContacts([]);
            setContacts([]);
          }}
          filters={filters}
          pageReset={() => {
            setValue(
              "filters_and_sort",
              defaultFilterValues["filter_and_sort"]
            );
            setValue("filterApplied", false);
            setRemovedContacts([]);
            setContacts([]);
          }}
        />
      )}

      <Drawer
        isOpen={isContactsOpen}
        onClose={onContactsClose}
        title="Contacts"
      >
        <Box padding="0 20px" marginTop="10px">
          <Input
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Contacts..."
            onChange={({ target: { value } }) => {
              setSearchStr(value);
              setPage(1);
              setContacts([]);
            }}
            width="100%"
            value={searchStr}
          />
        </Box>
        <div
          id="bulkMessageDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={data?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="bulkMessageDiv"
          />
        </div>
      </Drawer>
    </Box>
  );
};

export default CreateBulkMessageForm;
