import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import { convertDate } from "../../utils";
import { updateModifySettingsApi } from "../../utils/api/users.api";
import { Dropdown } from "../common";
import SwitchToggle from "../common/Switch";
import TooltipIfOverflow from "../common/TooltipIfOverflow";
export type UserData = {
  default?: boolean;
  modify_settings?: boolean;
  image_url?: string;
  id: number;
  uuid:number;
  logged_ip: string;
  full_name: string;
  name?: string;
  last_login: string;
  access_level: string;
  created_at: string;
  status: string;
  suspend_at?: string;
  dealerships?: string[];
  email: string;
  time_zone?: string;
  total_users?: string;
  phone?: string;
};

const info: any = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")!)
  : "";

//...........common functions........................

const createDateTimeField = (dateArray: string[]) => {
  return (
    <HStack gap="1rem">
      <VStack alignItems="flex-start" gap="0">
        <Text textStyle="h6" fontWeight="500">
          {dateArray?.[0] ?? "-"}
        </Text>
        <Text textStyle="h6" fontWeight="500">
          {dateArray?.[1] ?? null}
        </Text>
      </VStack>
    </HStack>
  );
};

const createNameField = (name: any, phone: any, url: any) => {
  return (
    <HStack gap="1rem">
      {url ? (
        <Image
          src={url}
          width={38}
          height={38}
          style={{
            borderRadius: "50%",
            objectFit: "contain",
          }}
          alt="avatar"
        />
      ) : (
        <AccountCircleIcon sx={{ width: "45px", height: "45px" }} />
      )}

      <VStack alignItems="flex-start" gap="0">
        <Text textStyle="h6" fontWeight="500">
          <TooltipIfOverflow>{name}</TooltipIfOverflow>
        </Text>
        <Text textStyle="h6" fontWeight="500" color="var(--grey-700)">
          <TooltipIfOverflow>{phone}</TooltipIfOverflow>
        </Text>
      </VStack>
    </HStack>
  );
};
const createActionField = (
  options: SelectIconOptionProps[],
  handleSelect: any,
  item: any
) => {
  const info: any = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : "";

  return (
    <Dropdown
      options={options}
      onSelect={handleSelect}
      disabled={
        (!item?.total_users && Number(info?.id) === Number(item?.id)) ||
        item?.default ||
        item?.access_level === "SimpSocial Owner"
      }
    />
  );
};

//..................api handling....................
interface ApiRequest {
  user: {
    modify_settings: boolean;
  };
}

const ModifySettings = (props: any) => {
  const { value, id, defaultFlag } = props;
  const [modifySettingsValue, setModifySettingsValue] = useState<
    boolean | undefined
  >(value);

  useEffect(() => {
    setModifySettingsValue(value);
  }, [value]);
  const info: any = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : "";

  const updateFunction = async (isChecked: boolean) => {
    const payload: ApiRequest = {
      user: {
        modify_settings: isChecked,
      },
    };
    try {
      await updateModifySettingsApi({ id, payload });
    } catch (error) {
      console.error("Error editing can modify settings:", error);
    }
  };
  return (
    <SwitchToggle
      key={id}
      defaultChecked={modifySettingsValue}
      onChange={(isChecked) => {
        setModifySettingsValue(isChecked);
        updateFunction(isChecked);
      }}
      disabled={Number(info?.id) === Number(id) || defaultFlag}
    />
  );
};

//...............users menu (dealership and simpsocials tabs)..................
export const mapTableData = (
  item: UserData,
  handleSelect: any,
  options: SelectOptionProps[],
  isFromDealership: boolean = false
): any => {
  const lastLoginDate = convertDate(item.last_login);
  const createAtDate = convertDate(item.created_at);
  const suspendedAtDate = convertDate(item.suspend_at ? item.suspend_at : "");

  const dealerships = (
    <HStack gap="1rem">
      <VStack alignItems="flex-start" gap="0">
        {item?.dealerships?.map((dealer: any, index: any) => (
          <Text key={index} textStyle="h6" fontWeight="500">
            <TooltipIfOverflow>{`• ${dealer}`}</TooltipIfOverflow>
          </Text>
        ))}
      </VStack>
    </HStack>
  );
  return {
    id: item.id,
    uuid:item?.uuid,
    email: item.email,
    accessLevel: item.access_level ?? "-",
    timezone: item.time_zone ?? "-",
    name: isFromDealership
      ? createNameField(item.name, item.phone, item.image_url)
      : createNameField(item.full_name, item.phone, item.image_url),
    dealership: dealerships,
    createdAt: createDateTimeField(createAtDate),
    lastLogin: createDateTimeField(lastLoginDate),
    suspendedAt: createDateTimeField(suspendedAtDate),
    actions: createActionField(options, handleSelect, item),
    modifySettings: (
      <ModifySettings
        value={item.modify_settings}
        id={item.id}
        defaultFlag={item.default}
      />
    ),
    totalUsers: item.total_users ?? "-",
  };
};

//................Headers...................................
export const getUserMenuHeaders = (
  isActive: boolean,
  isSimpSocial: boolean,
  isFromDealershipPortal: boolean = false
) => {
  const commonHeaders = [
    { value: "Name", label: "name" },
    { value: "ID", label: "uuid" },
    { value: "Email", label: "email" },
    { value: "Access Level", label: "accessLevel" },
    ...(isSimpSocial ? [] : [{ value: "Dealership(s)", label: "dealership" }]),
    { value: "Created At", label: "createdAt" },
    ...(isActive ? [] : [{ value: "Suspended At", label: "suspendedAt" }]),
    { value: "Last Login", label: "lastLogin" },
    ...(isFromDealershipPortal
      ? [{ value: "Can Modify Settings", label: "modifySettings" }]
      : []),
    { value: "Actions", label: "actions" },
  ];

  return commonHeaders;
};

export const getDealershipMenuHeaders = (isActive: boolean) => {
  return [
    { value: "Name", label: "name" },
    { value: "ID", label: "uuid" },
    { value: "Timezone", label: "timezone" },
    { value: "Total Users", label: "totalUsers" },
    { value: "Created At", label: "createdAt" },
    { value: "Last Login", label: "lastLogin" },
    ...(isActive ? [] : [{ value: "Suspended At", label: "suspendedAt" }]),
    { value: "Actions", label: "actions" },
  ];
};

export const sortWeekdays = (obj: any) => {
  const weekdaysOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const sortedWeekdays: any = {};

  // Filter out weekdays from the object and sort them based on weekdaysOrder
  const sortedWeekdaysArray = Object.keys(obj)
    .filter((day) => weekdaysOrder.includes(day))
    .sort((a, b) => weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b));

  // Populate the sorted weekdays object
  sortedWeekdaysArray.forEach((day) => {
    sortedWeekdays[day] = obj[day];
  });

  return sortedWeekdays;
};
