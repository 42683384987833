import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { useLocation, useParams } from "react-router-dom";
import "../../../App.css";
import Popup from "../../../components/Charts/Popup";
import { Spinner } from "../../../components/common";
import { BigCheckIcon } from "../../../components/icons/svg";
import { convertDateTime } from "../../../utils";
import {
  fetchBusinessHours,
  scheduleAVisit,
} from "../../../utils/api/guest-mode";
import {
  BusinessHours,
  convertToMilitaryTime,
  daysOfWeek,
  pickSlotTimes,
} from "./utils";

const ScheduleVisit = () => {
  const { id, dId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const automate = queryParams.get("automate");
  const [bookingDate, setBookingDate] = useState<Date | null>(null);
  const [bookingTimes, setBookingTimes] = useState<string[]>([]);
  const timeSlotCacheRef = useRef<Map<string, string[]>>(new Map());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [isBack, setIsBack] = useState<boolean>(true);
  const [businessHours, setBusinessHours] = useState<BusinessHours>({
    monday: { start_time: "", end_time: "", day_status: false },
    tuesday: { start_time: "", end_time: "", day_status: false },
    wednesday: { start_time: "", end_time: "", day_status: false },
    thursday: { start_time: "", end_time: "", day_status: false },
    friday: { start_time: "", end_time: "", day_status: false },
    saturday: { start_time: "", end_time: "", day_status: false },
    sunday: { start_time: "", end_time: "", day_status: false },
  });

  const { data } = useQuery({
    queryKey: ["fetchBusinessHours", dId],
    queryFn: fetchBusinessHours,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setBusinessHours(data?.data);
    },
  });

  const mutation = useMutation(scheduleAVisit);

  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    if (!bookingDate) return;

    let newBookingTimes = timeSlotCacheRef.current.get(
      bookingDate.toDateString()
    );

    if (!newBookingTimes) {
      newBookingTimes = pickSlotTimes(businessHours, bookingDate);
      timeSlotCacheRef.current.set(bookingDate.toDateString(), newBookingTimes);
    }

    setBookingTimes(newBookingTimes);
  }, [bookingDate]);

  const onDateChange = (date: Date | Date[]): void => {
    if (Array.isArray(date)) {
    } else {
      setBookingDate(date);
    }
  };

  const handleSubmit = () => {
    setIsBack(false);
    setIsOpen(false);

    mutation.mutate(
      {
        id,
        dId,
        payload: {
          appointment: {
            start_time: convertDateTime(
              moment(bookingDate).format("YYYY-MM-DD"),
              convertToMilitaryTime(selectedTime ?? "")
            ),
            is_sarah_created: automate ? true : false,
          },
        },
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
        },
        onError: (error: any) => {
          setIsSuccess(false);
        },
      }
    );
  };

  return id === "[contact_id]" ? (
    <VStack w="100%" mt="2.5rem" px={{ base: "1rem", md: "0" }}>
      <VStack
        w={{ base: "100%", md: "60%" }}
        alignItems="flex-start"
        background="#fff"
        borderRadius="0.5rem"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          We will need some contact information from you before you can schedule
          a visit.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          Please go to{" "}
          <Text as="span" fontWeight="bold">
            My Portal
          </Text>{" "}
          and provide the information to continue. Thank you!
        </Text>
      </VStack>
    </VStack>
  ) : (
    <VStack
      w="100%"
      mt={{ md: "2.5rem", base: "1rem" }}
      p={{ md: "0", base: "1rem" }}
    >
      <VStack
        w={{ base: "100%", md: "50%" }}
        background="#fff"
        borderRadius="0.5rem"
        p="1.5rem"
        h="100%"
      >
        {isBack ? (
          <HStack
            gap="2rem"
            justifyContent="center"
            alignItems="unset"
            h="100%"
            flexDirection={{ base: "column", md: "row" }}
          >
            <VStack w="100%" flex="2" className="schedule-visit-calendar">
              <Calendar
                value={bookingDate}
                onChange={(e: any) => onDateChange(e)}
                tileDisabled={
                  ({ date }: { date: Date }) =>
                    date < new Date(new Date().setHours(0, 0, 0, 0)) // Disable past dates
                }
                tileClassName={({ date }: { date: Date }) =>
                  date < new Date(new Date().setHours(0, 0, 0, 0))
                    ? "past-date"
                    : ""
                }
              />
            </VStack>

            <VStack
              background="var(--grey-200)"
              maxHeight={{ md: "400px", base: "300px" }}
              overflowY="scroll"
              p="1rem"
              borderRadius="0.5rem"
              w={{ md: "40%", base: "100%" }}
              flex="1"
              alignItems="center"
              flexDirection={{ md: "column", base: "row" }}
              flexWrap={{ md: "nowrap", base: "wrap" }}
              textAlign="center"
              justifyContent={{ md: "flex-start", base: "center" }}
            >
              {bookingDate ? (
                !businessHours[daysOfWeek[bookingDate.getDay()]]?.day_status ? (
                  <Text textStyle="h5">No slots available for this day.</Text>
                ) : (
                  bookingTimes.map((time) => (
                    <VStack key={time} alignItems="flex-start">
                      <HStack>
                        <Button
                          w={{
                            md: selectedTime === time ? "100px" : "200px",
                            base: selectedTime === time ? "150px" : "150px",
                          }}
                          variant={selectedTime === time ? "" : "outline"}
                          fontSize="0.875rem"
                          borderRadius="4px"
                          color={
                            selectedTime === time
                              ? "#fff"
                              : "var(--primary-600)"
                          }
                          bg={
                            selectedTime === time ? "var(--grey-700)" : "#fff"
                          }
                          border="1px solid var(--grey-300)"
                          onClick={() => handleTimeClick(time)}
                        >
                          {time}
                        </Button>
                        {selectedTime === time && (
                          <Button
                            borderRadius="4px"
                            w={{ md: "90px", base: "150px" }}
                            onClick={() => setIsOpen(true)}
                          >
                            Confirm
                          </Button>
                        )}
                      </HStack>
                    </VStack>
                  ))
                )
              ) : (
                <Text textStyle="h5">
                  Please select the day you would like to visit and my available
                  times will show here.
                </Text>
              )}
            </VStack>
          </HStack>
        ) : (
          <>
            <VStack
              w={{ md: "100%%", base: "100%" }}
              h={{ md: "400px", base: "300px" }}
            >
              {isSuccess ? (
                <VStack alignItems="center" justifyContent="center" h="100%">
                  <Text textStyle="h3" fontWeight="600">
                    Appointment created successfully!
                  </Text>
                  <VStack gap="0.5rem">
                    <BigCheckIcon color="var(--primary-600)" />
                  </VStack>
                </VStack>
              ) : (
                <VStack>
                  <Text textStyle="h3" fontWeight="600">
                    Error while creating appointment!
                  </Text>
                  <VStack gap="0.5rem">
                    <CancelIcon
                      style={{
                        width: "8rem",
                        height: "8rem",
                        color: "var(--red-600)",
                      }}
                    />
                  </VStack>
                </VStack>
              )}
              <HStack justifyContent="flex-start" w="100%">
                <Button
                  variant="none"
                  // border="1px solid var(--grey-300)"
                  // borderRadius="0.5rem"
                  justifyContent="center"
                  padding="0.55rem"
                  display="flex"
                  // w="40px"
                  onClick={() => {
                    setIsBack(true);
                    setSelectedTime(null);
                    setBookingDate(null);
                  }}
                >
                  <ArrowBackIcon sx={{ color: "var(--grey-900)" }} />
                </Button>
              </HStack>
            </VStack>
          </>
        )}
      </VStack>
      {isOpen && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpen(false)}
          onConfirm={handleSubmit}
          title="Confirmation Popup"
          message="Are you sure you want to schedule this appointment?"
        />
      )}
      {mutation?.isLoading && <Spinner />}
    </VStack>
  );
};

export default ScheduleVisit;
