import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { EditNote, Merge, PersonSearch } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SearchIcon } from "../../../components/icons/svg";
import { fetchLeadSource } from "../../../utils/api/global-config.api";
import { impersonateUser } from "../../../utils/api/users.api";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Dropdown, Input, PaginatedTable, Spinner } from "../../common";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import CreateLeadSource from "../CreateLeadSource/CreateLeadSource";
import DeleteLeadSource from "../DeleteLeadSource/DeleteLeadSource";
import EditLeadSource from "../EditLeadSource/EditLeadSource";
import MergeModal from "./MergeModal";

const GlobalLeadSource = () => {
  const { id: dealerId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const user = useUserInfo("user");
  const dispatch = useAuthDispatch();

  const handleUserImpersonate = async (selectedValue: any) => {
    await impersonateUser(
      dealerId,
      dispatch,
      true,
      {
        menu: "Dealership",
        "Sub-Menu": "Dealership Settings/Lead Sources",
      },
      user?.role,
      user?.id
    );
    localStorage.setItem(
      "search_and_filter",
      JSON.stringify({
        ...defaultFilterValues.filter_and_sort,
        contact_attributes: {
          ...defaultFilterValues.filter_and_sort.contact_attributes,
          disposition_status: [
            { label: selectedValue.name, value: selectedValue.id },
          ],
        },
      })
    );
    window.open(`${window.location.origin}/leads/lead-details`, "_self");
  };

  const handleSelect = (option: SelectOptionProps, item: any) => {
    if (option.value === "edit") {
      setActiveModal(option.value);
    }

    if (option.value === "merge") {
      setIsMergeModalOpen(true);
    }

    if (option.value === "show") {
      handleUserImpersonate(item);
    }
  };

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditNote />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <Merge />
          Merge Lead Source
        </Box>
      ),
      value: "merge",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PersonSearch />
          Show Contacts
        </Box>
      ),
      value: "show",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchLeadSources", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};
      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchLeadSource(params, dealerId);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setCount(data?.lead_sources_count ?? 0);
    },
  });

  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "Number of Contacts", label: "numberOfContacts" },
    { value: "Actions", label: "actions" },
  ];

  const tableData = data?.lead_sources?.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
      numberOfContacts: item?.contact_count,
      phoneNumber: "+1 234 567 89",
      dataType: "Single file",
      notes: "-",
      actions: (
        <Dropdown
          options={options}
          onSelect={(option) => handleSelect(option, item)}
        />
      ),
    };
  });

  return (
    <Box>
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1rem"
        background="white"
      >
        <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
          <Input
            maxW="20rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
          />
        </HStack>
        <Button onClick={onOpen} padding="0 2rem">
          Create Lead Sources
        </Button>
      </HStack>

      <Text
        textStyle="caption"
        padding="1.5rem 1rem 0.75rem"
        w="100%"
        color="var(--grey-600)"
      >
        Total Lead Sources ({count})
      </Text>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        itemsPerPage={perPage}
        maxPageNumbers={3}
        isPaginated={true}
        lastCenter={false}
        hasMultiBody={false}
        rowOnClick={(rowItem) => {
          setClickedRow(rowItem);
        }}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={count}
        isLoadingData={isLoading}
      />

      {isOpen && (
        <CreateLeadSource
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        ></CreateLeadSource>
      )}

      {activeModal === "edit" && (
        <EditLeadSource
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "delete" && (
        <DeleteLeadSource
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {isMergeModalOpen && (
        <MergeModal
          isOpen={isMergeModalOpen}
          onClose={() => setIsMergeModalOpen(false)}
          refetchParent={refetch}
          selectedId={clickedRow?.id}
        />
      )}

    </Box>
  );
};

export default GlobalLeadSource;
