import {
  Button,
  HStack,
  Text,
  useDisclosure,
  VStack,
  Box,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import DealershipListingDrawer from "../../../pages/(global-admin-routes)/announcements/DealershipListingDrawer";
import { getSaraSettings } from "../../../utils/api/sara-ai";
import { Input, Spinner, Select } from "../../common";
import SwitchToggle from "../../common/Switch";
import { CustomRadio } from "../../common";
import { Controller, useForm, useWatch } from "react-hook-form";
import { defaultValues, SarahAiForm ,formoptions,hoursOption} from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import TimeIcon from "../../icons/Time";
export default function SaraAI() {
  const [form, setForm] = useState<any>("sales_instructions");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getSaraSettings",form],
    queryFn: getSaraSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setSettingsHash(data?.data);
      setValue("Hours_of_Operation", data?.data?.operating_hours|| defaultValues?.Hours_of_Operation);
      setValue("days", data?.data?.hours || defaultValues?.days);

    },
  });
  const { handleSubmit, control, watch, setValue, reset } =
    useForm<SarahAiForm>({
      defaultValues: defaultValues,
      // resolver: yupResolver(
      //   CreateBDCCampaignFormSettingsSchema(businessHours?.data?.business_hours)
      // ) as any,
    });
  const [settingsHash, setSettingsHash] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (property: string, value: any) => {
    setSettingsHash((prev: any) => ({
      ...prev,
      [property]: value,
    }));
  };
  return (
    <>
      {" "}
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flex="1" alignItems="center">
          <Text fontWeight="700" textStyle="h4">
            Sarah.ai Integration
          </Text>
        </Box>
        <Select
          options={formoptions}
          label="Select Form"
          value={form}
          onChange={(val) => setForm(val?.value)}
          w="20%"
        />{" "}
      </Box>
      <VStack
        alignItems="flex-start"
        background="var(--grey-50)"
        border="1px solid var(--grey-300)"
        padding="1rem"
        borderRadius="0.5rem"
        w="100%"
        gap="1.5rem"
      >
        {isLoading && <Spinner />}
        <VStack w="100%" gap="1.25rem" alignItems="flex-start">
          <HStack>
            <SwitchToggle
              label="Sarah.ai Integration"
              defaultChecked={settingsHash?.chat_gpt_integration}
              onChange={(val) => handleInputChange("chat_gpt_integration", val)}
            />
          </HStack>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CustomRadio
                  label="Sarah.ai Hours of Operation"
                  options={hoursOption}
                  value={value}
                  setValue={onChange}
                />
              );
            }}
            name="Hours_of_Operation"
            control={control}
            rules={{
              required: true,
            }}
          />
           {watch("Hours_of_Operation") === "specific_hours" &&
          watch("days")?.map((item, index) => (
            <Stack direction="row" alignItems="center">
              <VStack mt="18px" minW="104px" alignItems="flex-start">
                <Controller
                  name={`days.${index}.checked`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      size="lg"
                      variant="whiteCheck"
                      onChange={onChange}
                      isChecked={value}
                    >
                      {item?.label}
                    </Checkbox>
                  )}
                />
              </VStack>
              <Controller
                name={`days.${index}.startTime`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onChange={onChange}
                    label="Start Time"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    type="time"
                    maxW="lg"
                    error={error?.message}
                    value={value}
                  />
                )}
              />
              <Controller
                name={`days.${index}.endTime`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onChange={onChange}
                    label="End Time"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    type="time"
                    maxW="lg"
                    value={value}
                    error={error?.message}
                  />
                )}
              />
            </Stack>
          ))}
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="Website Chat"
              isTextarea={true}
              defaultValue="Generate an SMS to introduce yourself as a non-aggressive car salesman namely Sarah at [company_name] to customers, involving saying how this is the perfect car for fun and work. Your goal is to set up an appointment for today, schedule a phone call, or get a credit application filled"
              value={settingsHash?.website_chat}
              onChange={(e) => {
                handleInputChange("website_chat", e?.target?.value);
              }}
            />
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <VStack alignItems="flex-start" w="100%">
              <Input
                maxW="100%"
                label="Offering"
                isTextarea={true}
                value={settingsHash?.offering}
                onChange={(e) => {
                  handleInputChange("offering", e?.target?.value);
                }}
              />
            </VStack>
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <VStack alignItems="flex-start" w="100%">
              <Input
                maxW="100%"
                label="Not Offering"
                isTextarea={true}
                value={settingsHash?.not_offering}
                onChange={(e) => {
                  handleInputChange("not_offering", e?.target?.value);
                }}
              />
            </VStack>
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="Hours of Operation"
              value={settingsHash?.hours_of_operation}
              onChange={(e) => {
                handleInputChange("hours_of_operation", e?.target?.value);
              }}
            />

            <Input
              maxW="100%"
              label="Wait time for Sarah.ai turnover"
              value={settingsHash?.wait_time}
              onChange={(e) => {
                handleInputChange("wait_time", e);
              }}
              type="number"
              isNumeric
            />
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="Response if the contact says no"
              value={settingsHash?.response_if_contact_say_no}
              onChange={(e) => {
                handleInputChange(
                  "response_if_contact_say_no",
                  e?.target?.value
                );
              }}
            />

            <Input
              maxW="100%"
              label="Response if the contact wants to make a call"
              value={settingsHash?.response_if_contact_wants_to_make_call}
              onChange={(e) => {
                handleInputChange(
                  "response_if_contact_wants_to_make_call",
                  e?.target?.value
                );
              }}
            />
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="Sequence id to enrol if 5 attempts no response"
              value={
                settingsHash
                  ?.sequence_id_to_enroll_5_attempts_no_response
              }
              onChange={(e) => {
                handleInputChange(
                  "sequence_id_to_enroll_5_attempts_no_response",
                  e?.target?.value
                );
              }}
            />

            <Input
              maxW="100%"
              label="1st followup message"
              value={settingsHash?.first_follow_up_message}
              onChange={(e) => {
                handleInputChange("first_follow_up_message", e?.target?.value);
              }}
            />
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="2nd followup message"
              value={settingsHash?.second_follow_up_message}
              onChange={(e) => {
                handleInputChange("second_follow_up_message", e?.target?.value);
              }}
            />

            <Input
              maxW="100%"
              label="3rd followup message"
              value={settingsHash?.third_follow_up_message}
              onChange={(e) => {
                handleInputChange("third_follow_up_message", e?.target?.value);
              }}
            />
          </HStack>
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Input
              maxW="100%"
              label="4th followup message"
              value={settingsHash?.fourth_follow_up_message}
              onChange={(e) => {
                handleInputChange("fourth_follow_up_message", e?.target?.value);
              }}
            />

            <Input
              maxW="100%"
              label="5th followup message"
              value={settingsHash?.fifth_follow_up_message}
              onChange={(e) => {
                handleInputChange("fifth_follow_up_message", e?.target?.value);
              }}
            />
          </HStack>
          <Button width="8rem" onClick={onOpen}>
            Save
          </Button>
        </VStack>
        {isOpen && (
          <DealershipListingDrawer
            onClose={onClose}
            refetch={refetch}
            settingsHash={settingsHash}
            operating_hours={watch('Hours_of_Operation')}
             days={watch("Hours_of_Operation") === "specific_hours"?watch('days'):undefined}  
             form={form}                
          />
        )}
      </VStack>
    </>
  );
}
