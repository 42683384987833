import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { HamburgerIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../assets/avatar.svg";
import { GuestModeSidebarMenu } from "../../config/constants/guestmodeSidebar";
import { fetchHome } from "../../utils/api/guest-mode";
import TooltipIfOverflow from "./TooltipIfOverflow";

interface SidebarProps {
  pathname: string;
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const guestModePaths: Record<string, string> = {
  home: "/guest_mode/dealership/:dId/contact/:id",
  my_profile: "/guest_mode/dealership/:dId/contact/:id/my_profile",
  my_garage: "/guest_mode/dealership/:dId/contact/:id/my_garage",
  schedule_a_visit: "/guest_mode/dealership/:dId/contact/:id/schedule_a_visit",
  apply_for_credit: "/guest_mode/dealership/:dId/contact/:id/apply_for_credit",
  browse_inventory: "/guest_mode/dealership/:dId/contact/:id/browse_inventory",
  my_document: "/guest_mode/dealership/:dId/contact/:id/my_document",
};
type GuestModePathKey = keyof typeof guestModePaths;

const GuestModeSidebar = ({
  pathname,
  isDrawerOpen,
  setIsDrawerOpen,
}: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id, dId } = useParams();
  const [currentPath, setCurrentPath] = useState<string>();
  const excludedPaths = ["my_garage", "my_document"];
  const isExcludedPath = excludedPaths.some((path) => pathname.includes(path));

  useEffect(() => {
    if (id === "[contact_id]" && isExcludedPath) {
      navigate(
        `/guest_mode/dealership/${dId}/contact/[contact_id]/apply_for_credit`
      );
    }
  }, [id]);

  const handleMenuItemClick = (path: string) => {
    let updatedPath = path.replace(":dId", dId || ":dId");
    updatedPath = updatedPath.replace(":id", id || ":id");
    navigate(updatedPath);
    setCurrentPath(path);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    let path: string = guestModePaths["home"];
    Object.keys(guestModePaths).forEach((key: GuestModePathKey) => {
      if (location?.pathname && location?.pathname.includes(key)) {
        path = guestModePaths[key];
      }
    });
    setCurrentPath(path);
    setIsDrawerOpen(false);
  }, [location]);

  const { data, isLoading } = useQuery({
    queryKey: ["fetchHome", { contact_uuid: id }, dId],
    queryFn: fetchHome,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id && id !== ":id",
  });

  return (
    <>
      <Box
        display={{ base: "none", md: "block" }}
        minWidth={isCollapsed ? "4.25rem" : "16rem"}
        bg="white"
        borderRight="1px solid var(--grey-300, #E0E0E0)"
        transition="all 0.3s"
        position="relative"
        zIndex="2"
        marginTop="45px"
      >
        {/* Sidebar Content */}
        <VStack
          mt="1.5rem"
          spacing={0}
          height="calc(100vh - 169px)"
          overflowY="auto"
        >
          {GuestModeSidebarMenu.map((menuItem) => {
            const isExcludedMenuItem = excludedPaths.some((path) =>
              menuItem?.path?.includes(path)
            );
            return (
              <Flex
                key={menuItem?.id}
                align="center"
                w="full"
                borderBottom="1px solid var(--grey-300)"
                background={
                  currentPath === menuItem?.path ||
                  (currentPath === "/" &&
                    menuItem.name === "/guest_mode/dealership/:dId/contact/:id")
                    ? "var(--primary-600)"
                    : "transparent"
                }
                style={
                  id && id === "[contact_id]" && isExcludedMenuItem
                    ? { opacity: 0.5, pointerEvents: "none" }
                    : {}
                }
              >
                <Button
                  py="0.5rem"
                  pl="1rem"
                  pr="1rem"
                  w="full"
                  onClick={() => handleMenuItemClick(menuItem.path)}
                  variant="none"
                  display="flex"
                  alignItems="center"
                  fontSize="0.875rem"
                  fontWeight={500}
                  color={
                    currentPath === menuItem?.path ||
                    (currentPath === "/" &&
                      menuItem.name ===
                        "guest_mode/dealership/:dId/contact/:id")
                      ? "white"
                      : "var(--grey-800)"
                  }
                >
                  {!isCollapsed ? menuItem?.name : ""}
                </Button>
              </Flex>
            );
          })}
        </VStack>
      </Box>

      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={() => setIsDrawerOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack h="100%" gap="0">
              <HStack
                position="relative"
                paddingRight={{ md: "1.4rem", base: "0.5rem" }}
              >
                <Image
                  src={Avatar}
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                  alt="avatar"
                />
                <VStack gap="0" align="flex-start">
                  <Text
                    textStyle={{ md: "h6", base: "bodySmall" }}
                    fontWeight={{ md: "700", base: "500" }}
                    color="var(--grey-900)"
                  >
                    <TooltipIfOverflow>
                      {data?.data?.dealership_name}
                    </TooltipIfOverflow>
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={0}>
              {GuestModeSidebarMenu.map((menuItem) => {
                const isExcludedMenuItem = excludedPaths.some((path) =>
                  menuItem?.path?.includes(path)
                );
                return (
                  <Flex
                    key={menuItem?.id}
                    align="center"
                    w="full"
                    borderBottom="1px solid var(--grey-300)"
                    background={
                      currentPath === menuItem?.path ||
                      (currentPath === "/" &&
                        menuItem.name ===
                          "/guest_mode/dealership/:dId/contact/:id")
                        ? "var(--primary-600)"
                        : "transparent"
                    }
                    style={
                      id && id === "[contact_id]" && isExcludedMenuItem
                        ? { opacity: 0.5, pointerEvents: "none" }
                        : {}
                    }
                  >
                    <Button
                      py="0.5rem"
                      pl="1rem"
                      pr="1rem"
                      w="full"
                      onClick={() => handleMenuItemClick(menuItem.path)}
                      variant="none"
                      display="flex"
                      alignItems="center"
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={
                        currentPath === menuItem?.path ||
                        (currentPath === "/" &&
                          menuItem.name ===
                            "guest_mode/dealership/:dId/contact/:id")
                          ? "white"
                          : "var(--grey-800)"
                      }
                    >
                      {!isCollapsed ? menuItem?.name : ""}
                    </Button>
                  </Flex>
                );
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default GuestModeSidebar;
