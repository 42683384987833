import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppointConfirmIcon,
  BroadcastIcon,
  EngagedLeadsIcon,
  ExcelDarkIcon,
  NewLeadsIcon,
  PDFDarkIcon,
  SetPriorityIcon,
  UnreadMsgIcon,
} from "../../../components/icons/svg";
import { fetchContacts } from "../../../utils/api/contact-details";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const LeadResponse = ({ dashboardStats }: { dashboardStats: any }) => {
  const dealer = useUserInfo("dealership");

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <BroadcastIcon />
          Send Broadcast
        </Box>
      ),
      value: "pdf",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFDarkIcon />
          Export PDF
        </Box>
      ),
      value: "pdf",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <ExcelDarkIcon />
          Export Excel
        </Box>
      ),
      value: "excel",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <SetPriorityIcon />
          Change Priority
        </Box>
      ),
      value: "excel",
    },
  ];
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };

  const { leads, messages,contacts } = dashboardStats || {};

  const navigate = useNavigate();

  const handleNavigate = (statKey: string) => {
    navigate(`/leads/lead-details?statKey=${statKey}`);
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchContacts"],
    queryFn: () => {
      return fetchContacts(dealer?.id, { per_page: 0 });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <VStack
      background="white"
      padding="1.5rem"
      gap="1.5rem"
      alignItems="flex-start"
      borderRadius="1rem"
      w="100%"
    >
      <VStack alignItems="flex-start" w="100%" gap="0.69">
        <HStack justifyContent="space-between" w="100%">
          <Text textStyle="h2" fontWeight="500">
            Overall Lead Information
          </Text>
          {/* <Dropdown
            options={options}
            hasBorder={false}
            onSelect={handleSelect}
          /> */}
        </HStack>
        <HStack mt="0.62rem">
          <Button onClick={() => navigate("/leads/lead-details")}>
            All Contacts ({contacts?.total_count})
          </Button>
        </HStack>
        <HStack
          w="100%"
          gap="1.5rem"
          mt="1rem"
          pb="1.5rem"
          flex="1"
          borderBottom="1px solid var(--grey-300)"
        >
          <VStack
            padding="2rem 1.5rem 1.5rem"
            position="relative"
            flex="1"
            background="var(--primary-800)"
            borderRadius="1rem"
            cursor="pointer"
            height="100%"
            pointerEvents={messages?.unread_messages > 0 ? "auto" : "none"}
            onClick={() => handleNavigate("unread_messages")}
          >
            {/* <Box
              position="absolute"
              right="0.5rem"
              top="1rem"
              sx={{
                ".menu-button": {
                  svg: {
                    width: "28px",
                    height: "28px",
                    path: { fill: "white" },
                  },
                },
              }}
            >
              {/* <Dropdown
                options={options}
                hasBorder={false}
                onSelect={handleSelect}
              /> */}
            {/* </Box> */}
            <UnreadMsgIcon />
            <Text textStyle="h1" fontWeight="700" color="white">
              {messages?.unread_messages || 0}
            </Text>
            <Text textStyle="h4" fontWeight="700" color="white" textAlign="center">
              Unread Messages
            </Text>
            <Text textStyle="h6" fontWeight="500" color="white">
              {moment().format("MM/DD/yy")}
            </Text>
          </VStack>
          <VStack
            padding="2rem 1.5rem 1.5rem"
            position="relative"
            flex="1"
            background="var(--red-500)"
            borderRadius="1rem"
            cursor="pointer"
            height="100%"
            onClick={() => handleNavigate("new_leads")}
            pointerEvents={leads?.new_leads > 0 ? "auto" : "none"}
          >
            {/* <Box
              position="absolute"
              right="0.5rem"
              top="1rem"
              sx={{
                ".menu-button": {
                  svg: {
                    color: "white",
                    width: "28px",
                    height: "28px",
                    path: { fill: "white" },
                  },
                },
              }}
            >
              {/* <Dropdown
                options={options}
                hasBorder={false}
                onSelect={handleSelect}
              /> */}
            {/* </Box> */}
            <NewLeadsIcon />
            <Text textStyle="h1" fontWeight="700" color="white">
              {leads?.new_leads || 0}
            </Text>
            <Text textStyle="h4" fontWeight="700" color="white" textAlign="center">
              New Leads
            </Text>
            <Text textStyle="h6" fontWeight="500" color="white">
              {moment().format("MM/DD/yy")}
            </Text>
          </VStack>
          <VStack
            padding="2rem 1.5rem 1.5rem"
            position="relative"
            flex="1"
            background="var(--secondary-600)"
            borderRadius="1rem"
            cursor="pointer"
            height="100%"
            onClick={() => handleNavigate("engaged_leads")}
            pointerEvents={leads?.engaged_leads > 0 ? "auto" : "none"}
          >
            {/* <Box
              position="absolute"
              right="0.5rem"
              top="1rem"
              sx={{
                ".menu-button": {
                  svg: {
                    color: "white",
                    width: "28px",
                    height: "28px",
                    path: { fill: "white" },
                  },
                },
              }}
            >
              {/* <Dropdown
                options={options}
                hasBorder={false}
                onSelect={handleSelect}
              /> */}
            {/* </Box> */}
            <EngagedLeadsIcon />
            <Text textStyle="h1" fontWeight="700" color="white">
              {leads?.engaged_leads || 0}
            </Text>
            <Text textStyle="h4" fontWeight="700" color="white" textAlign="center">
              Engaged Leads
            </Text>
            <Text textStyle="h6" fontWeight="500" color="white">
              {`${moment()
                .subtract(45, "days")
                .format("MM/DD/yy")}-${moment().format("MM/DD/yy")}`}
            </Text>
          </VStack>
          <VStack
            padding="2rem 1.5rem 1.5rem"
            position="relative"
            flex="1"
            background="var(--secondary-600)"
            borderRadius="1rem"
            cursor="pointer"
            height="100%"
            onClick={() => handleNavigate("appt_conf")}
            pointerEvents={
              leads?.appointment_confirmations > 0 ? "auto" : "none"
            }
          >
            <AppointConfirmIcon />
            <Text textStyle="h1" fontWeight="700" color="white">
              {leads?.appointment_confirmations || 0}
            </Text>
            <Text textStyle="h4" fontWeight="700" color="white" textAlign="center">
              Appointment Confirmations
            </Text>
            <Text textStyle="h6" fontWeight="500" color="white">
              {moment().format("MM/DD/yy")}
            </Text>
          </VStack>
        </HStack>
        <HStack
          justifyContent="space-between"
          mt="1.5rem"
          alignItems="flex-start"
          w="100%"
        >
          <Text textStyle="h2" fontWeight="500">
            Response Time Details
          </Text>
          <HStack>
            <VStack maxW="15rem" alignItems="flex-start" gap="0">
              <Text textStyle="h4">Avg New Lead Response Time</Text>
              <Text textStyle="h3" color="var(--primary-600)">
                {leads?.avg_new_lead_response_time || `0 seconds`}
              </Text>
            </VStack>
            <VStack maxW="15rem" alignItems="flex-start" gap="0">
              <Text textStyle="h4">
                Avg Inbound Communication Response Time
              </Text>
              <Text textStyle="h3" color="var(--secondary-600)">
                {leads?.avg_inbound_communication_response_time || `0 seconds`}
              </Text>
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default LeadResponse;
