import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../components/icons/svg";
import { fetchUsers } from "../../../utils/api/users.api";
import { Simpsocial_Roles } from "../../../utils/roles";
import { Input, PaginatedTable, Select, Spinner } from "../../common";
import CreateUser from "../CreateUser/CreateUser";
import EditUser from "../EditUser/EditUser";
import ReActivate from "../ReActivateUser/ReActivateUser";
import ResetPassword from "../ResetPassword/ResetPassword";
import SuspendUser from "../SuspendUser/SuspendUser";
import { getUserMenuHeaders, mapTableData, UserData } from "../UserUtils";

const SimpSocialUsers = () => {
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [clickedRow, setClickedRow] = useState<UserData>({
    id: 0,
    logged_ip: "",
    full_name: "",
    uuid:0,
    last_login: "",
    access_level: "",
    created_at: "",
    status: "",
    email: "",
    time_zone: "",
  });
  const ROLE_TITLES = Simpsocial_Roles?.join(",");

  const optionsHeader: SelectOptionProps[] = [
    { label: "All Access Levels", value: ROLE_TITLES },
    { label: "SimpSocial Owner", value: Simpsocial_Roles[0] },
    { label: "SimpSocial Admin", value: Simpsocial_Roles[1] },
    { label: "Customer Service Agent", value: Simpsocial_Roles[2] },
  ];

  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  const [tableHeader, setTableHeader] = useState(
    getUserMenuHeaders(isActive, true)
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ssUsers", ROLE_TITLES, isActive, page, perPage],
    queryFn: () => {
      const queryParameters: any = {
        role_titles: selectedOption?.value || ROLE_TITLES,
        status: isActive ? "active" : "suspended",
        searchValue: searchValue,
      };

      if (!searchValue) {
        queryParameters.page = page;
        queryParameters.per_page = perPage;
      }

      return fetchUsers(queryParameters);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const optionsActive: SelectOptionProps[] = [
    { label: "Edit User", value: "editUser" },
    { label: "Reset Password", value: "resetPassword" },
    { label: "Suspend", value: "suspend" },
    { label: "Settings", value: "settings" },
  ];
  const optionsSuspended: SelectOptionProps[] = [
    { label: "Edit User", value: "editUser" },
    { label: "Reset Password", value: "resetPassword" },
    { label: "Re-Activate", value: "reActivate" },
    { label: "Settings", value: "settings" },
  ];

  const activeData =
    data &&
    data.users?.map((item: UserData) =>
      mapTableData(
        item,
        handleSelect,
        isActive ? optionsActive : optionsSuspended
      )
    );
  const [tableData, setTableData] = useState<any>(activeData);
  //...............use effects..................
  useEffect(() => {
    refetch();
  }, [refetch, searchValue, selectedOption]);

  useEffect(() => {
    setTableHeader(getUserMenuHeaders(isActive, true));
    setTableData(activeData);

    setUsersCount(data?.users_count ?? 0);
  }, [data, isActive]);

  useEffect(() => {
    resetPagination();
  }, [isActive]);

  const resetPagination = () => {
    setPage(1);
    setPerPage(20);
  };

  useEffect(() => {
    if (activeModal === "settings") {
      navigate(`/admin/settings/${clickedRow.id}`);
    }
  }, [activeModal, navigate]);
  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Type here to search"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setPage(1);
              }}
              value={searchValue}
            />
            <VStack p="relative" zIndex="3">
              <Select
                onChange={(value: SelectOptionProps | null) => {
                  setSelectedOption(value);
                  resetPagination();
                }}
                placeholder="All Access Levels"
                options={optionsHeader}
                variant="default"
                value={selectedOption?.value}
                w={"240px"}
              />
            </VStack>

            <HStack border="1px solid var(--grey-300)" borderRadius="0.5rem">
              <Button
                variant="none"
                padding="0.5rem 1rem"
                onClick={() => setIsActive(true)}
                borderRadius="0.5rem"
                h="100%"
                fontWeight="500"
                background={isActive ? "var(--secondary-600)" : "white"}
                color={isActive ? "white" : "var(--grey-900)"}
              >
                Active
              </Button>
              <Button
                variant="none"
                h="100%"
                padding="0.5rem 1rem"
                borderRadius="0.5rem"
                fontWeight="500"
                onClick={() => setIsActive(false)}
                color={!isActive ? "white" : "var(--grey-900)"}
                background={!isActive ? "var(--secondary-600)" : "white"}
              >
                Suspended
              </Button>
            </HStack>
          </HStack>
          <Button onClick={onOpen}>Create User</Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Users found ({usersCount})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={usersCount}
              isLoadingData={isLoading}
            />
          </Box>
        </VStack>
        {isOpen && (
          <CreateUser
            isOpen={isOpen}
            onClose={onClose}
            isSimpSocial={true}
            refetchParent={refetch}
          ></CreateUser>
        )}

        {activeModal === "editUser" && (
          <EditUser
            isSimpSocial={true}
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

        {activeModal === "resetPassword" && (
          <ResetPassword
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}
        {activeModal === "reActivate" && (
          <ReActivate
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            userId={clickedRow?.id}
          />
        )}
        {activeModal === "suspend" && (
          <SuspendUser
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            userId={clickedRow?.id}
          />
        )}
      </VStack>
    </>
  );
};

export default SimpSocialUsers;
