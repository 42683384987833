import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  InventoryMappingIcon,
  SearchIcon,
} from "../../../components/icons/svg";
import { getPendingMapping } from "../../../utils/api/inventory-mapping.api";
import { Input, PaginatedTable } from "../../common";
import InventoryDrawer from "./InventoryDrawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const InventoryPending = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [dealership, setDealership] = useState({ name: "", id: "" });
  const [isAssignAccount, setIsAssignAccount] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState("");
  const [count, setCount] = useState<number>(0);

  const { data, isLoading } = useQuery({
    queryKey: ["pendingApi", page, perPage, searchStr],
    queryFn: () => {
      const params: any = {};
      if (!searchStr) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchStr;
      }

      return getPendingMapping(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setCount(data?.data?.response?.length ?? 0);
  }, [data]);

  const activeHeader = [
    { id: 1, value: "Name", label: "name" },
    { id: 3, value: "Received At", label: "receivedAt" },
    { id: 4, value: "Received From", label: "receivedFrom" },
    { id: 8, value: "Actions", label: "actions" },
  ];

  const activeData = data?.data?.response?.map((item: any) => ({
    name: (
      <HStack gap="1rem">
        {item?.dealership_avatar ? (
          <Image
            src={item?.dealership_avatar}
            width={38}
            height={38}
            style={{
              borderRadius: "50%",
            }}
            alt="avatar"
          />
        ) : (
          <AccountCircleIcon />
        )}
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            {item?.dealership_name || item?.filename}
          </Text>
          <Text textStyle="h6" fontWeight="500" color="var(--grey-700)">
            {item?.phone_number}
          </Text>
        </VStack>
      </HStack>
    ),
    id: item?.dealership_id,
    mapping_hash: item?.mapping_hash,
    headers: item?.csv_headers,
    all_csv_headers:item?.all_csv_headers,
    provider_id: item?.provider_id,
    filename: item?.filename,
    receivedAt: item?.received_at,
    receivedFrom: item?.received_from,
    dealership: item?.dealership_name,
    actions: (
      <Button
        variant="outline"
        onClick={() => setIsAssignAccount(true)}
        leftIcon={<InventoryMappingIcon />}
      >
        Inventory Mapping
      </Button>
    ),
  }));

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Dealerships..."
              value={searchStr}
              onChange={({ target: { value } }) => {setSearchStr(value); setPage(1);}}
            />
          </HStack>
        </HStack>
        <VStack w="100%" alignItems="flex-start">
          <Box w="100%" alignItems="flex-start" background="white">
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Dealerships ({data?.data?.response?.length})
            </Text>
            <PaginatedTable
              header={activeHeader}
              data={activeData}
              itemsPerPage={perPage}
              isLoadingData={isLoading}
              maxPageNumbers={5}
              tableHeight={420}
              currentPage={page}
              rowOnClick={(rowItem) => {
                setDealership({
                  name: rowItem?.dealership,
                  id: rowItem?.id,
                  mapping_hash: rowItem?.mapping_hash,
                  provider_id: rowItem?.provider_id,
                  filename: rowItem?.filename,
                  headers: rowItem?.headers,
                  all_headers: rowItem?.all_csv_headers,
                } as any);
              }}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              setCurrentPage={setPage}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              rowCount={count}
            />
          </Box>
        </VStack>
        {isAssignAccount && (
          <InventoryDrawer
            isOpen={isAssignAccount}
            onClose={() => setIsAssignAccount(false)}
            dealership={dealership}
          />
        )}
      </VStack>
    </>
  );
};

export default InventoryPending;
