import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Dealership from "../../../components/GlobalDashboard/DealershipDetail/Dealership/Dealership";
import TrainingVideos from "../../../components/GlobalDashboard/TrainingVideo/TrainingVideo";
import GPUsers from "../../../components/GlobalDashboard/DealershipDetail/Users/GPUsers";
import DealershipSettings from "../../../components/GlobalDashboard/DealershipDetail/DealershipSettings/DealershipSettings";
import LinesAndRingGroup from "../../../components/GlobalDashboard/DealershipDetail/LinesAndRingGroups/LinesAndRingGroups";
import { DealershipInventory } from "../../../components/GlobalDashboard/DealershipDetail/DealershipInventory/DealershipInventory";
import { useQuery } from "@tanstack/react-query";
import { getDealershipData } from "../../../utils/api/dealership-setting.api";
import Loader from "../../../components/common/Spinner";
import TooltipIfOverflow from "../../../components/common/TooltipIfOverflow";
import { impersonateUser } from "../../../utils/api/users.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { useAuthDispatch } from "../../../utils/context/AuthContext";

export default function DealershipsDetailPage() {
  const user = useUserInfo("user");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAuthDispatch();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");
  const [activeTab, setActiveTab] = useState(Number(tabParam) ?? 0);
  const { id } = useParams();
  const handleTabChange = (index: any) => {
    setActiveTab(index);
    const newUrl = location.pathname + "?" +`tab=${index}`;
    navigate(newUrl, { replace: true });
  };

  useEffect(()=>{
    setActiveTab(Number(tabParam || 0))
  }, [tabParam])

  useEffect(() => {
    // Parse the search query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const isFacebook = queryParams.has("facebook");
    const isTikTok = queryParams.has("tiktok");

    if (isFacebook || isTikTok) {
      setActiveTab(2);
    }
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ["dealershipdata", id],
    queryFn: getDealershipData,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleUserImpersonate = async () => {
    await impersonateUser(
      id,
      dispatch,
      true,
      {
        menu: "Users",
        "Sub-Menu": "Dealership Users",
      },
      user?.role,
      user?.id
    );
    navigate("/");
  };

  return (
    <>
      {isLoading && <Loader />}
      <HStack background="white" borderBottom="1px solid var(--grey-300)">
        <HStack
          w="100%"
          padding="1rem 1.5rem"
          // background="white"
          // borderBottom="1px solid var(--grey-300)"
        >
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => navigate("/dealerships")}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "var(--grey-900)",
                }}
              />
            </Box>
            <Text textStyle="h6" fontWeight="500">
              <TooltipIfOverflow>{data?.data?.name}</TooltipIfOverflow>{" "}
            </Text>
          </Button>
          <Button onClick={handleUserImpersonate}>
            Impersonate
          </Button>
        </HStack>
      </HStack>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start" gap="2rem">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          onChange={handleTabChange}
          defaultIndex={0}
          index={activeTab}
        >
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Dealership</Tab>
            <Tab w="15rem">Users</Tab>
            <Tab w="15rem">Dealership Settings</Tab>
            <Tab w="15rem">Lines & Ring Groups</Tab>
            <Tab w="15rem">Inventory</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              {activeTab === 0 && <Dealership />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 1 && <GPUsers />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 2 && <DealershipSettings />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 3 && <LinesAndRingGroup />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 4 && <DealershipInventory />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
