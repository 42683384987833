import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Delete, EditNote } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SearchIcon } from "../../../components/icons/svg";
import { fetchInventoryFeedProvider } from "../../../utils/api/global-config.api";
import { Dropdown, Input, PaginatedTable, Spinner } from "../../common";
import CreateInventoryFeedProvider from "../CreateInventoryFeedProvider/CreateInventoryFeedProvider";
import DeleteInventoryFeedProvider from "../DeleteInventoryFeedProvider/DeleteInventoryFeedProvider";
import EditInventoryFeedProvider from "../EditInventoryFeedProvider/EditInventoryFeedProvider";

const InventoryFeedProvider = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditNote />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <Delete />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchIFP", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchInventoryFeedProvider(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.inventory_feed_providers_count ?? 0);
    },
  });
  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "Email", label: "email" },
    { value: "Phone number", label: "phoneNumber" },
    { value: "Data Type", label: "dataType" },
    { value: "Notes", label: "notes" },
    { value: "Actions", label: "actions" },
  ];

  const tableData = data?.inventory_feed_providers?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.provider_name,
      email: item?.email,
      phoneNumber: item?.provider_phone ?? "-",
      dataType: item?.datatype ?? "-",
      notes: item?.notes ?? "-",
      directory_path: item?.directory_path,
      dealership_column: item?.dealership_column,
      actions: <Dropdown options={options} onSelect={handleSelect} />,
    };
  });

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setPage(1);
              }}
            />
          </HStack>
          <Button onClick={onOpen} padding="0 1.75rem">
            Create Inventory Provider
          </Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Inventory Providers ({count})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={count}
              isLoadingData={isLoading}
            />
          </Box>
        </VStack>

        {isOpen && (
          <CreateInventoryFeedProvider
            isOpen={isOpen}
            onClose={onClose}
            refetchParent={refetch}
          ></CreateInventoryFeedProvider>
        )}

        {activeModal === "edit" && (
          <EditInventoryFeedProvider
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

        {activeModal === "delete" && (
          <DeleteInventoryFeedProvider
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

      </VStack>
    </>
  );
};

export default InventoryFeedProvider;
