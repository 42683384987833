import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  ActivityLogIcon,
  DashboardIcon,
  DealershipIcon,
  InventoryMappingIcon,
  RegisterationIcon,
} from "../../components/icons/svg";

export const GlobalSidebarMenu = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    key: "dashboard",
    removeFilled: true,
  },
  {
    id: 2,
    name: "Dealerships",
    path: "/dealerships",
    icon: <DealershipIcon />,
    key: "dealerships",
  },

  {
    id: 3,
    name: "Users",
    path: "/global-users",
    icon: (
      <PeopleAltIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    key: "users",
  },
  {
    id: 4,
    name: "Activity Log",
    path: "/activity-log",
    icon: <ActivityLogIcon />,
    key: "activity_log",
  },

  {
    id: 5,
    name: "Global Configurations",
    path: "/global-configurations",
    icon: (
      <SettingsIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    key: "global_configurations",
  },
  {
    id: 6,
    name: "Brand & Campaign Registration",
    path: "/campaign-registeration",
    icon: <RegisterationIcon />,
    key: "brand_campaign_registration",
    removeFilled: true,
  },
  {
    id: 6,
    name: "Billing",
    path: "/billing",
    icon: (
      <LocalAtmIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    key: "brand_campaign_registration",
  },
  {
    id: 7,
    name: "Inventory Mapping",
    path: "/inventory-mapping",
    icon: <InventoryMappingIcon />,
    key: "inventory",
  },
  {
    id: 8,
    name: "Announcements",
    path: "/announcements",
    icon: (
      <CampaignIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    key: "announcements",
  },
  {
    id: 9,
    name: "Intercom Dashboard",
    path: "/",
    icon: <DashboardIcon />,
    key: "Intercom",
  },
];
