import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import Loader from "../common/Spinner";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { impersonateUser } from "../../utils/api/users.api";
import { useAuthDispatch } from "../../utils/context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectBroadcast = () => {
  const location = useLocation();
  const navigate = useNavigate(); // For navigation on error
  const dispatch = useAuthDispatch();
  const toast = useToast();
  const user = useUserInfo("user");

  const handleUserImpersonate = async (id:any, isBroadcast = false) => {
    try {
      await impersonateUser(
        id,
        dispatch,
        true,
        {
          menu: "Users",
          "Sub-Menu": "Dealership Users",
        },
        user?.role,
        user?.id
      );
      window.open(
        `${window.location.origin}/broadcast?active=${isBroadcast ? 0 : 1}`,
        "_self"
      );
    } catch (error) {
      // Show an error toast
      toast({
        title: "Error",
        description: "Failed to impersonate the user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Redirect to the Not Found page
      navigate("/not-found");
    }
  };

  useEffect(() => {
    const pathname = location.pathname; // e.g., "/broadcast-redirect/1"
    const searchParams = new URLSearchParams(location.search); // e.g., "?active=1"

    // Extract the id from the pathname
    const id = pathname.split("/").pop(); // Extracts "1"

    // Extract the active number from the query parameters
    const active = searchParams.get("active"); // Extracts "1"

    if (id) {
      handleUserImpersonate(id, active === "0" ? true : false);
    } else {
      // Redirect if the ID is missing
      navigate("/not-found");
    }
  }, [location.pathname]);

  return <Loader />;
};

export default RedirectBroadcast;
