import { Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../../components/common/Spinner";
import {
  emailValidation,
  phoneValidation,
  textValidation,
} from "../../../utils";
import { fetchContact } from "../../../utils/api/guest-mode";
import AddEmpInfo from "./AddEmpInfo";
import PersonalInfo from "./PersonalInfo";

const MyProfile = () => {
  const schema = (contactId: string, activeStep: number) => {
    return yup.object().shape({
      ...(activeStep === 1
        ? {
            employments_attributes: yup.array().of(
              yup.object().shape({
                company_name: yup
                  .string()
                  .required("Company name is required")
                  .nullable()
                  .min(1, "Company name cannot be empty"),
                phone: phoneValidation(),
              })
            ),
          }
        : {
            first_name:
              contactId === "[contact_id]"
                ? textValidation("First Name")
                : textValidation("First name").required(
                    "First Name is required"
                  ),
            middle_name: textValidation("Middle Name"),
            last_name: textValidation("Last Name"),
            nick_name: textValidation("Nick Name"),
            home_number: phoneValidation(),
            work_number: phoneValidation(),
            email: emailValidation(),
            phone_number: phoneValidation(),
          }),
    });
  };
  const [activeStep, setActiveStep] = useState(0);
  const { id, dId } = useParams();
  const schemaObject = schema(id || "", activeStep);

  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schemaObject),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employments_attributes",
  });

  const { isFetching } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess(data: any) {
      if (data?.data?.contacts) {
        const { address_attributes, employments_attributes, ...rest } =
          data?.data?.contacts;

        // making arrays of key value pairs of fields without nested objects
        Object.entries(rest).forEach(([key, value]: any) => {
          setValue(key, value);
        });

        // nested object
        if (address_attributes) {
          Object.entries(address_attributes).forEach(([key, value]: any) => {
            setValue(`address_attributes.${key}`, value);
          });
        }

        // Update nested employment fields
        if (employments_attributes && employments_attributes.length > 0) {
          employments_attributes.forEach((employment: any, index: any) => {
            append(employment);
          });
        }
      }
    },
  });

  return (
    <VStack w="100%" spacing={4} alignItems="flex-start" bg="#fff">
      <Text
        textStyle="h3"
        textAlign="left"
        pl="1rem"
        mt="1rem"
        fontWeight="700"
      >
        {activeStep === 0 ? "Personal Info" : "Employee Info"}
      </Text>

      <VStack
        alignItems="flex-start"
        // w={{ base: "100%", md: "45%" }}
        w="100%"
        padding="1rem"
        h="100%"
      >
        {activeStep === 0 && (
          <PersonalInfo
            setActiveStep={setActiveStep}
            control={control}
            handleSubmit={handleSubmit}
            setValue={setValue}
          />
        )}
        {activeStep === 1 && (
          <AddEmpInfo
            setActiveStep={setActiveStep}
            control={control}
            handleSubmit={handleSubmit}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
          />
        )}
      </VStack>
      {isFetching && <Loader />}
    </VStack>
  );
};

export default MyProfile;
