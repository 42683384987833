import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import {
  getSarahAI,
  getSarahAIBroadcast,
} from "../../../../../utils/api/sarah.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import DatePicker from "../../../../common/DateRangePicker";
import Loader from "../../../../common/Spinner";

type SarahAIProps = {
  clickedRow: any;
};

const SarahAI = ({ clickedRow }: SarahAIProps) => {
  const dealership = useUserInfo("dealership");

  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: "",
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  const { isLoading, data } = useQuery({
    queryKey: ["fetchSarahAI", selectionRange, clickedRow, dealership?.id],
    queryFn: () => {
      const params: any = {
        dealership_id: dealership?.id,
        contact_id: clickedRow,
      };

      if (selectionRange && selectionRange?.selected) {
        if (selectionRange?.startDate)
          params.start_date = selectionRange?.startDate.toString();
        if (selectionRange?.endDate)
          params.end_date = selectionRange?.endDate.toString();
      }

      return getSarahAI(params);
    },
    retry: false,
  });

  const { isLoading: isLoadingBroadcast, data: broadcastData } = useQuery({
    queryKey: ["fetchBroadcastAPI", selectionRange, clickedRow, dealership?.id],
    queryFn: () => {
      const params: any = {
        dealership_id: dealership?.id,
        contact_id: clickedRow,
      };

      if (selectionRange && selectionRange?.selected) {
        if (selectionRange?.startDate)
          params.start_date = selectionRange?.startDate.toString();
        if (selectionRange?.endDate)
          params.end_date = selectionRange?.endDate.toString();
      }

      return getSarahAIBroadcast(params);
    },
    retry: false,
  });

  const {
    outbound_message = 0,
    outbound_emails = 0,
    outbound_calls = 0,
    inbound_messages = 0,
    inbound_calls = 0,
    inbound_emails = 0,
    appointment_set = 0,
    appointment_shown = 0,
  } = data?.data || {};

  const {
    appointment_set: bdcAppointmentSet = 0,
    appointment_shown: bdcAppointmentShown = 0,
    inbound_calls: bdcInboundCalls = 0,
    inbound_emails: bdcInboundEmails = 0,
    inbound_messages: bdcMessages = 0,
    outbound_message: bdcOutboundMessages = 0,
  } = broadcastData?.data?.report || {};

  const calculateTotal = useCallback((data: any) => {
    const dataObj = data || {}; // Get the data object or an empty object if undefined

    // Calculate the total sum of all attributes dynamically
    const total = Object.values(dataObj).reduce(
      (sum: number, value) => sum + (typeof value === "number" ? value : 0),
      0
    );

    return total;
  }, []);

  const sarahData = useMemo(() => {
    return [
      { id: 2, label: "Outbound SMS", value: outbound_message },
      { id: 3, label: "Outbound Emails", value: outbound_emails }, // Placeholder for outbound emails
      { id: 4, label: "Outbound Calls", value: outbound_calls }, // Placeholder for outbound calls
      { id: 5, label: "Inbound SMS/MMS", value: inbound_messages },
      { id: 6, label: "Inbound Calls", value: inbound_calls },
      { id: 7, label: "Inbound Emails", value: inbound_emails },
      // { id: 8, label: "Calls Summarized", value: 0 }, // Placeholder value
      // { id: 9, label: "Calls Scored", value: 0 }, // Placeholder value
      { id: 10, label: "Appointments Set", value: appointment_set },
      { id: 11, label: "Appointments Shown", value: appointment_shown },
    ];
  }, [
    appointment_set,
    appointment_shown,
    inbound_calls,
    inbound_emails,
    inbound_messages,
    outbound_calls,
    outbound_emails,
    outbound_message,
  ]);

  const bdcData = useMemo(() => {
    return [
      { id: 2, label: "Outbound SMS", value: bdcOutboundMessages },
      // { id: 3, label: "Outbound Emails", value: outbound_emails }, // Placeholder for outbound emails
      // { id: 4, label: "Outbound Calls", value: outbound_calls }, // Placeholder for outbound calls
      { id: 5, label: "Inbound SMS/MMS", value: bdcMessages },
      { id: 6, label: "Inbound Calls", value: bdcInboundCalls },
      { id: 7, label: "Inbound Emails", value: bdcInboundEmails },
      // { id: 8, label: "Calls Summarized", value: 0 }, // Placeholder value
      // { id: 9, label: "Calls Scored", value: 0 }, // Placeholder value
      { id: 10, label: "Appointments Set", value: bdcAppointmentSet },
      { id: 11, label: "Appointments Shown", value: bdcAppointmentShown },
    ];
  }, [
    bdcAppointmentSet,
    bdcAppointmentShown,
    bdcInboundCalls,
    bdcInboundEmails,
    bdcMessages,
    bdcOutboundMessages,
  ]);

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      {(isLoading || isLoadingBroadcast) && <Loader />}
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="0"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Sarah AI
          </Text>

          <DatePicker
            value={selectionRange}
            onChange={(value) => {
              setSelectionRange(value);
            }}
            shouldShowClear
            label="Select Date Range"
            shouldShowLabel={false}
          />
        </HStack>
        <VStack mt="1rem" alignItems="flex-start" gap="1rem" w="100%">
          <Text textStyle="h6" color="var(--grey-600)">
            Found ({calculateTotal(data?.data)})
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              padding="1rem"
              textStyle="h4"
              w="100%"
              fontWeight="500"
              background="var(--grey-100)"
            >
              Sarah.ai
            </Text>
            <VStack
              alignItems="flex-start"
              w="100%"
              padding="1rem"
              paddingTop="0.5rem"
            >
              {sarahData.map((item, index) => (
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="h5" fontWeight="500">
                    {item.label}
                  </Text>
                  <Text
                    textStyle="h5"
                    fontWeight="400"
                    color={
                      index === 0 ? "var(--primary-600)" : "var(--grey-900)"
                    }
                  >
                    {item.value}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </VStack>

        {/* <VStack mt="1rem" alignItems="flex-start" gap="1rem" w="100%">
          <Text textStyle="h6" color="var(--grey-600)">
            Found ({calculateTotal(broadcastData?.data?.report)})
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              padding="1rem"
              textStyle="h4"
              w="100%"
              fontWeight="500"
              background="var(--grey-100)"
            >
              BDC Report
            </Text>
            <VStack
              alignItems="flex-start"
              w="100%"
              padding="1rem"
              paddingTop="0.5rem"
            >
              {bdcData.map((item, index) => (
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="h5" fontWeight="500">
                    {item.label}
                  </Text>
                  <Text
                    textStyle="h5"
                    fontWeight="400"
                    color={
                      index === 0 ? "var(--primary-600)" : "var(--grey-900)"
                    }
                  >
                    {item.value}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </VStack> */}
      </VStack>
    </VStack>
  );
};
export default SarahAI;
