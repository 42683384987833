import axios from "axios";
import { SERVICES } from "../../config";


  export interface ApiResult {
    // Define your API response structure here
    data: any;
    headers: any;
    // Add other relevant fields
  }
    axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  export const getUserDealerships = async (): Promise<ApiResult> =>axios.get(`${SERVICES.apiBaseUrl}/api/users/dealerships`)
  export const getUserImage = async ({queryKey}:any): Promise<ApiResult> =>axios.get(`${SERVICES.apiBaseUrl}/api/profile_picture?dealership_id=${queryKey[1]}`)


