import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getVoicemailCounts } from "../../utils/api/broadcast.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Dealership_Roles } from "../../utils/roles";
import { Drawer } from "../common";
import Loader from "../common/Spinner";
import BroadcastRVMListing from "./BroadcastRVMListing";
import CreateBulkRVMForm from "./CreateBulkRVM";

function BroadcastRVM() {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: new Date(""),
    selected: false,
  });
  const user = useUserInfo("user");
  const isSalesPerson = Dealership_Roles[2] === user?.role;

  const { dealership } = user || {};

  const [searchParam] = useSearchParams();
  const activeTab = Number(searchParam.get("active") ?? 0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`broadcastCounts`, dateRange, activeTab],
    queryFn: () =>
      getVoicemailCounts({ dealershipId: dealership?.id, dateRange }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const TabsData = useMemo(() => {
    const {
      completed = 0,
      paused = 0,
      pending = 0,
      rejected = 0,
      in_progress = 0,
    } = data?.data?.status_count || {};
    return [
      {
        id: 3,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>All</Text>
            <Text
              textStyle="h4"
              background="var(--secondary-50)"
              color="var(--secondary-600)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {completed + paused + pending + rejected + in_progress}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type=""
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
      {
        id: 3,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>Pending</Text>
            <Text
              textStyle="h4"
              background="var(--orange-50)"
              color="var(--orange-800)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {pending}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type="pending"
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
      {
        id: 4,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>Paused</Text>
            <Text
              textStyle="h4"
              background="var(--primary-50)"
              color="var(--primary-600)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {paused}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type="paused"
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
      {
        id: 5,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>In Progress</Text>
            <Text
              textStyle="h4"
              background="var(--primary-50)"
              color="var(--primary-600)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {in_progress}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type="in_progress"
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
      {
        id: 6,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>Completed</Text>
            <Text
              textStyle="h4"
              background="var(--secondary-50)"
              color="var(--secondary-600)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {completed}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type="completed"
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
      {
        id: 7,
        title: (
          <HStack justifyContent="space-between" w="100%">
            <Text>Rejected</Text>
            <Text
              textStyle="h4"
              background="var(--orange-50)"
              color="var(--orange-800)"
              borderRadius="0.25rem"
              padding="0 1rem"
            >
              {rejected}
            </Text>
          </HStack>
        ),
        content: (
          <BroadcastRVMListing
            parentRefetch={refetch}
            type="rejected"
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      },
    ];
  }, [data?.data?.status_count, dateRange, refetch, shouldRefetch]);

  const [isOpenMsg, setIsOpenMsg] = useState(false);

  const onOpenMsg = () => setIsOpenMsg(true);
  const onCloseMsg = () => setIsOpenMsg(false);

  return (
    <>
      {isLoading && <Loader />}
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text textStyle="h3" fontWeight="700">
          Broadcast RVM
        </Text>
        {/* <Input
          maxW="320px"
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Type here to search"
        /> */}
      </Box>
      <Box
        background="white"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        margin="1rem"
        // height="100%"
        padding="1rem"
      >
        <Tabs variant="primary" isLazy onChange={() => refetch()}>
          <HStack w="100%" justifyContent="space-between" mb="1rem">
            <TabList w="max-content">
              {TabsData.map((item, index) => {
                return (
                  <Tab key={item?.id} padding="1rem" w="11rem">
                    {item?.title}
                  </Tab>
                );
              })}
            </TabList>
            {!isSalesPerson && (
              <HStack>
                <Button onClick={onOpenMsg}>Create Bulk RVM</Button>
              </HStack>
            )}
          </HStack>

          <TabPanels height="100%" overflow="auto">
            {TabsData.map((item) => (
              <TabPanel key={item.id} h="100%">
                {item.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        {isOpenMsg && (
          <Drawer
            isOpen={isOpenMsg}
            onClose={onCloseMsg}
            title="Create Bulk RVM"
          >
            <CreateBulkRVMForm
              onCloseMsg={() => {
                onCloseMsg();
                setShouldRefetch(true);
                refetch();
              }}
            />
          </Drawer>
        )}
      </Box>
    </>
  );
}

export default BroadcastRVM;
