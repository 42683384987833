import {
  Box,
    Button,
    DrawerFooter,
    Textarea,
    useToast,
    VStack,
  } from "@chakra-ui/react";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import moment from "moment";
  import { useEffect, useState } from "react";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { Controller, useForm } from "react-hook-form";
  import { convertDateTime } from "../../utils";
  import { editAppointment } from "../../utils/api/meeting.api";
  import { fetchUsersDP } from "../../utils/api/users.api";
  import { useTwilioDispatch } from "../../utils/context/TwilioContext";
  import { useUserInfo } from "../../utils/hooks/useUserInfo";
  import { Drawer, Input, Select } from "../common";
  import SelectRadio from "../common/SelectRadio";
  import Loader from "../common/Spinner";
  import {
    EditMeetingForm,
    editMeetingValidation,
    meetingDefaultValues,
    MeetingOptions,
  } from "./util";
  import AppointmentReasonModal from "./AppointmentReasonModal";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
  interface Type {
    value?: any;
    // Other properties if any
  }
  export const EditMeeting = ({
    isOpen,
    onClose,
    slotData,
    apiCall,
    contactid,
  }: any) => {
    
    const toast = useToast();
    const dealership = useUserInfo("dealership");
    const userId = useUserInfo("id");
    const dispatch = useTwilioDispatch();
    const [reason, setReason] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const { data, isLoading } = useQuery({
      queryKey: ["Appointment-users", dealership?.id, ""],
      queryFn: () =>
        fetchUsersDP({
          dealership_id: dealership?.id,
          status: "active",
          role_titles: "Owner/GM,Manager/Admin,Sales Person,BDC Agent",
        }),
      refetchOnWindowFocus: false,
      retry: false,
    });

    const editmutation = useMutation(editAppointment);

    const { handleSubmit, control, setValue, reset } =
      useForm<EditMeetingForm>({
        defaultValues: meetingDefaultValues,
        resolver: yupResolver(editMeetingValidation) as any,
      });

      const handleEditFormSubmit = (values: EditMeetingForm) => {
        let newdate = moment(values.date).format("yyyy-MM-DD");
        const transformedValues = {
          event: {
            is_sale_meeting: Boolean(values?.type?.value),
            participant_ids: [values?.userid],
            start_time: convertDateTime(newdate, values.start_time),
            reason: reason,
            notes: values.notes,
          },
        };

        editmutation.mutate(
          {
            id: dealership.id,
            meetingId: slotData?.id,
            contactid: contactid,
            data: transformedValues,
          },
          {
            onSuccess(data, variables, context) {
              handleClose();
              apiCall();
              toast({
                description: "Event edited successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            },
            onError: (error: any) => {
              toast({
                description:
                  error?.response?.data?.errors?.toString() ??
                  "Something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            },
          }
        );
      };
      
  
    const handleModalSubmit = (values: any) => {
      setModalOpen(false);
      handleSubmit((values) => handleEditFormSubmit(values))();
    };
  
    // Set default values for start time, end time, and date based on slotData
    useEffect(() => {
      if (slotData) {
        reset(slotData);
      }
    }, [slotData]);

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <>
      <Drawer
        isOpen={isOpen}
        onClose={handleClose}
        title="Edit Meeting"
      >
        <VStack padding="1rem 1.3rem" alignItems="flex-start" w="100%">
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <Select
                    label="Select User"
                    onChange={(value) => onChange(value.value)}
                    value={value}
                    placeholder="Select a user"
                    options={
                      data?.users?.map((user: any) => ({
                        label: user?.full_name,
                        value: user?.id,
                      })) ?? []
                    }
                    variant="default"
                    w="100%"
                    error={error?.message}
                  />
                );
              }}
              name="userid"
              control={control}
              rules={{ required: true }}
            />
            <Box minW={"100%"} className="calenderDatePicker">
              <Controller
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePicker
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    dateFormat="MM/dd/yyyy"
                    customInput={
                      <Input
                        label="Select Date"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<CalendarIcon />}
                        maxW="100%"
                        minW={"100%"}
                        error={error?.message}
                        placeholder={"mm/dd/yyyy"}
                      />
                    }
                  />
                )}
                name="date"
                control={control}
                rules={{ required: true }}
              />
            </Box>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    label="Select Time"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    type="time"
                    maxW="100%"
                    error={error?.message}
                    isRequired
                  />
                );
              }}
              name="start_time"
              control={control}
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <Input
                    hasIcon={false}
                    label="Add Notes"
                    isIconRight={false}
                    placeholder="Type Here.."
                    isTextarea={true}
                    w="100%"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                );
              }}
              name="notes"
              control={control}
              rules={{ required: true }}
            />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <SelectRadio
                  options={MeetingOptions}
                  hasIcon={true}
                  isStepper={false}
                  labelDark={true}
                  label="Select Meeting Type"
                  onChange={onChange}
                  defaultValue={value}
                />
              );
            }}
            name="type"
            control={control}
            rules={{ required: true }}
          />

  
          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            <Button variant="outline" w="100%" size="xl" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              w="100%"
              size="xl"
              onClick={(event) => {
                event.preventDefault();
                setModalOpen(true);
              }}
            >
              Edit
            </Button>
          </DrawerFooter>
        </VStack>
        {editmutation?.isLoading && <Loader />}
      </Drawer>
        {isModalOpen && (
          <AppointmentReasonModal
            isOpen={isModalOpen}
            headerText="Reason for Updation"
            bodyText="Please provide a reason for updating the meeting."
            setModalOpen={setModalOpen}
            onSubmit={handleModalSubmit}
            reason={reason}
            setReason={setReason}
          />
        )}
      </>
    );
  };
  