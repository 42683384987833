import { Icon } from "@chakra-ui/react";

const TimeIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.6888 9.7474V6.1849C10.6888 6.00434 10.6298 5.85503 10.5117 5.73698C10.3937 5.61892 10.2444 5.5599 10.0638 5.5599C9.88325 5.5599 9.73394 5.61892 9.61588 5.73698C9.49783 5.85503 9.4388 6.00434 9.4388 6.1849V9.9974C9.4388 10.0807 9.45269 10.1571 9.48047 10.2266C9.50825 10.296 9.54991 10.3655 9.60547 10.4349L12.6055 13.5391C12.7305 13.678 12.8867 13.7439 13.0742 13.737C13.2617 13.73 13.418 13.6641 13.543 13.5391C13.668 13.4141 13.7305 13.2613 13.7305 13.0807C13.7305 12.9002 13.668 12.7474 13.543 12.6224L10.6888 9.7474ZM10.0013 18.3307C8.86241 18.3307 7.78602 18.112 6.77214 17.6745C5.75825 17.237 4.87283 16.6398 4.11589 15.8828C3.35894 15.1259 2.76172 14.2405 2.32422 13.2266C1.88672 12.2127 1.66797 11.1363 1.66797 9.9974C1.66797 8.85851 1.88672 7.78212 2.32422 6.76823C2.76172 5.75434 3.35894 4.86892 4.11589 4.11198C4.87283 3.35503 5.75825 2.75781 6.77214 2.32031C7.78602 1.88281 8.86241 1.66406 10.0013 1.66406C11.1402 1.66406 12.2166 1.88281 13.2305 2.32031C14.2444 2.75781 15.1298 3.35503 15.8867 4.11198C16.6437 4.86892 17.2409 5.75434 17.6784 6.76823C18.1159 7.78212 18.3346 8.85851 18.3346 9.9974C18.3346 11.1363 18.1159 12.2127 17.6784 13.2266C17.2409 14.2405 16.6437 15.1259 15.8867 15.8828C15.1298 16.6398 14.2444 17.237 13.2305 17.6745C12.2166 18.112 11.1402 18.3307 10.0013 18.3307ZM10.0013 17.0807C11.9457 17.0807 13.6124 16.3863 15.0013 14.9974C16.3902 13.6085 17.0846 11.9418 17.0846 9.9974C17.0846 8.05295 16.3902 6.38628 15.0013 4.9974C13.6124 3.60851 11.9457 2.91406 10.0013 2.91406C8.05686 2.91406 6.39019 3.60851 5.0013 4.9974C3.61241 6.38628 2.91797 8.05295 2.91797 9.9974C2.91797 11.9418 3.61241 13.6085 5.0013 14.9974C6.39019 16.3863 8.05686 17.0807 10.0013 17.0807Z"
        fill="#757575"
      />
    </Icon>
  );
};

export default TimeIcon;
