"use client";
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { convertUnderscoreToLabel } from "../../utils";

const PieChart = ({
  data,
  orientation = "vertical",
  height = 250,
  width = 250,
  labelDirection = "row",
}: {
  data: Record<string, number>;
  orientation?: "vertical" | "horizontal";
  height?: number;
  width?: number;
  labelDirection?: "row" | "column";
}) => {
  const newOption: any = useMemo(
    () => ({
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        show: false,
        position: "bottom",
        markers: {
          width: 16,
          height: 16,
        },
      },
      stroke: {
        width: 2,
        colors: ["transparent"],
      },
      colors: ["#1565C0", "#2196F3", "#66BB6A"],
      labels: Object.keys(data).map((item) => convertUnderscoreToLabel(item)),
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "13px",
                fontFamily: "var(--font-red-hat)",
                fontWeight: 400,
                color: "#212121",
                lineHeight: "normal",
                whiteSpace: "wrap",
                width: "100px",
              },
              value: {
                show: true,
                fontSize: "24px",
                fontFamily: "var(--font-red-hat)",
                fontWeight: 700,
                color: "#212121",
                lineHeight: "40px",
              },
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, w }: any) => {
          const seriesName = w.globals.labels[seriesIndex];
          const value = series[seriesIndex];
          const color = w.config.colors[seriesIndex];
          return `
        <div style="padding: 0.5rem 0.75rem; background: #263238; color: #fff; border-radius: 5px;">
            <div style="display: flex; align-items: center;">
              <div style="width: 0.5rem; height: 0.5rem; background: ${color}; margin-right: 0.38rem;"></div>
              <span style="font-size: 0.75rem; line-height: 1rem; font-weight: 500">${seriesName}</span>
            </div>
            <div style="margin-top: 1px; margin-left: 14px; font-size: 0.75rem; line-height: 1rem; font-weight: 500">${value}</div>
            <div style="position: absolute; background: #263238; clip-path: polygon(100% 0, 0 0, 50% 100%); width: 1.5rem; height: 1.5rem; bottom: -16px; left: 50%; transform: translateX(-50%); z-index: 999"></div>
          </div>`;
        },
      },
    }),
    [data]
  );

  const newSeries = useMemo(() => Object.values(data), [data]);

  return (
    <Stack
      className="pie-chart-wrapper"
      direction={orientation === "horizontal" ? "row" : "column"}
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      h="100%"
      spacing="2rem"
    >
      <ReactApexChart
        options={newOption}
        series={newSeries}
        type="donut"
        height={height}
        width={width}
      />
      <HStack
        w="100%"
        alignItems="flex-start"
        justifyContent="center"
        spacing="3rem"
        flexDirection={labelDirection}
      >
        {Object.keys(data).map((key, index) => (
          <HStack key={key} alignItems="flex-start">
            <Box
              w="0.8rem"
              h="0.8rem"
              background={newOption.colors[index]}
              borderRadius="0.3rem"
              mt="0.15rem"
            ></Box>
            <VStack alignItems="flex-start" gap="0">
              <Text textStyle="captionSmall" fontWeight="600">
                {convertUnderscoreToLabel(key)}
              </Text>
              <Text textStyle="h4" fontWeight="700">
                {data[key]}
              </Text>
            </VStack>
          </HStack>
        ))}
      </HStack>
    </Stack>
  );
};

export default PieChart;
