import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import EmailCard from "../Notifications/Emails/EmailCard";
import { MessagesCard } from "../Notifications/Messages/MessageCard";
import MissedCallCard from "../Notifications/MissedCall/MissedCallCard";
import NotificationCard from "../Notifications/Notifications/NotificationCard";
import { PingCard } from "../Notifications/Pings/PingCard";
import { ReminderCard } from "../Notifications/Reminders/ReminderCard";

interface ExpandableModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  count: number;
  api?: any;
}

const ExpandableModal = ({
  isOpen,
  onClose,
  type,
  count,
  api,
}: ExpandableModalProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(Math.ceil(count / perPage));

  const handleTotalPages = (totalRecords: number) => {
    if (totalRecords !== totalPages) {
      setTotalPages(totalRecords);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const typeSetter = () => {
    switch (type) {
      case "Reminder":
        return (
          <ReminderCard
            page={currentPage}
            perPage={perPage}
            onClose={onClose}
          />
        );
      case "Email":
        return (
          <EmailCard page={currentPage} perPage={perPage} onClose={onClose} />
        );
      case "Message":
        return (
          <MessagesCard
            page={currentPage}
            perPage={perPage}
            setTotalPages={handleTotalPages}
            onClose={onClose}
          />
        );
      case "Ping":
        return (
          <PingCard page={currentPage} perPage={perPage} onClose={onClose} />
        );
      case "Notification":
        return (
          <NotificationCard
            page={currentPage}
            perPage={perPage}
            onClose={onClose}
            api={api}
          />
        );
      case "Missed Call":
        return (
          <MissedCallCard
            page={currentPage}
            perPage={perPage}
            onClose={onClose}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type === "Notification"
            ? "All Notifications"
            : `${type} Notifications`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {typeSetter()}
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            mt={4}
          >
            <Text>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              variant="none"
            >
              <ChevronLeftIcon />
            </Button>

            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              variant="none"
            >
              <ChevronRightIcon />
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExpandableModal;
