import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { markAllMessageAsRead } from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { MessagesCard } from "./MessageCard";
import { useState } from "react";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";

interface MessagesProps {
  onOpen: (val: string, count: number) => void;
  onClose: () => void;
  count: number;
}

export const Messages = ({ onOpen, onClose, count }: MessagesProps) => {
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const mutation = useMutation(markAllMessageAsRead);
  const toast = useToast();
  const [refetch, setRefetch] = useState(false);
  const { refetchNotifications } = useNotificationContext();
  const handleMarkAllAsRead = () => {
    mutation.mutate(
      { dId: dealerId?.id },
      {
        onSuccess: (data: any) => {
          setRefetch(true);
          refetchNotifications(); // This will trigger the refetch of the parent's query
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <VStack w="100%" alignItems="flex-start" gap="0">
        <HStack w="100%" justifyContent="space-between" padding="1rem">
          <Text textStyle="h5" fontWeight="500">
            All Messages ({count})
          </Text>
          <HStack>
            {isImpersonating && (
              <Text
                textStyle="h6"
                color="var(--primary-600)"
                fontWeight="500"
                cursor="pointer"
                onClick={handleMarkAllAsRead}
              >
                Mark all as read
              </Text>
            )}
            <Button
              variant="none"
              onClick={() => onOpen("Message", count)}
              display="flex"
            >
              <FullscreenIcon />
            </Button>
          </HStack>
        </HStack>

        <MessagesCard page={1} perPage={5} refetch={refetch} onClose={onClose} />
      </VStack>
    </>
  );
};
