import { useEffect } from 'react';

const useIntercomScript = (appId) => {
  useEffect(() => {
    const loadIntercomScript = () => {
      if (window.Intercom) return; // Intercom is already loaded

      const script = document.createElement('script');
      script.src = `https://widget.intercom.io/widget/${appId}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.Intercom) {
          window.Intercom('boot', {
            app_id: appId,
          });

          makeIntercomDraggable(); // Make the widget draggable
        }
      };

      script.onerror = () => {
        console.error('Failed to load Intercom script');
      };

      return () => {
        // Cleanup script if component unmounts
        document.body.removeChild(script);
      };
    };

    const makeIntercomDraggable = () => {
      const intercomLauncher = document.querySelector('.intercom-launcher'); // Select the launcher
      if (intercomLauncher) {
        intercomLauncher.style.position = 'absolute'; // Change position to absolute

        let isDragging = false;
        let offsetX, offsetY;

        const onMouseDown = (e) => {
          isDragging = true;
          offsetX = e.clientX - intercomLauncher.getBoundingClientRect().left;
          offsetY = e.clientY - intercomLauncher.getBoundingClientRect().top;
        };

        const onMouseMove = (e) => {
          if (isDragging) {
            intercomLauncher.style.left = `${e.clientX - offsetX}px`;
            intercomLauncher.style.top = `${e.clientY - offsetY}px`;
          }
        };

        const onMouseUp = () => {
          isDragging = false;
        };

        intercomLauncher.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
          intercomLauncher.removeEventListener('mousedown', onMouseDown);
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        };
      }
    };

    loadIntercomScript();
  }, [appId]);
};

export default useIntercomScript;
