import {
  Button,
  Checkbox,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@tanstack/react-query";
import { Input, PhoneInput, Select } from "../../../components/common";
import { updateContact } from "../../../utils/api/guest-mode";

import { Controller, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import Loader from "../../../components/common/Spinner";

const AddEmpInfo = (props: any) => {
  const {
    setActiveStep,
    control,
    handleSubmit,
    setValue,
    fields,
    append,
    remove,
  } = props;
  const { id, dId } = useParams();
  const toast = useToast();
  const email = useWatch({ control, name: "email" });
  const phone_number = useWatch({ control, name: "phone_number" });

  const isEmailOrPhoneEmpty = !email && !phone_number;

  const residency_statuses = ["Parents", "Rent", "Own", "Other"];

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    if (!data.phone_number) {
      data.phone_number = "";
    }
    if (!data.home_number) {
      data.home_number = "";
    }
    if (!data.work_number) {
      data.work_number = "";
    }
    mutation.mutate(
      { contact_uuid: id, dId, payload: { contact: data } },
      {
        onSuccess: () => {
          toast({
            description: "My portal updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error updating my portal: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack position="relative" w="100%">
      {mutation?.isLoading && <Loader />}

      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem 0 4rem"
        gap="1rem"
        h={{ md: "calc(100vh - 220px)", base: "calc(100vh - 150px)" }}
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.62rem"
          >
            <Text fontWeight="700" textStyle="h4">
              Address
            </Text>
            <HStack w="100%">
              <Controller
                name="address_attributes.street_address"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="Street Address"
                  />
                )}
              />

              <Controller
                name="address_attributes.unit"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="Unit #"
                    maxW="100px"
                  />
                )}
              />
              <Controller
                name="address_attributes.city"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="City"
                    w="100%"
                  />
                )}
              />
            </HStack>
            <HStack w="100%">
              <Controller
                name="address_attributes.state"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="State"
                  />
                )}
              />
              <Controller
                name="address_attributes.zip"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={10}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Zip"
                  />
                )}
              />
            </HStack>
            <HStack w="100%">
              <Controller
                name="address_attributes.country"
                control={control}
                defaultValue={"usa"}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    onChange={(value: any) =>
                      setValue("address_attributes.country", value?.value)
                    }
                    placeholder="Country"
                    options={[
                      { label: "USA", value: "usa" },
                      { label: "Canada", value: "canada" },
                    ]}
                    variant="default"
                    w="100%"
                  />
                )}
              />

              <Controller
                name="address_attributes.residence_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Residency Status"
                    onChange={(value: any) =>
                      setValue(
                        "address_attributes.residence_status",
                        value?.value
                      )
                    }
                    placeholder="Residency Status"
                    options={residency_statuses?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                  />
                )}
              />

              <Controller
                name="address_attributes.rental_mortgage_amount"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={20}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Rent/Mortgage Amount"
                  />
                )}
              />
            </HStack>
            <HStack>
              <Controller
                name="address_attributes.years"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    maxLength={3}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Years"
                  />
                )}
              />
              <Controller
                name="address_attributes.months"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={2}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Month"
                  />
                )}
              />
            </HStack>
          </VStack>
        </VStack>

        <VStack alignItems="flex-start" mb="10px" w="100%">
          {fields &&
            fields?.map((employment: any, index: number) => (
              <VStack
                key={index}
                w="100%"
                alignItems="flex-start"
                padding="1rem"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                background="#efeded9c"
                gap="0.5rem"
                position="relative"
                mb="10px"
              >
                <Text fontWeight="700" textStyle="h4">
                  Employment History
                </Text>
                <Button
                  variant="none"
                  position="absolute"
                  top="0.25rem"
                  right="0.35rem"
                  padding="0"
                  background="transparent"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon
                    style={{ color: "var(--grey-800)", width: "1.25rem" }}
                  />
                </Button>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].company_name`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Company Name"
                        error={error?.message || ""}
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].monthly_income`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={20}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Monthly Income"
                      />
                    )}
                  />
                </HStack>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].years`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={3}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Years"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].months`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={2}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Month"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].occupation`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Occupation"
                      />
                    )}
                  />
                </HStack>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].title`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Title"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].phone`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <PhoneInput
                        {...field}
                        label="Phone Number"
                        placeholder="Enter phone number"
                        isRequired={false}
                        isSmall={true}
                        error={error?.message || ""}
                      />
                    )}
                  />
                </HStack>
                <Controller
                  name={`employments_attributes[${index}].current_employer`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      size="lg"
                      variant="whiteCheck"
                      onChange={(e: any) =>
                        setValue(
                          `employments_attributes[${index}].current_employer`,
                          e.target.checked
                        )
                      }
                      isChecked={field.value}
                    >
                      Current Employer
                    </Checkbox>
                  )}
                />
                {isEmailOrPhoneEmpty && (
                  <Text color="red.500">
                    Please ensure you provide either an email address or a phone
                    number
                  </Text>
                )}
              </VStack>
            ))}
        </VStack>
        {fields && fields.length < 7 && (
          <Button
            padding="1rem"
            variant="outline"
            w="100%"
            background="var(--grey-50)"
            onClick={() => append({})}
          >
            Add More
          </Button>
        )}
      </VStack>
      <HStack w="100%" justifyContent="flex-end" pt="0.5rem">
        <Button
          w="200px"
          marginTop="auto"
          left="0"
          bottom={{ md: "0", base: "30px" }}
          onClick={() => {
            setActiveStep(0);
          }}
        >
          Back
        </Button>
        <Button
          w="200px"
          marginTop="auto"
          left="0"
          bottom={{ md: "0", base: "30px" }}
          onClick={handleSubmit(onSubmit)}
          isDisabled={isEmailOrPhoneEmpty}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
};

export default AddEmpInfo;
