import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { fetchLeadById } from "../../../../../utils/api/contact-lead";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { Spinner } from "../../../../common";
import { SalesLeadIcon, SimpleDateIcon } from "../../../../icons/svg";

const LeadDetailPage = ({ clickedRecord, setClickedRecord, id }: any) => {
  const dId = useUserInfo("dealership")?.id;
  const [isOpen, setIsOpen] = useState(false);
  const [leadDetail, setLeadDetail] = useState<any>([]);

  const leadId =
    "details" in clickedRecord ? clickedRecord.details?.id : clickedRecord?.id;

  const { isFetching } = useQuery({
    queryKey: ["fetchLeadById", dId, id, leadId],
    queryFn: fetchLeadById,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setLeadDetail(data?.data);
    },
  });
  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      background="var(--grey-50)"
      padding="1rem 1.3rem"
      height="calc(100vh - 120px)"
      overflow="auto"
    >
      <VStack
        w="100%"
        alignItems="flex-start"
        borderTop="1px solid var(--grey-300)"
      >
        <HStack w="100%" justifyContent="space-between" padding="1rem 0">
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => setClickedRecord(null)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
            Back
          </Button>
          <HStack></HStack>
        </HStack>
        <VStack
          w="100%"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          gap="0"
          background="white"
        >
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            background="white"
            padding="1rem"
            borderTopRightRadius="0.5rem"
            borderTopLeftRadius="0.5rem"
            borderBottom="1px solid var(--grey-300)"
            w="100%"
          >
            <HStack alignItems="flex-start" gap="0.69rem" w="100%">
              <Box
                sx={{
                  svg: {
                    width: "1.25rem",
                    height: "1.25rem",
                  },
                }}
              >
                <SalesLeadIcon />
              </Box>
              <VStack alignItems="flex-start" gap="0" w="100%">
                <Text textStyle="h4" fontWeight="600">
                  Active Sales Lead
                </Text>
              </VStack>
            </HStack>
            <HStack gap="0.5rem" w="100%" justifyContent="flex-end">
              <SimpleDateIcon />
              <Text textStyle="h6" fontWeight="600">
                {leadDetail?.created_at
                  ? moment(leadDetail?.created_at).format(
                      "dddd MMM DD YY H:mm a"
                    )
                  : ""}
              </Text>
            </HStack>
          </HStack>
          <VStack w="100%" alignItems="flex-start" gap="0">
            <Box padding="1rem" w="100%" background="var(--secondary-50)">
              <Text textStyle="h6" fontWeight="500">
                Details
              </Text>
            </Box>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Status
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.status}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Raw Lead
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text
                    textStyle="h6"
                    fontWeight="500"
                    color="blue"
                    cursor="pointer"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    View Raw Lead
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Lead Source
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text
                    textStyle="h6"
                    fontWeight="500"
                  >
                    {leadDetail?.lead_source?.name}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>
          <VStack w="100%" alignItems="flex-start" gap="0">
            <Box padding="1rem" w="100%" background="var(--secondary-50)">
              <Text textStyle="h6" fontWeight="500">
                Customer Info
              </Text>
            </Box>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    First Name
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.first_name}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Last Name
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.last_name}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Full Name
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.first_name}{" "}
                    {leadDetail?.contact?.last_name}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Mobile Number
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.phone_number}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Home Address
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.address}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Creation Date
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.contact?.contact_created_date
                      ? moment(
                          leadDetail?.contact?.contact_created_date
                        ).format("dddd MMM DD YY H:mm a")
                      : ""}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>

          <VStack w="100%" alignItems="flex-start" gap="0">
            <Box padding="1rem" w="100%" background="var(--secondary-50)">
              <Text textStyle="h6" fontWeight="500">
                Dates
              </Text>
            </Box>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Creation Date
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.created_at
                      ? moment(leadDetail?.created_at).format(
                          "dddd MMM DD YY H:mm a"
                        )
                      : ""}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    First Response Date
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.time_to_first_response
                      ? moment(leadDetail?.time_to_first_response).format(
                          "dddd MMM DD YY H:mm a"
                        )
                      : ""}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>

          <VStack w="100%" alignItems="flex-start" gap="0">
            <Box padding="1rem" w="100%" background="var(--secondary-50)">
              <Text textStyle="h6" fontWeight="500">
                Vehicle Submitted
              </Text>
            </Box>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Inventory Status
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.vehicle?.status}
                  </Text>
                </Box>
              </HStack>

              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Year
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.vehicle?.year}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Make
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.vehicle?.make}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" gap="0" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="15rem">
                  <Text textStyle="h6" fontWeight="500">
                    Model
                  </Text>
                </Box>
                <Box
                  padding="0.75rem"
                  w="100%"
                  borderLeft="1px solid var(--grey-300)"
                >
                  <Text textStyle="h6" fontWeight="500">
                    {leadDetail?.vehicle?.model}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Raw Lead Detail</ModalHeader>
            <ModalBody>
              <Text whiteSpace="pre-wrap">
                {leadDetail?.details
                  ? JSON.stringify(leadDetail?.details, null, 5)
                  : "No Data Found"}
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default LeadDetailPage;
