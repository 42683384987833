import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  Dropdown,
  Input,
  PaginatedTable,
  Spinner,
} from "../../../components/common";
import {
  DuplicateIcon,
  EditIcon,
  SearchIcon,
} from "../../../components/icons/svg";
import { fetchTemplate } from "../../../utils/api/scorecard-settings.api";
import ActivateTemplate from "./ActivateTemplate";
import CreateTemplate from "./CreateTemplate";
import DeactivateTemplate from "./DeactivateTemplate";
import DuplicateTemplate from "./DuplicateTemplate";
import EditTemplate from "./EditTemplate";

const TranscriptionSettingsForGlobalConfig = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Duplicate
        </Box>
      ),
      value: "duplicate",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Activate
        </Box>
      ),
      value: "activate",
    },
  ];
  const optionsDeactivate: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Duplicate
        </Box>
      ),
      value: "duplicate",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          De-Activate
        </Box>
      ),
      value: "deactivate",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchTranscriptionSettingsGlobal", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }

      return fetchTemplate(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.scorecards_count ?? 0);
    },
  });

  const tableHeader = [
    { value: "Name of Template", label: "name" },
    { value: "Status", label: "status" },
    { value: "Actions", label: "actions" },
  ];
  const tableData = data
    ? data?.scorecards.map((template: any) => ({
        id: template.id,
        name: template.name || "-",
        category: (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0.62rem"
            border="1px solid var(--secondary-600)"
          >
            <Text textStyle="h6" color="var(--secondary-600)">
              {template.category === "sms"
                ? "SMS"
                : template.category === "calls"
                ? "Calls"
                : "-"}
            </Text>
          </Box>
        ),
        status: (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0.62rem"
            border="1px solid var(--secondary-600)"
            borderColor={
              template.status === "inactive" ? "red" : "var(--secondary-600)"
            }
          >
            <Text
              textStyle="h6"
              color={
                template.status === "inactive" ? "red" : "var(--secondary-600)"
              }
            >
              {template.status || "-"}
            </Text>
          </Box>
        ),
        actions: (
          <Dropdown
            options={
              template.status === "inactive" ? options : optionsDeactivate
            }
            onSelect={handleSelect}
          />
        ),
      }))
    : [];

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      gap="0"
      overflow="auto"
      h="calc(100vh - 172px)"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1.5rem"
        background="white"
      >
        <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
          <Input
            maxW="20rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
          />
        </HStack>
        <Button onClick={onOpen}>Create Scorecard</Button>
      </HStack>
      <VStack w="100%" alignItems="flex-start" padding="1.5rem">
        <Box
          w="100%"
          alignItems="flex-start"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          <Text
            textStyle="caption"
            padding="1.5rem 1rem 0.75rem"
            w="100%"
            color="var(--grey-600)"
          >
            Total Templates ({count})
          </Text>
          <PaginatedTable
            header={tableHeader}
            data={tableData}
            itemsPerPage={perPage}
            maxPageNumbers={3}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
            rowOnClick={(rowItem) => {
              setClickedRow(rowItem);
            }}
            perPageChange={(value) => {
              setPage(1);
              setPerPage(Number(value?.label));
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={count}
            isLoadingData={isLoading}
          />
        </Box>
      </VStack>
      {isOpen && (
        <CreateTemplate
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        ></CreateTemplate>
      )}

      {activeModal === "edit" && (
        <EditTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "duplicate" && (
        <DuplicateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}
      {activeModal === "activate" && (
        <ActivateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}
      {activeModal === "deactivate" && (
        <DeactivateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

    </VStack>
  );
};

export default TranscriptionSettingsForGlobalConfig;
