import { Box, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PaginatedTable } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { fetchLeadProviderReport } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";

const LeadProviderReport = () => {
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchLeadProviderReport",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
    ],
    queryFn: fetchLeadProviderReport,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const tableHeader = [
    { id: 1, value: "Lead Sources", label: "leadSources" },
    { id: 2, value: "# Of Leads", label: "noOfLeads" },
    { id: 3, value: "Engaged Rate", label: "engagedRate" },
    { id: 4, value: "Invalid", label: "invalid" },
    { id: 5, value: "Duplicate", label: "duplicate" },
    { id: 5, value: "Sold", label: "sold" },
  ];

  const tableData = data?.data?.data?.map((item: any) => ({
    leadSources: item["Lead Sources"],
    noOfLeads: <Text color="var(--primary-600)">{item["# Of Leads"]}</Text>,
    engagedRate: item["Engaged Rate"],
    invalid: item["Invalid"],
    duplicate: item["Duplicate Leads"],
    sold: item["Sold"],
  }));

  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <Box>
          <DatePicker
            value={selectionRange}
            onChange={(value) => {setSelectionRange(value); setPage(1);}}
            w="100%"
          />
        </Box>
        <ExportReport reportType="lead_provider" />
      </HStack>
      <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
        Found {data?.data?.total_count}
      </Text>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.total_count}
        isLoadingData={isLoading}
      />
    </Box>
  );
};

export default LeadProviderReport;
