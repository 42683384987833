import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { DeleteForever } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import { Dispatch, SetStateAction, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "../../../../assets/avatar.svg";
import { convertDateContacts, getLastActivity } from "../../../../utils";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

type ContactListingProps = {
  contacts?: any[];
  setPage: Dispatch<SetStateAction<number>>;
  count?: number;
  foundIndex?: number;
  onClick?: (item: any, index: number) => void;
  removedContacts?: any[];
  setRemovedContacts?: Dispatch<SetStateAction<any[]>>;
  target?: string;
};

function ContactListing({
  contacts,
  setPage,
  count,
  foundIndex,
  onClick,
  setRemovedContacts,
  removedContacts,
  target = "scrollableDiv",
}: ContactListingProps) {
  const shouldShowTooltip = !!setRemovedContacts;

  const [searchStr, setSearchStr] = useState("");
  return (
    <>
      <InfiniteScroll
        dataLength={contacts?.length || 0}
        next={() => {
          setPage((prev: any) => prev + 1);
        }}
        hasMore={(contacts?.length || 0) < (count || 0)}
        loader={<h4>Loading...</h4>}
        scrollableTarget={target}
      >
        <VStack gap="0">
          {contacts?.map((item: any, index: number) => {
            const isItemDeselected = removedContacts
              ?.map(String)
              .includes(String(item.id));
            return (
              <Box
                key={item?.id}
                w="100%"
                padding="1rem"
                cursor="pointer"
                borderBottom="1px solid var(--grey-300)"
                background={foundIndex === index ? "var(--primary-50)" : ""} // use this color for active state
                onClick={() => {
                  if (setRemovedContacts)
                    isItemDeselected
                      ? setRemovedContacts(
                          removedContacts?.filter(
                            (contactId) => contactId != item.id
                          ) || []
                        )
                      : setRemovedContacts((prev) => [...prev, item.id]);
                  onClick && onClick(item, index);
                }}
                style={
                  removedContacts && isItemDeselected
                    ? { background: "#FF7F7F" }
                    : {}
                }
              >
                <HStack
                  alignItems="flex-start"
                  gap="0.75rem"
                  justifyContent="space-between"
                >
                  <HStack alignItems="flex-start" gap="0.75rem">
                    {/* <Image
                      src={
                        `https://source.unsplash.com/random/1920x1080/?abstract,pattern&${item?.id}` ||
                        Avatar
                      }
                      width="36px"
                      height="36px"
                      alt="avatar"
                      borderRadius="50%"
                    /> */}
                    <AccountCircleIcon
                      style={{
                        width: "36px",
                        height: "36px",
                      }}
                    />
                    <VStack gap="0.25rem" alignItems="flex-start">
                      {!item?.use_nick_name && (
                        <Text
                          textStyle="h5"
                          fontWeight="600"
                          color="var(--grey-800)"
                        >
                          {item.first_name} {item?.last_name}
                        </Text>
                      )}
                      {item?.use_nick_name && (
                        <Text
                          textStyle="h5"
                          fontWeight="600"
                          color="var(--grey-800)"
                        >
                          {item?.nick_name}
                        </Text>
                      )}

                      <Text
                        textStyle="h5"
                        fontWeight="500"
                        color="var(--grey-800)"
                      >
                        {item.phone_number}
                      </Text>
                      {item?.last_activity && (
                        <>
                          <Text
                            textStyle="h6"
                            fontWeight="500"
                            color="var(--grey-600)"
                            maxW="15rem"
                            textAlign="left"
                          >
                            Last activity:{" "}
                            {getLastActivity(item?.last_activity)}
                          </Text>
                          <Text
                            textStyle="h6"
                            fontWeight="500"
                            color="var(--grey-600)"
                            maxW="12rem"
                            textAlign="left"
                          >
                            {convertDateContacts(
                              item?.last_activity?.created_at,
                              null
                            )}
                          </Text>
                        </>
                      )}
                    </VStack>
                  </HStack>

                  {shouldShowTooltip ? (
                    isItemDeselected ? (
                      <DeleteForever />
                    ) : (
                      <Delete />
                    )
                  ) : (
                    <></>
                  )}
                </HStack>
              </Box>
            );
          })}
        </VStack>
      </InfiniteScroll>
    </>
  );
}

export default ContactListing;
