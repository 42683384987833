import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getBDCCampaign = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
  );
export const getBDCCampaignByID = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}`
  );
export const createCategories = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/categories`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const updateCategories = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/categories/${payload.category_id}`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );

export const createBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const updateBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
        "Sub-Menu": payload?.subMenu,
      },
    }
  );
export const duplicateBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/duplicate`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const deleteCampaign = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
    {
      headers: {
        menu: "BDC Campaign",
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const deleteCategory = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/categories/${payload.category_id}`,
    {
      headers: {
        menu: "BDC Campaign",
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const getOutbooundLine = async (params: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/v1/lines/search`, {
    params,
  });
export const getCategories = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/categories?is_ro_tradeup=${queryKey[2]}`
  );
export const checkLeadSource = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/check_conflicting_lead_source`,
    payload.payload,
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const updateStatus = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.campaign_id}/toggle_active`,
    {},
    {
      headers: {
        menu: `BDC Campaign`,
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const createActivity = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities`,
    payload.payload,
    {
      headers: {
        menu: "BDC Campaign/Activity",
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const getActivity = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/activities?page=${queryKey[3]}&&per_page=${queryKey[4]}`
  );
export const deleteActivity = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
    {
      headers: {
        menu: "BDC Campaign/Activity",
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const editActivity = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
    payload.payload,
    {
      headers: {
        menu: "BDC Campaign/Activity",
        Dealership: payload.id,
        "Portal-Type": true,
        isBdc: true,
      },
    }
  );
export const getLedger = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/ledgers?event=${queryKey[3]}&time=${queryKey[4]}&q=${queryKey[5]}&page=${queryKey[6]}&per_page=${queryKey[7]}`
  );
export const getDealershipHours = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/business_hours`
  );
export const getEnrolledContacts = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/enrolled_contacts?q=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}&type=${queryKey[6]}`
  );
export const getRingGroupUsers = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups/ring_groups_users?ids=${queryKey[1]}&page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );

// Global Dealership Settings APIs

export const adminDealershipAPIs = {
  createCategories: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/categories`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  getCategories: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/categories?is_ro_tradeup=${queryKey[2]}`
    ),
  updateCategories: async (payload: any): Promise<ApiResult> =>
    axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/categories/${payload.category_id}`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  getBDCCampaign: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
    ),
  createBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  getBDCCampaignByID: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}`
    ),
  updateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign/${payload?.subMenu}`,
          Dealership: payload.id,
        },
      }
    ),
  duplicateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/duplicate`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  checkLeadSource: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/check_conflicting_lead_source`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  updateStatus: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.campaign_id}/toggle_active`,
      {},
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": `BDC Campaign`,
          Dealership: payload.id,
        },
      }
    ),
  createActivity: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": "BDC Campaign/Activity",
          Dealership: payload.id,
        },
      }
    ),
  getActivity: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/activities?page=${queryKey[3]}&&per_page=${queryKey[4]}`
    ),

  deleteActivity: async (payload: any): Promise<ApiResult> =>
    axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": "BDC Campaign/Activity",
          Dealership: payload.id,
        },
      }
    ),
   deleteCampaign : async (payload: any): Promise<ApiResult> =>
      axios.delete(
        `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
        {
          headers: {
            menu: "Dealership Settings",
            "Sub-Menu": "BDC Campaign",
            Dealership: payload.id,
          },
        }
      ),
   deleteCategory : async (payload: any): Promise<ApiResult> =>
      axios.delete(
        `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/categories/${payload.category_id}`,
        {
          headers: {
            menu: "Dealership Settings",
            "Sub-Menu": "BDC Campaign",
            Dealership: payload.id,
          },
        }
      ),
  editActivity: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      payload.payload,
      {
        headers: {
          menu: "Dealership Settings",
          "Sub-Menu": "BDC Campaign/Activity",
          Dealership: payload.id,
        },
      }
    ),
  getLedger: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/ledgers?event=${queryKey[3]}&time=${queryKey[4]}&q=${queryKey[5]}&page=${queryKey[6]}&per_page=${queryKey[7]}`
    ),
  getEnrolledContacts: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/enrolled_contacts?q=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}&type=${queryKey[6]}`
    ),
};

// Global BDC campaign APIs
export const globalBDCApis = {
  createCategories: async (payload: any): Promise<ApiResult> =>
    axios.post(`${SERVICES.apiBaseUrl}/api/admin/categories`, payload.payload, {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "BDC Campaign",
      },
    }),

  getCategories: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/categories?is_ro_tradeup=${queryKey[2]}`
    ),

  updateCategories: async (payload: any): Promise<ApiResult> =>
    axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/categories/${payload.category_id}`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "BDC Campaign",
        },
      }
    ),

  getBDCCampaign: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
    ),

  createBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "BDC Campaign",
        },
      }
    ),

  getBDCCampaignByID: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(`${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${queryKey[2]}`),

  updateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": `BDC Campaign/${payload?.subMenu}`,
        },
      }
    ),

  duplicateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/duplicate`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": `BDC Campaign`,
        },
      }
    ),

  updateStatus: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.campaign_id}/toggle_active`,
      {},
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": `BDC Campaign`,
        },
      }
    ),

  createActivity: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "BDC Campaign/Activity",
        },
      }
    ),

  getActivity: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${queryKey[2]}/activities?page=${queryKey[3]}&&per_page=${queryKey[4]}`
    ),

  deleteActivity: async (payload: any): Promise<ApiResult> =>
    axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "BDC Campaign/Activity",
        },
      }
    ),
    deleteCampaign : async (payload: any): Promise<ApiResult> =>
      axios.delete(
        `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}`,
        {
          headers: {
            menu: "Dealership Settings",
            "Sub-Menu": "BDC Campaign",
          },
        }
      ),
   deleteCategory : async (payload: any): Promise<ApiResult> =>
      axios.delete(
        `${SERVICES.apiBaseUrl}/api/admin/categories/${payload.category_id}`,
        {
          headers: {
            menu: "Dealership Settings",
            "Sub-Menu": "BDC Campaign",
          },
        }
      ),

  editActivity: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      payload.payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "BDC Campaign/Activity",
        },
      }
    ),
};

/// lead centre middle panel APIS
export const getactiveBDCCampaigns = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/active_campaigns?q=${queryKey[2]}`
  );
export const getactiveUserBDCCampaigns = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/enrolled_campaigns`
  );
export const userCompaignStatus = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/contacts/${payload.contactid}/update_campaign_enrollments  `,
    payload.payload
  );

//leadformids
export const getLeadFormIds = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/facebook/pages`
  );
