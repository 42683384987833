import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";

interface CustomInputProps {
  type?: string;
  value?: Date | string | null;
  onChange?: (value: string | null) => void; // Updated
  label?: string;
  isRequired?: boolean;
  error?: string;
  hasIcon?: boolean;
  isIconRight?: boolean;
  icon?: React.ReactNode;
}

const parseTime = (time: Date | string | null): Date | null => {
  if (!time) return null;

  if (typeof time === "string") {
    const [hours, minutes] = time.split(":").map(Number);

    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59
    ) {
      console.error("Invalid time string:", time);
      return null;
    }

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  if (time instanceof Date && !isNaN(time.getTime())) {
    return time;
  }

  console.error("Invalid time value:", time);
  return null;
};

const CustomTimeInput: React.FC<CustomInputProps> = ({
  type = "text",
  value = null,
  onChange,
  label = "",
  isRequired = false,
  error = "",
  hasIcon = false,
  isIconRight = false,
  icon,
}) => {
  const [internalValue, setInternalValue] = useState<Date | null>(parseTime(value));

  useEffect(() => {
    setInternalValue(parseTime(value));
  }, [value]);

  const handleTimeChange = (date: Date | null) => {
    if (date) {
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;

      setInternalValue(date); // Keep the internal state as a Date object for ReactDatePicker
      if (onChange) {
        onChange(formattedTime); // Pass the formatted time string
      }
    } else {
      setInternalValue(null);
      if (onChange) {
        onChange(null); // Pass null for no value
      }
    }
  };

  return (
    <Box w="100%" className="timePickerWrapper">
      {label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
          alignContent={"center"}
          display={"flex"}
        >
          {label}{" "}
          {isRequired && (
            <Box as="span" color="red.500">
              *
            </Box>
          )}
        </FormLabel>
      )}
      <InputGroup w="100%">
        {hasIcon && !isIconRight && (
          <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
        )}
        <ReactDatePicker
          selected={internalValue}
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Time"
          dateFormat="hh:mm aa"
          customInput={
            <Input
              paddingRight={hasIcon && isIconRight ? "42px" : ""}
              paddingLeft={hasIcon && !isIconRight ? "42px" : ""}
              borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
              w="100%"
              placeholder="__:__"
            />
          }
        />
        {hasIcon && isIconRight && (
          <InputRightElement pointerEvents="none">{icon}</InputRightElement>
        )}
      </InputGroup>
      {error && (
        <Text color="red.500" mt="2">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default CustomTimeInput;
