import { Box, Button, HStack, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Input, PaginatedTable } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { PDFIcon, SearchIcon } from "../../../components/icons/svg";
import { downloadBlob, humanize } from "../../../utils";
import {
  activateLeadWarCampaign,
  downloadFile,
  getLeadWar,
} from "../../../utils/api/leadwar.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import CreateDigitalLead from "./CreateDigitalLead";

export default function DigitalLeadPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [query, setQuery] = useState("");
  const [leadId, setLeadId] = useState();
  const [isSms, setIsSms] = useState<boolean | null>(null);
  const id = useUserInfo("dealership")?.id;
  const mutation = useMutation(activateLeadWarCampaign);
  const [isOpenCreateCampaign, setIsOpenCreateCampaign] = useState(false);
  const onOpenCreateCampaign = () => setIsOpenCreateCampaign(true);
  const onCloseCreateCampaign = () => setIsOpenCreateCampaign(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["leadwars", id, page, perPage, query],
    queryFn: getLeadWar,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const exportOptions: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];

  const actionsOptions = [
    { label: "Edit", value: "edit" },
    {
      label: "Communication Logs",
      value: "logs",
    },
  ];

  const handleSelect = (option: any, id: any) => {
    if (option === "text") {
      handleActivateCampaign("sms", id?.id);
    } else if (option === "email") {
      handleActivateCampaign("email", id?.id);
    } else if (option === "edit") {
      if (id?.subject) {
        setIsSms(false);
      } else {
        setIsSms(true);
      }
      onOpenCreateCampaign();
      setLeadId(id?.id);
    } else if (option === "logs") {
      if (id?.email_logs_id) {
        navigate(`/emails/communication/${id?.email_logs_id}`);
      } else if (id?.message_campaigns_id) {
        navigate(`/broadcast/communication/${id?.message_campaigns_id}`);
      }
    }
  };

  const salesHeader = [
    { id: 1, value: "Digital Lead War Campaign Name", label: "fullName" },
    { id: 11, value: "ID", label: "uuid" },
    { id: 2, value: "Total Contacts", label: "contact_count" },
    { id: 3, value: "Filters", label: "filters" },
    { id: 4, value: "Created DateTime", label: "createdAt" },
    { id: 5, value: "Status", label: "status" },
    { value: "Action Buttons", label: "actionButtons" },
    { id: 6, value: "Actions", label: "actions" },
  ];

  const salesData = data?.data?.digital_lead_wars?.map((item: any) => ({
    fullName: <Text whiteSpace="nowrap">{item?.name}</Text>,
    uuid:item?.uuid,
    filters: item?.filters ? (
      <Text whiteSpace="nowrap">
        {Object?.keys(item?.filters)?.map((key) => (
          <li key={key}>{humanize(key)}</li>
        ))}
      </Text>
    ) : (
      ""
    ),
    leadProvider: "---",
    contact_count: (
      <Text
        cursor={item?.campaign_start_at ? "pointer" : "none"}
        color={item?.campaign_start_at ? "blue" : ""}
        onClick={() => {
          if (item?.campaign_start_at) {
            if (item?.email_logs_id) {
              navigate(`/emails/communication/${item?.email_logs_id}`);
            } else if (item?.message_campaigns_id) {
              navigate(
                `/broadcast/communication/${item?.message_campaigns_id}`
              );
            }
          }
        }}
      >
        {item?.contact_count}
      </Text>
    ),
    createdAt: moment(item.created_at).format("MMM DD, YYYY hh:mm A"),
    status: item?.campaign_start_at ? "Activated" : "Pending",
    actionButtons: item?.campaign_start_at ? null : (
      <HStack
        alignItems="center"
        justifyContent="flex-start"
        padding="0"
        margin="0"
      >
        {item?.subject ? (
          <Button onClick={() => handleActivateCampaign("email", item?.id)}>
            Email Blast Now
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleActivateCampaign("sms", item?.id);
            }}
          >
            Broadcast Message Now
          </Button>
        )}
      </HStack>
    ),
    actions: (
      <Dropdown
        options={
          !item?.campaign_start_at
            ? actionsOptions.slice(0, 1)
            : actionsOptions.slice(1)
        }
        onSelect={(option) => handleSelect(option?.value, item)}
      />
    ),
  }));
  const handleActivateCampaign = async (type: string, lead_id: any) => {
    mutation.mutate(
      { id, lead_id, type },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "Digital lead war campaign activated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error activating Digital lead war campaign: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const handleSelectExport = async (option: SelectOptionProps) => {
    try {
      setIsFileDownloading(true);
      const response = await downloadFile(id);
      setIsFileDownloading(false);

      if (response?.data) {
        downloadBlob(response.data, "Digital Lead War.pdf");
      } else {
        throw new Error("No data in response");
      }
    } catch (error) {
      console.error("Error downloading", error);
    }
  };
  return (
    <>
      <HStack
        background="white"
        padding="1.5rem"
        w="100%"
        justifyContent="space-between"
      >
        <HStack w="100%">
          <Input
            maxW="12rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            onChange={(e) => {setQuery(e.target.value); setPage(1);}}
          />
        </HStack>
        <HStack>
          <Button
            variant="primary"
            fontWeight="500"
            onClick={() => {
              onOpenCreateCampaign();
              setIsSms(true);
            }}
          >
            Create Bulk SMS
          </Button>
          <Button
            variant="primary"
            fontWeight="500"
            onClick={() => {
              onOpenCreateCampaign();
              setIsSms(false);
            }}
          >
            Compose Blast
          </Button>
          <Dropdown
            options={exportOptions}
            onSelect={handleSelectExport}
            hasText={true}
            text="Export"
          />
        </HStack>
      </HStack>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <PaginatedTable
          header={salesHeader}
          data={salesData}
          itemsPerPage={perPage}
          currentPage={page}
          maxPageNumbers={3}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          setCurrentPage={setPage}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          isLoadingData={isLoading}
          rowCount={data?.data?.digital_lead_war_count}
        />
      </Box>
      {isOpenCreateCampaign && (
        <CreateDigitalLead
          isOpen={isOpenCreateCampaign}
          onClose={() => {
            onCloseCreateCampaign();
            setLeadId(undefined);
            setIsSms(null);
          }}
          refetch={refetch}
          id={leadId}
          isSms={isSms}
        />
      )}
      {(mutation?.isLoading || isFileDownloading) && <Loader />}
    </>
  );
}
