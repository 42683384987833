import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getSarahAI = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/sarah_ai/reports?contact_id=${params.contact_id}`,
    { params }
  );

export const getSarahAIBroadcast = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/bdc_campaigns/bdc_campaign_report?contact_id=${params.contact_id}`,
    { params }
  );

export const getSarahAIGraphs = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/sarah_ai/sarah_graphs`,
    { params }
  );

export const getSarahAIEngagements = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/sarah_ai/sarah_lead_engagement`,
    { params }
  );
export const getLinkCount = ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/sarah_ai/sarah_links_and_count`
  );
