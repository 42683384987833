import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useMemo, useState } from "react";
import {
  getLinkCount,
  getSarahAIEngagements,
  getSarahAIGraphs,
} from "../../../../../utils/api/sarah.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import PieChart from "../../../../Charts/PIeChart";
import DatePicker from "../../../../common/DateRangePicker";
import Loader from "../../../../common/Spinner";
import { EmptyIcon } from "../../../../icons/svg";
import LeadEngagementChart from "./LeadEngagementChart";
import MessagesChart from "./MessagesChart";
import TextRepliesRatioChart from "./TextRepliesRatioChart";

export default function SarahAIGraphs() {
  const dealership = useUserInfo("dealership");
  const [selectedLeadSource, setSelectedLeadSource] = useState([]);

  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: "",
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  const { isLoading, data, isFetching } = useQuery({
    queryKey: ["fetchSarahAI", selectionRange, dealership?.id],
    queryFn: () => {
      const params: any = {
        dealership_id: dealership?.id,
      };

      if (selectionRange && selectionRange?.selected) {
        if (selectionRange?.startDate)
          params.start_date = selectionRange?.startDate.toString();
        if (selectionRange?.endDate)
          params.end_date = selectionRange?.endDate.toString();
      }

      return getSarahAIGraphs(params);
    },
    retry: false,
  });

  const {
    isLoading: isLoadingSarah,
    data: sarahData,
    isFetching: isFetchingSarah,
  } = useQuery({
    queryKey: [
      "fetchSarahEngagement",
      selectionRange,
      dealership?.id,
      selectedLeadSource,
    ],
    queryFn: () => {
      const params: any = {
        dealership_id: dealership?.id,
        lead_source_ids: selectedLeadSource.map((leadSource: any) =>
          Number(leadSource.value)
        ),
      };

      if (selectionRange && selectionRange?.selected) {
        if (selectionRange?.startDate)
          params.start_date = selectionRange?.startDate.toString();
        if (selectionRange?.endDate)
          params.end_date = selectionRange?.endDate.toString();
      }

      return getSarahAIEngagements(params);
    },
    retry: false,
  });

  const { lead_engagement_by_day = [], messages_vs_replies_ratio = [] } =
    data?.data || {};

  const sortedRatio = useMemo(() => {
    if (!messages_vs_replies_ratio?.length) return [];
    return messages_vs_replies_ratio.sort((a: any, b: any) =>
      moment(a.period).diff(moment(b.period))
    );
  }, [messages_vs_replies_ratio]);

  const sortedEngagement = useMemo(() => {
    if (!lead_engagement_by_day?.length) return [];
    return lead_engagement_by_day.sort((a: any, b: any) =>
      moment(a.period).diff(moment(b.period))
    );
  }, [lead_engagement_by_day]);

  const chartContainerStyle: React.CSSProperties = {
    padding: "20px",
    margin: "20px 0",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    width: "100%",
  };

  const { data: linkcount } = useQuery({
    queryKey: ["getLinkCount", dealership?.id],
    queryFn: getLinkCount,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="0"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Sarah AI Performance
          </Text>
          <Box position="relative" zIndex={99}>
            <DatePicker
              value={selectionRange}
              onChange={(value) => {
                setSelectionRange(value);
              }}
              shouldShowClear
              label="Select Date Range"
              shouldShowLabel={false}
            />
          </Box>
        </HStack>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <VStack mt="1rem" alignItems="flex-start" gap="1rem" w="100%">
            <LeadEngagementChart data={sortedEngagement} />
            <TextRepliesRatioChart data={sortedRatio} />
            <MessagesChart
              data={sarahData?.data?.lead_sources || []}
              setSelectedLeadSource={setSelectedLeadSource}
              selectedLeadSource={selectedLeadSource}
              isLoading={isLoadingSarah || isFetchingSarah}
            />
            <div style={chartContainerStyle}>
              {isLoading && <Loader />}
              <VStack
                gap="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Text textStyle="h6" fontWeight="700" mb="2rem">
                  Types of Links Sent and their Counts
                </Text>
                {linkcount?.data ? (
                  <PieChart
                    data={linkcount?.data}
                    orientation="vertical"
                    // labelDirection="column"
                    height={350}
                    width={350}
                  />
                ) : (
                  <HStack justifyContent="center" alignItems="center" h="100%">
                    <EmptyIcon />
                    <Text
                      textStyle="h5"
                      fontWeight="400"
                      color="var(--grey-500)"
                    >
                      No data found
                    </Text>
                  </HStack>
                )}
              </VStack>
            </div>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
}
