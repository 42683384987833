import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { MessageHistory } from "../../../../../../utils/types";
import { ChatIcon, PingingsIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";
import {
  filterLinks,
  getFileNameFromURL,
  getPingMessage,
} from "../../../../../../utils";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";
import DOMPurify from "dompurify";

type SingleMessageCardProps = {
  item: MessageHistory;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleMessageCard({ item, setClickedRecord }: SingleMessageCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => setClickedRecord(item)}
      title={item.details.type === "ping" ? "Ping" : "Message"}
      status={""}
      dateAndTime={moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")}
      icon={item.details.type === "ping" ? <PingingsIcon /> : <ChatIcon />}
      content={
        <Text textStyle="h6" fontWeight="400" maxW="23.5rem" noOfLines={2}>
          {`From:`}{" "}
          <span style={{ fontWeight: "bold" }}>
            {item?.details?.message_source === "sarah_ai"
              ? "Sarah.ai"
              : item?.details?.direction === "received"
              ? item?.contact_name
              : item?.details?.user_name
              ? item?.details?.user_name
              : item?.details?.from}
          </span>
          <Text
            fontSize="0.83rem"
            color="var(--grey-900)"
            fontWeight="500"
            style={{ lineBreak: "anywhere" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getPingMessage(item.details.content)),
            }}
          />
          <Box mt="0.5rem" display="flex" flexWrap="wrap" width="100%">
            {filterLinks(item.details.media_urls || [])?.map((media: any) => {
              return <img src={media} alt={media} width={100} height={100} />;
            })}

            {/* {filterLinks(item.details.media_urls || [], false)?.map(
              (media: any) => {
                const filename = getFileNameFromURL(media);
                return (
                  <a
                    href={media}
                    download
                    onClick={(event) => event.stopPropagation()}
                  >
                    <TooltipIfOverflow char={70}>{filename}</TooltipIfOverflow>
                  </a>
                );
              }
            )} */}
          </Box>
        </Text>
      }
    />
  );
}

export default SingleMessageCard;