import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  CalendarIcon,
  FilterIcon,
  SearchIcon,
  TimeIcon,
} from "../../../components/icons/svg";
import { loadOutboundLine } from "../../../pages/(dashboard-routes)/bdc-campaign/CreateCampaign/utils";
import ContactListing from "../../../pages/(dashboard-routes)/leads/lead-details/ContactListing";
import { containsGifAndImage, getTransformedFilters } from "../../../utils";
import {
  createBroadcastMessage,
  editBroadcastMessage,
  getBroadcastMessage,
} from "../../../utils/api/broadcast.api";
import { fetchContacts } from "../../../utils/api/contact-details";
import { fetchSettings } from "../../../utils/api/dealership-setting.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AttachmentBar from "../../AttachmentBar";
import Popup from "../../Charts/Popup";
import SearchAndFilterDrawer from "../../Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import { CustomRadio, Drawer, Input, Select } from "../../common";
import AttachmentViewer from "../../common/AttachmentViewer";
import Loader from "../../common/Spinner";
import {
  CreateBulkMessage,
  createBulkMessageValidation,
  defaultValues,
} from "./utils";

const throttlingOptions = [
  { label: "200 per hour", value: "two_hundred" },
  { label: "400 per hour", value: "four_hundred" },
  { label: "600 per hour", value: "six_hundred" },
  { label: "800 per hour", value: "eight_hundred" },
];

const sendOptions = [
  { label: "Send Now", value: "send_now" },
  { label: "Future Schedule", value: "scheduled" },
];

type CreateBulkMessageFormProps = {
  onCloseMsg: () => void;
  isEdit?: boolean;
  id?: string;
};

const hourSendOptions = [
  { label: "Default Business Hours", value: "dealership" },
  { label: "Custom Campaign Hours", value: "custom" },
];

const CreateBulkMessageForm = ({
  onCloseMsg,
  isEdit,
  id,
}: CreateBulkMessageFormProps) => {
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [settingHours, setSettingHours] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });

  const isImpersonating = !!useUserInfo("user")?.previousUserId;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const user = useUserInfo("user");
  const toast = useToast();

  const { dealership } = user || {};

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    clearErrors,
  } = useForm<CreateBulkMessage>({
    defaultValues: defaultValues,
    resolver: yupResolver(createBulkMessageValidation(settingHours)) as any,
  });

  const filters = useWatch({ control, name: "filters_and_sort" });
  const filterApplied = useWatch({ control, name: "filterApplied" });
  const scheduleType = useWatch({ control, name: "broadcast_type" });
  const workingHour = useWatch({ control, name: "working_hour" });

  // const { devices } = useTwilioState();
  // const lineDevices = filterByUniqueKey(
  //   devices.filter((device) => device.phoneNumber),
  //   "phoneNumber"
  // );
  // useEffect(() => {
  //   const defaultLine =
  //     lineDevices?.find((line) => line.is_default)?.phoneNumber ||
  //     lineDevices?.[0]?.phoneNumber;
  //   setValue("from", defaultLine);
  // }, [lineDevices, setValue]);

  useEffect(() => {
    const fetchDefaultOption = async () => {
      const options = await loadOutboundLine("", dealership?.id);
      const defaultOption = options.find(
        (option: any) => option.default === true
      );
      if (defaultOption && !id) {
        setValue("from", defaultOption);
      }
    };

    fetchDefaultOption();
  }, [id, setValue]);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (isEdit
          ? editBroadcastMessage
          : createBroadcastMessage)({
          payload,
          dealership_id: dealership?.id,
          id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Broadcast messages initiated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onCloseMsg();
    },
    onError: (error: any) => {
      toast({
        description: `Error initiating broadcast messages: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data } = useQuery({
    queryKey: ["fetchContacts", page, filters, searchStr],
    queryFn: () => {
      const params: any = {};

      if (!searchStr) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchStr;
      }

      if (filters) {
        const tempFilters = getTransformedFilters(filters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        params.filter_and_sort = tempFilters;
      }
      return fetchContacts(dealership?.id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: filterApplied,
    onSuccess(data) {
      setValue("reduce_count", data?.data?.contacts_count);
      setValue("contacts_count", data?.data?.contacts_count);
      setValue("is_edit", isEdit);
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
    },
  });

  useQuery({
    queryKey: ["fetchBulkMessage", id],
    queryFn: () =>
      getBroadcastMessage({ dealership_id: user?.dealership?.id, id }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setRemovedContacts(data?.data?.message?.removed_users)
      setValue("name", data?.data?.message?.name);
      setValue("broadcast_date", data?.data?.message?.broadcast_date);
      setValue("broadcast_time", data?.data?.message?.broadcast_time);
      setValue("throttling_rate", data?.data?.message?.throttling_rate);
      setValue("content", data?.data?.message?.content);
      setValue(
        "filters_and_sort",
        JSON.parse(data?.data?.message?.filter_string)
      );
      setValue("filterApplied", true);
      setValue("broadcast_type", data?.data?.message?.broadcast_type);
      setValue("working_hour_type", data?.data?.message?.working_hour_type);
      setValue("working_hour", data?.data?.message?.working_hour);
      setTotalCount(data?.data?.message?.total_sms);
      setAttachmentUrls(data?.data?.message?.media_urls || []);

      if (data?.data?.line?.id) {
        setValue("from", {
          value: data?.data?.line?.id,
          label: data?.data?.line?.phone_number,
        });
      }
    },
  });

  useQuery({
    queryKey: ["fetchGeneralSettings", dealership?.id],
    queryFn: () => fetchSettings(dealership?.id, "general_settings"),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setSettingHours(data?.settings_hash?.business_hours || {});
    },
  });

  const confirmationSubmission = (values: CreateBulkMessage) => {
    if (
      !filterApplied ||
      !data?.data?.contacts_count ||
      data?.data?.contacts_count === removedContacts.length
    ) {
      return toast({
        description: "Please apply filters to fetch contacts",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    setPopup({
      name: "Send Broadcast Message",
      description: `Please confirm that you have received explicit consent from all contacts to send this text message. By proceeding, you verify that each recipient has agreed to receive communications from ${dealership?.name}.`,
      value: "item?.id",
    });

    if (containsGifAndImage(attachmentUrls)) {
      toast({
        description: `Adding both an image and a GIF to a single message is not supported.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      onOpenPopupOpen();
    }
  };

  const handleBulkSendMessage = (values: CreateBulkMessage) => {
    if (values.broadcast_type === "send_now") {
      delete (values as any).schedule_time;
      delete (values as any).schedule_date;
    }
    mutation.mutate({
      message_campaign: {
        name: values.name,
        content: values.content,
        dealership_id: user?.dealership?.id,
        line_id: values.from?.label,
        broadcast_type: values.broadcast_type,
        throttling_rate: values.throttling_rate,
        broadcast_date: values.broadcast_date,
        broadcast_time: moment(
          values.broadcast_date + " " + values.broadcast_time
        ).format(),
        media_urls: attachmentUrls.map((attachment: any) => attachment.url),
        filter_string: JSON.stringify(values.filters_and_sort),
        working_hour_type: values.working_hour_type,
        working_hour: values.working_hour,
        reduce_count: isEdit ? values.reduce_count : null,
      },
      filter_and_sort: getTransformedFilters(values.filters_and_sort),
      removed_contacts: removedContacts,
    });
  };

  const handleFormReset = () => {
    reset();
    setAttachmentUrls([]);
    setRemovedContacts([]);
  };

  const transformedThrottlingOptions = useMemo(() => {
    if (isImpersonating) {
      return [...throttlingOptions, { label: "Or Max", value: "all_time" }];
    }

    return throttlingOptions;
  }, [isImpersonating]);

  return (
    <Box w="100%">
      {mutation.isLoading && <Loader />}
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Type name here"
                  label="Broadcast Name"
                  hasIcon={false}
                  type="text"
                  maxW="lg"
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
            name="name"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
        <Box mb="1rem">
          <Button
            variant="outlineBlue"
            leftIcon={<FilterIcon />}
            rightIcon={<ChevronRightIcon />}
            justifyContent="space-between"
            color="var(--grey-900)"
            width="100%"
            size="xl"
            onClick={onOpen}
          >
            <Box as="span" mr="auto">
              Filter & Sort
            </Box>
          </Button>
        </Box>

        {filterApplied && (
          <Box mb="1rem">
            <Button
              variant="outlineBlue"
              rightIcon={<ChevronRightIcon />}
              justifyContent="space-between"
              color="var(--grey-900)"
              width="100%"
              size="xl"
              onClick={onContactsOpen}
            >
              <Box as="span" mr="auto">
                {(data?.data?.contacts_count || 0) - removedContacts.length}{" "}
                Contacts Selected
              </Box>
            </Button>
          </Box>
        )}

        <Box mb="1rem">
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <CustomRadio
                  options={sendOptions}
                  value={value}
                  setValue={(value: any) => setValue("broadcast_type", value)}
                />
              );
            }}
            name="broadcast_type"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
        {scheduleType === "scheduled" && (
          <Box mb="1rem" display="flex" alignItems="" gap="1rem">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Select Date"
                    onChange={onChange}
                    type="date"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<CalendarIcon />}
                    maxW="lg"
                    value={value}
                    isRequired={true}
                    error={error?.message}
                  />
                );
              }}
              name="broadcast_date"
              control={control}
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    onChange={onChange}
                    label="Select Time"
                    hasIcon={true}
                    value={value}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    type="time"
                    maxW="lg"
                    isRequired
                    error={error?.message}
                  />
                );
              }}
              name="broadcast_time"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        )}
        <Box mb="1rem" position="relative" zIndex={5}>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <AsyncSelect
                    loadOptions={(searchStr) =>
                      loadOutboundLine(searchStr, dealership?.id)
                    }
                    defaultOptions
                    value={value}
                    onChange={(val) => onChange(val)}
                    placeholder="Select line"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100%",
                        marginBottom: "0.5rem",
                      }),
                    }}
                  />
                </>
              );
            }}
            name="from"
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
        {isImpersonating && (
          <Box mb="1rem">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    label="Throttling"
                    onChange={(value: any) => {
                      setValue("throttling_rate", value.value);
                      clearErrors("throttling_rate");
                    }}
                    placeholder="Select Throttling"
                    options={transformedThrottlingOptions}
                    variant="default"
                    w="100%"
                    value={value}
                    isRequired
                    error={error?.message}
                  />
                );
              }}
              name="throttling_rate"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        )}

        {isEdit && (
          <Box mb="1rem">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Enter Number"
                    label="Reduce Count"
                    hasIcon={false}
                    type="number"
                    maxW="lg"
                    isRequired
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    min={0}
                    max={totalCount}
                  />
                );
              }}
              name="reduce_count"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        )}

        <Box mb="1rem">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Type here"
                  label=""
                  hasIcon={false}
                  type="text"
                  maxW="lg"
                  w="100%"
                  isTextarea={true}
                  onChange={onChange}
                  rows={10}
                  value={value}
                  showCharCount
                  isTranslatable
                  isRequired
                  error={error?.message}
                />
              );
            }}
            name="content"
            control={control}
            rules={{
              required: true,
            }}
          />

          <AttachmentBar
            gap="0.5rem"
            width="3rem"
            flexWrap="wrap"
            setBody={(text, concatText, type = "text") => {
              if (type === "text") {
                const messagesText = `${getValues("content")} ${
                  concatText ? `${concatText}: ` : ""
                }${text}`;
                setValue("content", messagesText);
              } else {
                setAttachmentUrls((prev) => [...prev, text]);
              }
            }}
            handleTemplate={(value: string, body, attachmentUrls) => {
              setValue("content", value);
              setAttachmentUrls(attachmentUrls);
            }}
          />
          <Box className="attachmentViewerWrapper">
            <AttachmentViewer
              attachments={attachmentUrls}
              setAttachments={setAttachmentUrls}
            />
          </Box>
        </Box>

        <Box
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="var(--grey-50)"
        >
          <Text
            textStyle="h4"
            fontWeight="500"
            background="var(--grey-200)"
            padding="0.92rem"
            border="1px solid var(--grey-300)"
          >
            Broadcast Message Hours
          </Text>
          {/* <HStack gap="1rem" alignItems="flex-start" w="100%"> */}
          <Box padding="1.3rem">
            <Box mb="1rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <CustomRadio
                      options={hourSendOptions}
                      value={value}
                      setValue={onChange}
                    />
                  );
                }}
                name="working_hour_type"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            {watch("working_hour_type") === "custom" &&
              workingHour &&
              Object.keys(workingHour)?.map((item, index) => (
                <Stack
                  mb="1rem"
                  direction="row"
                  alignItems="center"
                  key={index}
                >
                  <VStack mt="18px" minW="104px" alignItems="flex-start">
                    <Controller
                      name={`working_hour.${item}.day_status`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          size="lg"
                          variant="whiteCheck"
                          onChange={onChange}
                          isChecked={value}
                        >
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </Checkbox>
                      )}
                    />
                  </VStack>

                  <Controller
                    name={`working_hour.${item}.start_time`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={onChange}
                        label="Start Time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        type="time"
                        maxW="lg"
                        value={value}
                        error={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`working_hour.${item}.end_time`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={onChange}
                        label="End Time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        type="time"
                        maxW="lg"
                        value={value}
                        error={error?.message}
                      />
                    )}
                  />
                </Stack>
              ))}
          </Box>
          {/* </HStack> */}
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button
            variant="outline"
            w="100%"
            size="xl"
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={() => {
              handleSubmit((values) => confirmationSubmission(values))();
            }}
          >
            Send Broadcast Message
          </Button>
        </DrawerFooter>
      </Box>

      {isOpen && (
        <SearchAndFilterDrawer
          isOpen={isOpen}
          onClose={onClose}
          setFilters={(searchAndFilters) => {
            setValue("filters_and_sort", searchAndFilters);
            setValue("filterApplied", true);
            setRemovedContacts([]);
            setContacts([]);
          }}
          filters={filters}
          pageReset={() => {
            setValue(
              "filters_and_sort",
              defaultFilterValues["filter_and_sort"]
            );
            setValue("filterApplied", false);
            setRemovedContacts([]);
            setContacts([]);
          }}
        />
      )}

      <Drawer
        isOpen={isContactsOpen}
        onClose={onContactsClose}
        title="Contacts"
      >
        <Box padding="0 20px" marginTop="10px">
          <Input
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Contacts..."
            onChange={({ target: { value } }) => {
              setSearchStr(value);
              setPage(1);
              setContacts([]);
            }}
            width="100%"
            value={searchStr}
          />
        </Box>
        <div
          id="bulkMessageDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={data?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="bulkMessageDiv"
          />
        </div>
      </Drawer>
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={() => {
            handleSubmit((values) => handleBulkSendMessage(values))();
          }}
          title={popup.name}
          message={popup.description}
          loading={mutation?.isLoading}
          confirmationText={"Confirm and Send"}
        />
      )}
    </Box>
  );
};

export default CreateBulkMessageForm;
