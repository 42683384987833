import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Input, PaginatedTable } from "../../../../components/common";
import { SearchIcon } from "../../../../components/icons/svg";
import { getRings } from "../../../../utils/api/line-rings.api";
export const RingsListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [query, setQuery] = useState("");
  const [ringID, setRingId] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["Ringapi", id, page, perPage, query],
    queryFn: getRings,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <SettingsIcon
            style={{
              width: "1.25rem",
              height: "1.25rem",
              color: "var(--grey-800)",
            }}
          />
          Settings
        </Box>
      ),
      value: "settings",
    },
  ];

  const handleSelect = (option: SelectOptionProps, ringID: string) => {
    if (option.value === "settings") {
      navigate(
        `/dealerships/lines-ring-groups/create-ring-group/${id}/${ringID}`
      );
    }
  };

  const ringGroupsHeader = [
    { id: 2, value: "Name", label: "name" },
    { id: 3, value: "Connected Lines", label: "connectedLines" },
    { id: 4, value: "Users", label: "users" },
    { id: 5, value: "Total Users", label: "totalUsers" },
    { id: 6, value: "Actions", label: "actions" },
  ];

  const ringGroupsData = data?.data?.ring_groups?.map((ring: any) => ({
    id: ring?.id,
    name: ring?.name,
    connectedLines: (
      <>
        {ring?.lines?.map((line: any) => (
          <HStack>
            <Box
              as="span"
              w="0.5rem"
              h="0.5rem"
              borderRadius="50%"
              background="var(--secondary-600)"
            ></Box>
            <Text>{line?.name}</Text>{" "}
          </HStack>
        ))}
      </>
    ),
    users: (
      <VStack alignItems="flex-start">
        <Text>Ext-New</Text>
        <Text>Dealership Number</Text>
      </VStack>
    ),

    totalUsers: (
      <VStack alignItems="flex-start">
        <Text> {ring?.total_users}</Text>
      </VStack>
    ),
    actions: (
      <Dropdown
        options={options}
        onSelect={(option) => handleSelect(option, ring?.id || "")}
      />
    ),
  }));

  return (
    <>
      {" "}
      <HStack justifyContent="space-between" padding="1rem">
        <Text textStyle="caption" color="var(--grey-600)">
          Ring Group Found: {data?.data?.line_count ?? ""}
        </Text>
        <HStack>
          <Input
            maxW="18rem"
            minW="18rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Type here to search"
            onChange={(e) => {setQuery(e.target.value); setPage(1);}}
          />

          <Button
            onClick={() =>
              navigate(`/dealerships/lines-ring-groups/create-ring-group/${id}`)
            }
          >
            Create Ring Group
          </Button>
        </HStack>
      </HStack>
      <PaginatedTable
        header={ringGroupsHeader}
        data={ringGroupsData}
        isLoadingData={isLoading}
        itemsPerPage={perPage}
        currentPage={page}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        rowOnClick={(rowItem) => {
          setRingId(rowItem?.id);
        }}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
      />
    </>
  );
};
