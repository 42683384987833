import {
  Text,
  Button,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { submitOtp, resendOtp } from "../../../utils/api/login.api";
import { useMutation } from "@tanstack/react-query";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { Spinner } from "../../../components/common";
import { useToast } from "@chakra-ui/react";
import { CustomRadio } from "../../../components/common";

const SelectTwoFa = (props: any) => {
  const dispatch = useAuthDispatch();
  const [selectedMethod, setSelectedMethod] = useState("smd");
  const mutation = useMutation(submitOtp);
  const resendMutation = useMutation(resendOtp);
  const toast = useToast();


  const sendOptions = [
    { label: "Phone", value: "sms" },
    { label: "Email", value: "email" },
  ];
  const handleResend = () => {
    resendMutation.mutate(
      {
        user: {
          email: props?.email,
        },
        delivery_method: selectedMethod

      },
      {
        onSuccess: () => {
          toast({
            description:
              `An OTP has been sent to your registered ${selectedMethod}. Please check your inbox and enter the OTP to proceed.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          dispatch({ type: '2FA', payload: selectedMethod, });
        },
        onError: (error: any) => {
          toast({
            description: error?.response?.data?.error ?? "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };


  return (
    <>
      <VStack
        w="31.25rem"
        borderRadius={8}
        bg="white"
        align="flex-start"
        alignSelf="center"
        spacing={4}
        m="0 auto"
        p={6}
        boxShadow="md"
      >
        <Text textStyle="h2">Select OTP Delivery Method</Text>
        <Text textStyle="h4" color="grey.700">
          Choose where you’d like to receive your OTP:
        </Text>

        {/* Options */}
        <VStack align="flex-start" spacing={3} w="full">
        <CustomRadio
            options={sendOptions}
            value={selectedMethod}
            setValue={(value: string) => setSelectedMethod(value)}
          />
        </VStack>

        {/* Submit Button */}
        <Button
          size="xl"
          w="full"
          mt={4}
          onClick={handleResend}
          isDisabled={!selectedMethod}
        >
          Confirm
        </Button>

        {/* Loading Spinner */}
        {(mutation.isLoading || resendMutation.isLoading) && <Spinner />}
      </VStack>
    </>
  );
};

export default SelectTwoFa;
