export const days: SelectOptionProps[] = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];

export const daysStructure=[
  { label: "Monday", checked: false, startTime: "", endTime: "" },
  { label: "Tuesday", checked: false, startTime: "", endTime: "" },
  { label: "Wednesday", checked: false, startTime: "", endTime: "" },
  { label: "Thursday", checked: false, startTime: "", endTime: "" },
  { label: "Friday", checked: false, startTime: "", endTime: "" },
  { label: "Saturday", checked: false, startTime: "", endTime: "" },
  { label: "Sunday", checked: false, startTime: "", endTime: "" },
]