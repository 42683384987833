import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchDealershipEmail } from "../../../../../../utils/api/contact-details";
import { fetchEmailById } from "../../../../../../utils/api/history.api";
import { fetchUserById } from "../../../../../../utils/api/users.api";
import { titleMapping } from "../../../../../../utils/helper";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import { Spinner } from "../../../../../common";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";
import EmailDrawer from "../../../../../Drawers/EmailDrawer";
import { throttlingOptions } from "../../../../../EmailBlast/ComposeBlast/ComposeBlast";
import {
  PrinterIcon,
  SimpleDateIcon,
  ViewedEmailIcon,
} from "../../../../../icons/svg";

type EmailDetailProps = {
  clickedRow?: any;
  clickedRecord: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
  parentMenu?: string;
};

function EmailDetail({
  setClickedRecord,
  clickedRecord,
  parentMenu = "contactDetail",
  clickedRow,
}: EmailDetailProps) {
  const navigate = useNavigate();
  const [isEmailCenterOpen, setIsEmailCenterOpen] = useState<boolean>(false);
  const dealership = useUserInfo("dealership");
  const [isLoadingDoc, setIsLoadingDoc] = useState<Boolean>(false);
  const { data, isLoading } = useQuery({
    queryKey: [
      "Email",
      dealership?.id,
      clickedRecord?.details?.id || clickedRecord?.id,
    ],
    queryFn: fetchEmailById,
    retry: false,
  });
  const printRef = useRef<any>(null);

  const handlePrint = async () => {
    const printableContent = printRef.current;
    if (!printableContent) return;

    setIsLoadingDoc(true);

    try {
      const canvas = await html2canvas(printableContent, {
        scrollY: -window.scrollY,
        useCORS: true,
      });

      let imgData = canvas.toDataURL("image/png", 1.0);
      let imgWidth = 200;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let pdf = new jsPDF("p", "mm");
      let y = 10;
      let x = 5.5;

      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        y = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Email Detail.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoadingDoc(false);
    }
  };

  //................for reply button and opening the inbound email drawer...........
  const user = useUserInfo("user");

  const { isLoading: isLoadingEmail, data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail"],
    queryFn: () => fetchDealershipEmail(dealership?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};
  const { data: userObj } = useQuery({
    queryKey: ["userById"],
    queryFn: () => fetchUserById(user?.id),
  });
  const isEmailEnabled =
    !!userEmail &&
    userObj?.permissions?.some(
      (permission: any) =>
        permission?.module_key === "emails" && permission?.allowed
    );

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      background="var(--grey-50)"
      padding="1rem 1.3rem"
      height="calc(100vh - 200px)"
      overflow="auto"
    >
      <VStack
        w="100%"
        alignItems="flex-start"
        // borderTop="1px solid var(--grey-300)"
      >
        <HStack w="100%" justifyContent="space-between" padding="1rem 0">
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => setClickedRecord(null)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
            Back
          </Button>
          <HStack>
            <Button
              background="var(--secondary-600)"
              onClick={() => {
                if (parentMenu === "inbox") {
                  navigate("/email-blast/compose", {
                    state: {
                      from: data?.data?.from_address,
                      subject: data?.data?.subject,
                    },
                  });
                } else {
                  setIsEmailCenterOpen(true);
                }
              }}
              isDisabled={
                parentMenu === "contactDetail" &&
                (!isEmailEnabled || !clickedRow?.email)
              }
            >
              Reply
            </Button>

            <Button
              variant="outline"
              sx={{
                svg: {
                  width: "1.25rem",
                  height: "1.25rem",
                  fill: {
                    path: "var(--grey-900)",
                  },
                },
              }}
              padding="0"
              onClick={handlePrint}
            >
              <PrinterIcon />
            </Button>
          </HStack>
        </HStack>

        <VStack
          w="100%"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          gap="0"
          ref={printRef}
        >
          <>
            <HStack
              alignItems="flex-start"
              justifyContent="space-between"
              background="var(--grey-200)"
              padding="1rem"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              <HStack alignItems="center" gap="0.69rem" w="100%">
                <Box
                  sx={{
                    svg: {
                      width: "1.25rem",
                      height: "1.25rem",
                    },
                  }}
                >
                  <ViewedEmailIcon />
                </Box>
                <Text textStyle="h4" fontWeight="600">
                  {(titleMapping as any)[data?.data?.internal_action]}
                </Text>
                {data?.data?.internal_action?.includes("outbound") && (
                  <Box
                    padding="0.65rem 1rem"
                    border={`2px solid var(--${
                      !data?.data?.is_open ? "red" : "secondary"
                    }-600)`}
                    height="1.25rem"
                    // minH="2rem"
                    borderRadius="12px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      textStyle="h4"
                      fontWeight="600"
                      color={`var(--${
                        !data?.data?.is_open ? "red" : "secondary"
                      }-600)`}
                    >
                      {data?.data?.is_open ? "Read" : "Unread"}
                    </Text>
                  </Box>
                )}
              </HStack>

              <HStack gap="0.5rem" w="100%" justifyContent="flex-end">
                <SimpleDateIcon />
                {data?.data?.created_at && (
                  <Text textStyle="h6" fontWeight="600">
                    {moment(data?.data?.created_at?.replace("Z", ""))?.format(
                      "dddd MMM DD YY • h:mm a"
                    )}
                  </Text>
                )}
              </HStack>
            </HStack>
            <VStack
              w="100%"
              alignItems="flex-start"
              gap="0"
              background="white"
              borderBottomRightRadius="0.5rem"
              borderBottomLeftRadius="0.5rem"
            >
              <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="7.5rem">
                  <Text textStyle="h6" fontWeight="500">
                    From:
                  </Text>
                </Box>
                <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                  <Text textStyle="h6" fontWeight="500">
                    {data?.data?.from_address}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="7.5rem">
                  <Text textStyle="h6" fontWeight="500">
                    To:
                  </Text>
                </Box>
                <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                  <Text textStyle="h6" fontWeight="500">
                    <TooltipIfOverflow>
                      {data?.data?.to_address}
                    </TooltipIfOverflow>
                  </Text>
                </Box>
              </HStack>
              {data?.data?.internal_action === "campaign_outbound" ? (
                <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                  <Box padding="0.75rem" w="7.5rem">
                    <Text textStyle="h6" fontWeight="500">
                      Throttling:
                    </Text>
                  </Box>
                  <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                    <Text textStyle="h6" fontWeight="500">
                      <TooltipIfOverflow>
                        {
                          throttlingOptions.find(
                            (value) =>
                              value.value === data?.data?.throttling_rate
                          )?.label
                        }
                      </TooltipIfOverflow>
                    </Text>
                  </Box>
                </HStack>
              ) : (
                <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                  <Box padding="0.75rem" w="7.5rem">
                    <Text textStyle="h6" fontWeight="500">
                      CC:
                    </Text>
                  </Box>
                  <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                    <Text textStyle="h6" fontWeight="500">
                      <TooltipIfOverflow>
                        {data?.data?.cc_address}
                      </TooltipIfOverflow>
                    </Text>
                  </Box>
                </HStack>
              )}

              <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="7.5rem">
                  <Text textStyle="h6" fontWeight="500">
                    Subject:
                  </Text>
                </Box>
                <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                  <Text textStyle="h6" fontWeight="500">
                    {data?.data?.subject}
                  </Text>
                </Box>
              </HStack>

              <VStack
                w="100%"
                alignItems="flex-start"
                padding="1rem"
                maxW="77vw"
                h="calc(100vh - 530px)"
                overflowX="auto"
                className="emailBodyContent"
              >
                <Text
                  maxW="100%"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data?.data?.body),
                  }}
                />
              </VStack>

              {data?.data?.attachment_urls?.length && (
                <>
                  <Text textStyle="h4" fontWeight="500" mt={10}>
                    Attachments:
                  </Text>
                  <VStack w="100%" alignItems="flex-start" gap="0">
                    {data?.data?.attachment_urls?.map((url: any) => (
                      <a
                        href={url.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TooltipIfOverflow char={70}>
                          {url.filename}
                        </TooltipIfOverflow>
                      </a>
                    ))}
                  </VStack>
                </>
              )}
            </VStack>
          </>
        </VStack>
      </VStack>
      {isEmailCenterOpen && (
        <EmailDrawer
          isOpen={isEmailCenterOpen}
          onClose={() => setIsEmailCenterOpen(false)}
          clickedRow={clickedRow}
          userEmail={userEmail}
        />
      )}
      {(isLoading || isLoadingDoc) && <Spinner />}
    </VStack>
  );
}

export default EmailDetail;
