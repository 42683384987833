import { Input, Select } from "../../../../components/common";
import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { fetchTimezones } from "../../../../utils/api/users.api";
import { createRef, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import SwitchToggle from "../../../common/Switch";
import Loader from "../../../common/Spinner";
import {
  editDealershipData,
  getsettingsApi,
  updatesettingApi,
} from "../../../../utils/api/dealership-setting.api";
import { useParams } from "react-router-dom";
import { defaultValues, GeneralForm, generalFormValidation } from "./utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimeIcon } from "../../../icons/svg";
import { getTCPA } from "../../../../utils/api/line-rings.api";
import { defaultOperatingHours } from "../../../../utils";
import { DayComponent } from "../../../LineSettings/RoutingAndIVR/DayComponent";
import { sortWeekdays } from "../../../GlobalDashboard/UserUtils";

type Weekday =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

const General = ({ detail, refetch: parentFetch }: any) => {
  const toast = useToast();
  const { id } = useParams();
  const mainDivRef = createRef<HTMLDivElement>();

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<GeneralForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(generalFormValidation) as any,
  });

  useQuery({
    queryKey: ["timezones"],
    queryFn: () => fetchTimezones(),
    onSuccess(timezones) {
      setTimezoneOptions(
        timezones?.map(({ id, name }: any) => ({ label: id, value: name })) ||
          []
      );
    },
  });

  const { data } = useQuery({
    queryKey: ["settingsdata", id, "general_settings"],
    queryFn: getsettingsApi,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (detail) {
      setValue("name", detail?.name || "");
      setValue("website", detail?.website || "");
      setValue("address", detail?.address || "");
      setValue("time_zone", detail?.time_zone || "");
      setValue("credit_app_link", detail?.credit_app_link || "");
    }
  }, [detail, setValue]);

  useEffect(() => {
    if (data) {
      setValue(
        "business_hours.monday",
        data?.data?.settings_hash?.business_hours?.monday
      );
      setValue(
        "business_hours.tuesday",
        data?.data?.settings_hash?.business_hours?.tuesday
      );
      setValue(
        "business_hours.wednesday",
        data?.data?.settings_hash?.business_hours?.wednesday
      );
      setValue(
        "business_hours.thursday",
        data?.data?.settings_hash?.business_hours?.thursday
      );
      setValue(
        "business_hours.friday",
        data?.data?.settings_hash?.business_hours?.friday
      );
      setValue(
        "business_hours.saturday",
        data?.data?.settings_hash?.business_hours?.saturday
      );
      setValue(
        "business_hours.sunday",
        data?.data?.settings_hash?.business_hours?.sunday
      );
      setOperatingHours(
        data?.data?.settings_hash?.business_hours?.monday
          ? data?.data?.settings_hash?.business_hours
          : defaultOperatingHours
      );
      setValue("mms", data?.data?.settings_hash?.mms ?? false);
      setValue("bhph", data?.data?.settings_hash?.bhph ?? false);
      setValue("virtual_dealership", data?.data?.settings_hash?.virtual_dealership ?? false);

      setValue(
        "business_hours.tcpa_disposition",
        data?.data?.settings_hash?.business_hours?.tcpa_disposition
      );
      setValue(
        "business_hours.thirty_days_opt_in",
        data?.data?.settings_hash?.business_hours?.thirty_days_opt_in
      );
      setValue(
        "business_hours.initial_sms_opt_in",
        data?.data?.settings_hash?.business_hours?.initial_sms_opt_in
      );
      setValue(
        "business_hours.thirty_days_opt_message",
        data?.data?.settings_hash?.business_hours?.thirty_days_opt_message
      );
      setValue(
        "business_hours.initial_sms_opt_message",
        data?.data?.settings_hash?.business_hours?.initial_sms_opt_message
      );
    }
  }, [data, setValue]);

  const mutation = useMutation(editDealershipData);
  const settingMutation = useMutation(updatesettingApi);
  const [timezoneOptions, setTimezoneOptions] = useState<SelectOptionProps[]>(
    []
  );
  const [operatingHours, setOperatingHours] = useState<any>(
    defaultOperatingHours
  );
  const [copiedTime, setCopiedTime] = useState({
    start_time: null,
    end_time: null,
  });

  const handleToggle = (day: any, status: any) => {
    setOperatingHours((prev: any) => {
      const updatedHours = {
        ...prev,
        [day]: { ...prev[day], day_status: status },
      };

      // Merge updated business_hours while preserving other fields
      const currentFormValues = getValues();
      setValue("business_hours", {
        ...currentFormValues.business_hours,
        ...updatedHours,
      });

      return updatedHours;
    });
  };
  const handleCopyTime = (day: any) => {
    setCopiedTime(operatingHours[day]);
  };
  const handlePasteTime = (day: any) => {
    if (copiedTime.start_time && copiedTime.end_time) {
      const updatedHours = {
        ...operatingHours,
        [day]: { ...operatingHours[day], ...copiedTime },
      };
      setOperatingHours(updatedHours);
      setValue("business_hours", {
        ...getValues().business_hours,
        ...updatedHours,
      });
    }
  };
  const handleSelectTime = (day: any, timeType: any, value: any) => {
    setOperatingHours((prev: any) => {
      const updatedHours = {
        ...prev,
        [day]: { ...prev[day], [timeType]: value },
      };

      // Merge updated business_hours while preserving other fields
      const currentFormValues = getValues();
      setValue("business_hours", {
        ...currentFormValues.business_hours,
        ...updatedHours,
      });

      return updatedHours;
    });
  };
  console.log(errors);

  const handleFormSubmit = async (values: GeneralForm) => {
    const { business_hours, mms,virtual_dealership,bhph, ...restValues } = values;
    try {
      // Use Promise.all to execute both mutations concurrently
      await Promise.all([
        mutation.mutateAsync({
          data: {
            dealership: restValues,
          },
          id: id,
          sub_menu: "Dealership settings/Dealer Profile/General",
        }),
        settingMutation.mutateAsync({
          data: {
            key: "general_settings",
            settings_hash: {
              business_hours,
              ...restValues,
              mms: mms,
              bhph:bhph,
              virtual_dealership:virtual_dealership
            },
          },
          id: id,
          sub_menu: "Dealership settings/Dealer Profile/General",
        }),
      ]);

      // Handle the responses as needed
      toast({
        description: "Settings updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        onCloseComplete() {
          parentFetch();
        },
      });

      // Additional logic after both mutations succeed
    } catch (error) {
      // Handle errors if any mutation fails
      toast({
        description: "Something went wrong ",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const { data: Tcpa } = useQuery({
    queryKey: ["tcpa", id],
    queryFn: getTCPA,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <>
      <VStack background="white" gap="1.5rem">
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          ref={mainDivRef}
        >
          <Text fontWeight="700" textStyle="h4">
            Company Details
          </Text>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
          <HStack w="100%" gap="1.25rem" alignItems="flex-start">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SwitchToggle
                    id="1"
                    onChange={(check: boolean) => setValue("virtual_dealership", check)}
                    label="Virtual Dealership"
                    defaultChecked={value}
                  />
                );
              }}
              name="virtual_dealership"
              control={control}
              rules={{
                required: true,
              }}
            />{" "}
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SwitchToggle
                    id="1"
                    onChange={(check: boolean) => setValue("bhph", check)}
                    label="BHPH"
                    defaultChecked={value}
                  />
                );
              }}
              name="bhph"
              control={control}
              rules={{
                required: true,
              }}
            />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="100%"
                      label="Company Name"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="100%"
                      label="Website"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="website"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="50%"
                      label="Address"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
              />

              {/* <Input
                maxW="50%"
                label="Email"
                placeholder="Email"
                value={detail?.email}
                isDisabled
              /> */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="50%"
                      label="Credit App Link"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                name="credit_app_link"
                control={control}
                // rules={{
                //   required: true,
                // }}
              />
            </HStack>
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Timezone
          </Text>
          <Text textStyle="h6" fontWeight="600" mb="0.25rem">
            Account's main timezone. Sequences, business hours, and some more
            controls follow this timezone. This is also the base timezone for
            your reports.
          </Text>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label=""
                  onChange={(value: any) => setValue("time_zone", value.value)}
                  placeholder="Select Option"
                  options={timezoneOptions}
                  variant="default"
                  w="100%"
                  value={value}
                  error={error?.message}
                />
              );
            }}
            name="time_zone"
            control={control}
            rules={{
              required: true,
            }}
          />
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Business Hours
          </Text>
          <VStack
            alignItems="flex-start"
            // width="50%"
            gap="0"
            border=" 1px solid var(--grey-300)"
            background="white"
            borderRadius="0.5rem"
            padding="1.3rem"
          >
            <VStack alignItems="flex-start" width="100%" gap="1rem">
              {Object?.entries(sortWeekdays(operatingHours))?.map(
                ([day, data]) => (
                  <DayComponent
                    key={day}
                    day={day}
                    data={data}
                    error={errors?.business_hours?.[day as Weekday]} // Pass specific day's error
                    onToggle={handleToggle}
                    onSelectTime={handleSelectTime}
                    onCopy={() => handleCopyTime(day)}
                    onPaste={() => handlePasteTime(day)}
                  />
                )
              )}
            </VStack>
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Other Details
          </Text>
          <Box
            padding="1rem"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
          >
            <Text textStyle="h4" fontWeight="500" mb="1rem">
              Send long text messages as MMS
            </Text>
            <Text
              textStyle="h6"
              fontWeight="400"
              mb="1.12rem"
              color="var(--grey-800)"
            >
              Messages longer than 160 characters will automatically send as an
              MMS rather than multiple SMS segments. This setting can also be
              made on individual Line Settings.
            </Text>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SwitchToggle
                    id="1"
                    onChange={(check: boolean) => setValue("mms", check)}
                    label="Enable Send long text messages as MMS"
                    defaultChecked={value}
                  />
                );
              }}
              name="mms"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        </VStack>

        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            TCPA: Text Authorization
          </Text>
          <VStack alignItems="flex-start" width="100%" gap="1rem">
            <VStack alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      id="1"
                      label="Initial SMS Opt-In"
                      onChange={(check: boolean) =>
                        setValue("business_hours.initial_sms_opt_in", check)
                      }
                      defaultChecked={value}
                    />
                  );
                }}
                name="business_hours.initial_sms_opt_in"
                control={control}
              />
            </VStack>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    type="text"
                    placeholder="Write here.."
                    onChange={onChange}
                    value={value}
                    isTextarea={true}
                  />
                );
              }}
              name="business_hours.initial_sms_opt_message"
              control={control}
            />

            <VStack alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      id="1"
                      label="30-Day Opt-In"
                      onChange={(check: boolean) =>
                        setValue("business_hours.thirty_days_opt_in", check)
                      }
                      defaultChecked={value}
                    />
                  );
                }}
                name="business_hours.thirty_days_opt_in"
                control={control}
              />
            </VStack>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    type="text"
                    placeholder="Write here.."
                    onChange={onChange}
                    value={value}
                    isTextarea={true}
                  />
                );
              }}
              name="business_hours.thirty_days_opt_message"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    label="TCPA disposition"
                    onChange={(value: any) =>
                      setValue("business_hours.tcpa_disposition", value.value)
                    }
                    options={
                      Tcpa?.data?.contact_dispositions?.map((tcpa: any) => ({
                        label: tcpa.name,
                        value: tcpa.id,
                      })) ?? []
                    }
                    w="100%"
                    value={value}
                    variant="default"
                    isClearable
                  />
                );
              }}
              name="business_hours.tcpa_disposition"
              control={control}
            />
          </VStack>
        </VStack>

        <HStack
          position="fixed"
          bottom="1.5rem"
          w="calc(100% - 585px)"
          right="1.5rem"
          background="white"
          justifyContent="flex-end"
          padding="1rem"
          borderTop="1px solid var(--grey-300)"
        >
          <Button
            width="8rem"
            onClick={async () => {
              const isError = await trigger();
              if (!isError)
                return mainDivRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              handleSubmit((values: GeneralForm) => handleFormSubmit(values))();
            }}
          >
            Save
          </Button>
        </HStack>
        {mutation?.isLoading && <Loader />}
      </VStack>
    </>
  );
};

export default General;
