import { Box, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PaginatedTable, Select } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { fetchLeaderBoardReport } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";

const Leaderboard = () => {
  const dId = useUserInfo("dealership")?.id;
  const [perPage, setPerPage] = useState<any>(20);
  const [page, setPage] = useState(1);
  const [sortBy, setSetSortBy] = useState<string>("sms");
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  const optionSort = [
    {
      value: "sms",
      label: "SMS",
    },
    {
      value: "activityCount",
      label: "Activity Count",
    },
    { value: "sold", label: "Sold" },
    { value: "appt_set", label: "Appointment Set Count" },
    { value: "appt_shown", label: "Appointment Shown Count" },
    {
      value: "soldClosingRatio",
      label: "Sold Closing Ratio",
    },
    {
      value: "showClosingRatio",
      label: "Show Closing Ratio",
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchLeaderBoardReport",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
      sortBy,
    ],
    queryFn: fetchLeaderBoardReport,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: selectionRange?.startDate !== selectionRange?.endDate,
  });

  const tableHeader = [
    { id: 1, value: "", label: "name" },
    { id: 2, value: "Outbound Calls", label: "outboundCalls" },
    { id: 2, value: "Outbound SMS", label: "outboundSMS" },
    { id: 4, value: "Appointment Set", label: "apptSet" },
    { id: 5, value: "Appointment Shown", label: "apptShown" },
    { id: 5, value: "Meetings", label: "meetings" },
    { id: 6, value: "Deals", label: "deals" },
  ];

  const tableData = data?.data?.data?.map((item: any) => ({
    name: item.user,
    outboundCalls: item.outbound_calls,
    outboundSMS: item.outbound_messages,
    apptSet: item.appt_set,
    apptShown: item.appt_shown,
    meetings: item.meetings,
    deals: item.deals,
  }));

  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <HStack>
          <Box>
            <DatePicker
              value={selectionRange}
              onChange={(value) => {setSelectionRange(value); setPage(1);}}
              w="100%"
            />
          </Box>

          <Box position="relative" zIndex="3" width="15rem">
            <Select
              placeholder="Sort By"
              options={optionSort}
              border="1px solid"
              borderRadius="0.5rem"
              variant="default"
              onChange={(value: SelectOptionProps) =>
                setSetSortBy(value?.value)
              }
              value={sortBy}
              w="100%"
            />
          </Box>
        </HStack>
        <ExportReport reportType="leaderboard" />
      </HStack>
      <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
        Found {data?.data?.total_count}
      </Text>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.total_count}
        isLoadingData={isLoading}
      />
    </Box>
  );
};

export default Leaderboard;
