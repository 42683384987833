import {
  Box,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getConversations,
  getConversationsByNext,
  markAsSeen,
} from "../../utils/api/social-talk.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import TooltipIfOverflow from "../common/TooltipIfOverflow";
import MessengerThread from "./MessengerThread";

export default function WebChatComponent() {
  const dealership_id = useUserInfo("dealership")?.id;
  const [conversations, setConversations] = useState<any>([]);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [next, setNext] = useState("");
  const [loadCount, setLoadCount] = useState(0);
  const seenmutation = useMutation(markAsSeen, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.error("Error sending message:", error);
    },
  });
  const { data } = useQuery({
    queryKey: ["conversation", dealership_id],
    queryFn: getConversations,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      if (data?.data?.data?.items && Array.isArray(data.data.data.items)) {
        setConversations((prev: any) => {
          const existingIds = new Set(prev.map((item: any) => item.id));
          const newItems = data?.data?.data?.items?.filter(
            (item: any) => !existingIds.has(item.id)
          );
          return [...prev, ...newItems];
        });
        setSelectedConversation(data?.data?.data?.items[0]);
      }
      if (data?.data?.data?.paging?.next) {
        setNext(encodeURIComponent(data?.data?.data?.paging?.next));
      }
      setLoadCount((prev) => prev + 1);
    },
  });
  const { data: moreData } = useQuery({
    queryKey: ["conversationloadmore", dealership_id, next],
    queryFn: getConversationsByNext,
    refetchOnWindowFocus: false,
    enabled: next !== "" && loadCount < 12,
    retry: false,
    onSuccess(data) {
      if (data?.data?.data?.items && Array.isArray(data.data.data.items)) {
        setConversations((prev: any) => {
          const existingIds = new Set(prev.map((item: any) => item.id));
          const newItems = data?.data?.data?.items?.filter(
            (item: any) => !existingIds.has(item.id)
          );
          return [...prev, ...newItems];
        });
      }
      if (data?.data?.data?.paging?.next) {
        setLoadCount((prev) => prev + 1);
        setNext(encodeURIComponent(data.data.data.paging.next));
      }
    },
  });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef?.current)
      containerRef.current["scrollTop"] = containerRef.current["scrollHeight"];
  }, []);
  const markAsSeenCall = (rec_id: any) => {
    seenmutation.mutate({
      id: dealership_id,
      recipient_id: rec_id,
    });
  };
  const setConversationsItem = (item: any) => {
    setConversations((prev: any) => {
      // Check if the conversation already exists
      const existingConversationIndex = prev.findIndex(
        (conv: any) => conv.conversation_id === item?.conversation_id
      );

      if (existingConversationIndex !== -1) {
        // If the conversation exists, update it and move it to the top
        const updatedConversations = [...prev];
        updatedConversations.splice(existingConversationIndex, 1); // Remove the old conversation
        return [item, ...updatedConversations]; // Add the updated conversation to the top
      } else {
        // If the conversation doesn't exist, add it to the top
        return [item, ...prev];
      }
    });
  };
  const updateCurrentConvo = (item: any) => {
    setConversations((prev: any) => {
      return prev.map((conv: any) => {
        if (conv.conversation_id === item?.conversation_id) {
          return {
            ...conv,
            snippet: item?.messages.items.slice(-1)[0].body, // Update snippet with the last message
            unread_count: item?.unread_count, // Update unread count
          };
        }
        return conv;
      });
    });
  };

  return (
    <Tabs
      variant="vertical"
      border="1px solid var(--grey-300)"
      borderRadius="0.5rem"
      display="flex"
      onChange={(index) => {
        setSelectedConversation(conversations[index]);
        markAsSeenCall(conversations[index]?.id);
      }}
    >
      <TabList
        minW="23rem"
        sx={{
          ".chakra-tabs__tab": {
            padding: "0",
            _selected: {
              background: "var(--primary-50)",
              ".tab-wrapper": {
                borderLeft: "0.24rem solid var(--secondary-600)",
              },
              ".chatter-name": {
                fontWeight: "700",
                color: "var(--grey-900)",
              },
            },
          },
        }}
      >
        <Text padding="1.1rem 1rem" textStyle="h3" fontWeight="600">
          All Chats
        </Text>
        <InfiniteScroll
          dataLength={conversations.length}
          next={() => setLoadCount(0)}
          hasMore={next !== ""}
          loader={""}
          height="calc(100vh - 330px)"
          scrollableTarget="scrollableDiv"
        >
          <VStack
            alignItems="flex-start"
            w="100%"
            gap="0"
            h="calc(100vh - 330px)"
            // overflow="auto"
          >
            {conversations?.map((item: any) => (
              <Tab w="100%" key={item}>
                <HStack w="100%" className="tab-wrapper" padding="1rem">
                  <HStack gap="0.72rem" w="100%">
                    <VStack
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                    >
                      {item?.unread_count > 0 && (
                        <Box
                          w="0.7rem"
                          h="0.7rem"
                          background="var(--secondary-600)"
                          borderRadius="50%"
                          border="1px solid white"
                          position="absolute"
                          right="2px"
                          top="0"
                        ></Box>
                      )}
                      {item?.profile_pic ? (
                        <Image
                          src={item?.profile_pic}
                          alt="avatar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <AccountCircleIcon
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </VStack>
                    <VStack alignItems="flex-start" gap="0.48rem">
                      <Text
                        textStyle="h5"
                        fontWeight="600"
                        className="chatter-name"
                      >
                        {item.first_name} {item?.last_name}
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="500"
                        color="var(--grey-600)"
                      >
                        <TooltipIfOverflow>{item?.snippet}</TooltipIfOverflow>
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="500"
                        color="var(--grey-600)"
                      ></Text>
                    </VStack>
                  </HStack>
                  {item?.unread_count > 0 && (
                    <Text
                      textStyle="h6"
                      fontWeight="600"
                      color="white"
                      background="var(--red-600)"
                      padding="0.24rem 0.36rem"
                      borderRadius="0.6rem"
                    >
                      {item?.unread_count}
                    </Text>
                  )}
                </HStack>
              </Tab>
            ))}
          </VStack>
        </InfiniteScroll>
      </TabList>

      <TabPanels>
        {conversations?.map((item: any, index: number) => (
          <TabPanel w="100%" h="100%" key={item.id}>
            {selectedConversation?.id === item.id && (
              <MessengerThread
                selectedConversation={selectedConversation}
                setConversationsItem={setConversationsItem}
                updateCurrentConvo={updateCurrentConvo}
              />
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
