import {
  Button,
  DrawerFooter,
  Text,
  useToast,
  VStack,
  Link,
} from "@chakra-ui/react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../../components/icons/svg";
import {
  updateMapping,
  previewMapping,
} from "../../../utils/api/inventory-mapping.api";
import { Drawer, Select } from "../../common";
import Loader from "../../common/Spinner";
import { humanize } from "../../../utils";
import { PreviewMapping } from "./PreviewMapping";

interface MappingData {
  [key: string]: string;
}

const InventoryDrawer = ({ isOpen, onClose, dealership }: any) => {
  const toast = useToast();
  const [preview, setPreview] = useState<boolean>(false);
  const mutation = useMutation(updateMapping);
  const [mapping, setMapping] = useState<MappingData>({});
  const [autoAssign, setAutoAssign] = useState(false);

  const { mapping_hash, filename, provider_id, id,headers,all_headers } = dealership || {};
  useEffect(() => {
    setMapping(mapping_hash || {});
  }, [mapping_hash]);
  const queryClient = useQueryClient();
  const handleSubmit = () => {
    mutation.mutate(
      {
        id: id,
        payload: {
          key: "integration_settings",
          settings_hash: {
            inventory_provider_params: {
              id: provider_id,
              mapping_params: {
                status: "approve",
                mapping_hash: mapping,
                feed_provider_id: provider_id,
                dealership_id: id,
                ftp_file_id: filename,
              },
            },
          },
        },
      },
      {
        onSuccess: () => {
          toast({
            description: "Mapping approved successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              onClose();
              queryClient.invalidateQueries(["pendingApi"]);
            },
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleSelectChange = (key: any, value: any) => {
    setMapping({ ...mapping, [key]: !value.value?"":value.value });
    setAutoAssign(false);
  };

  const optionss: SelectOptionProps[] = all_headers?.map((value:any) => ({
    label: humanize(String(value)), // Convert value to string or use your label formatting logic
    value: String(value),
  }));
  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        {/* Invontory mapping Drawer */}
        <Drawer isOpen={isOpen} onClose={onClose} title="Map Fields">
          <VStack padding="1rem 1.5rem" alignItems="flex-start" w="100%">
            <VStack alignItems="flex-start">
              <Text fontSize="1rem" fontWeight="700">
                {dealership?.name}
              </Text>
              <Text fontSize="0.85rem" fontWeight="500">
                {filename}
              </Text>
            </VStack>
            <VStack padding="0.5rem" alignItems="flex-start" w="100%">
              {Object.keys(mapping).map((key) => (
                <Select
                  key={key}
                  label={humanize(key)}
                  onChange={(value) => handleSelectChange(key, value)}
                  value={mapping[key]}
                  options={optionss??[]}
                  variant="default"
                  w="100%"
                  isClearable
                />
              ))}
            </VStack>
          </VStack>
          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            <Button
              variant="outline"
              w="100%"
              size="xl"
              onClick={() => setPreview(true)}
            >
              Preview Mapping
            </Button>
            <Button
              variant="outline"
              w="100%"
              size="xl"
              onClick={() => {
                setAutoAssign(true);
                setMapping((prevMapping:any) => {
                  const keys = Object.keys(prevMapping);
                  const newMapping = {...prevMapping};      
                  keys.forEach((key:any, index:any) => {
                      // Use the index to access csv_headers and assign, but check if index exists in csv_headers
                      newMapping[key] = headers[index] || '';
                  });
      
                  return newMapping;
              });
              }}
            >
              Auto Assign Fields
            </Button>
            <Button w="100%" size="xl" onClick={handleSubmit} isDisabled={Object.values(mapping).every(value => value === '')}>
              Approve
            </Button>
          </DrawerFooter>
          {mutation.isLoading && <Loader />}
          {preview && (
            <PreviewMapping
              isOpen={preview}
              onClose={() => setPreview(false)}
              name={dealership?.name}
              mapping={mapping}
            />
          )}
        </Drawer>
      </VStack>
    </>
  );
};

export default InventoryDrawer;
