import * as yup from "yup";
export type SarahAiForm = {
  Hours_of_Operation: string;
  days: {
    label: string;
    checked: boolean;
    startTime: string;
    endTime: string;
  }[];
};

export const defaultValues = {
    Hours_of_Operation:'twenty_four_hour',
  days: [
    { label: "Monday", checked: false, startTime: "", endTime: "" },
    { label: "Tuesday", checked: false, startTime: "", endTime: "" },
    { label: "Wednesday", checked: false, startTime: "", endTime: "" },
    { label: "Thursday", checked: false, startTime: "", endTime: "" },
    { label: "Friday", checked: false, startTime: "", endTime: "" },
    { label: "Saturday", checked: false, startTime: "", endTime: "" },
    { label: "Sunday", checked: false, startTime: "", endTime: "" },
  ],
};
export const formoptions: SelectOptionProps[] = [
  {
    label: "Sales",
    value: "sales_instructions",
  },
  {
    label: "Service",
    value: "service_instructions",
  },
  {
    label: "Buyback",
    value: "buyback_instructions",
  },
  {
    label: "Webchat",
    value: "webchat_instructions",
  },
];
export const hoursOption = [
  { label: " 24 hours", value: "twenty_four_hour" },
  {
    label: "Follow dealership business hours",
    value: "dealership_basic_hours",
  },
  { label: "Follow these specific hours", value: "specific_hours" },
  {
    label: "Only follow after business hours",
    value: "after_business_hours",
  },
];

