import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useEffect, useState, lazy, Suspense } from "react";
import { useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import { getBDCCampaignByID } from "../../../../utils/api/bdc-campaign.api";
import {
  adminDealershipAPIs,
  globalBDCApis,
} from "../../../../utils/api/bdc-campaign.api";

const Settings = lazy(
  () => import("../../../../components/BDCSettings/Settings/Settings")
);
const Steps = lazy(
  () => import("../../../../components/BDCSettings/Steps/Steps")
);
const Trigger = lazy(
  () => import("../../../../components/BDCSettings/Trigger/Trigger")
);
const Leads = lazy(
  () => import("../../../../components/BDCSettings/Leads/Leads")
);
const Ledger = lazy(
  () => import("../../../../components/BDCSettings/Ledger/Ledger")
);
const EmailTemplate = lazy(
  () => import("../../../../components/BDCSettings/EmailTemplate/EmailTemplate")
);

export default function BDCCampaignSettingsPage() {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { option } = useParams();
  const { dealer_id } = useParams();
  const dealership_id = useUserInfo("dealership")?.id ?? dealer_id;
  const { id } = useParams();
  const apiSet = !useUserInfo("dealership")?.id
    ? dealer_id
      ? adminDealershipAPIs
      : globalBDCApis
    : undefined;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["nameofcampaign", dealership_id, id],
    queryFn: apiSet ? apiSet?.getBDCCampaignByID : getBDCCampaignByID,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setTabIndex(parseInt(option ?? "0") - 1);
  }, [option]);

  const navigate = useNavigate();
  return (
    <VStack>
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1rem 1.5rem"
        background="white"
      >
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "black",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <Box w="100%" padding="1.5rem">
        <Box
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          w="100%"
        >
          <Tabs
            variant="vertical"
            display="flex"
            w="100%"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Text
                p="1rem"
                textStyle="h5"
                // color="var(--grey-500)"
                fontWeight="600"
              >
                Campaign Name: {data?.data?.name}
              </Text>
              <Tab>Settings</Tab>
              <Tab>Activity Workflow</Tab>
              {(useUserInfo("dealership")?.id || dealer_id) && (
                <Tab>Contacts</Tab>
              )}
              {(useUserInfo("dealership")?.id || dealer_id) && (
                <Tab>Ledger</Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Suspense fallback={<Spinner />}>
                  {tabIndex === 0 && (
                    <Settings apiSet={apiSet} refetchParent={refetch} />
                  )}
                </Suspense>
              </TabPanel>
              <TabPanel>
                <Suspense fallback={<Spinner />}>
                  {tabIndex === 1 && (
                    <Steps
                      apiSet={apiSet}
                      global={
                        data?.data?.global_id && dealership_id ? true : false
                      }
                      bdc_key={
                        data?.data?.bdc_campaign_key
                      }
                    />
                  )}
                </Suspense>
              </TabPanel>
              <TabPanel>
                <Suspense fallback={<Spinner />}>
                  {tabIndex === 2 && <Leads apiSet={apiSet} />}
                </Suspense>
              </TabPanel>
              <TabPanel>
                <Suspense fallback={<Spinner />}>
                  {tabIndex === 3 && <Ledger apiSet={apiSet} />}
                </Suspense>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </VStack>
  );
}
