import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Input, PaginatedTable, Spinner } from "../../../components/common";
import CreateDealership from "../../../components/GlobalDashboard/CreateDealership/CreateDealership";
import ReActivate from "../../../components/GlobalDashboard/ReActivateUser/ReActivateUser";
import SuspendUser from "../../../components/GlobalDashboard/SuspendUser/SuspendUser";
import {
  UserData,
  getDealershipMenuHeaders,
  mapTableData,
} from "../../../components/GlobalDashboard/UserUtils";
import { SearchIcon } from "../../../components/icons/svg";
import {
  fetchDealershipListing,
  impersonateUser,
} from "../../../utils/api/users.api";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const DealershipsPage = () => {
  const user = useUserInfo("user");
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [dealershipCount, setDealershipCount] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [clickedRow, setClickedRow] = useState<UserData>({
    id: 0,
    uuid:0,
    logged_ip: "",
    full_name: "",
    last_login: "",
    access_level: "",
    created_at: "",
    status: "",
    suspend_at: "",
    email: "",
    time_zone: "",
    total_users: "",
  });
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const [searchValue, setSearchValue] = useState<string>("");

  const [tableHeader, setTableHeader] = useState(
    getDealershipMenuHeaders(isActive)
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["dealershipUsersMain", isActive, page, perPage],
    queryFn: () => {
      const queryParameters: any = {
        status: isActive ? "active" : "suspended",
        searchValue: searchValue,
      };

      if (!searchValue) {
        queryParameters.page = page;
        queryParameters.per_page = perPage;
      }

      return fetchDealershipListing(queryParameters);
    },
  });
  const handleUserImpersonate = async (userData: UserData) => {
    await impersonateUser(
      userData.id,
      dispatch,
      true,
      {
        menu: "Users",
        "Sub-Menu": "Dealership Users",
      },
      user?.role,
      user?.id
    );
    navigate("/");
  };

  const handleSelect = (option: SelectOptionProps, user: UserData) => {
    if (option.value === "impersonate") {
      return handleUserImpersonate(user);
    }
    setActiveModal(option.value);
  };

  const optionsActive: SelectOptionProps[] = [
    { label: "Impersonate", value: "impersonate" },
    { label: "Settings", value: "settings" },
    { label: "Suspend", value: "suspend" },
  ];
  const optionsSuspended: SelectOptionProps[] = [
    { label: "Settings", value: "settings" },
    { label: "Re-Activate", value: "reActivate" },
  ];
  const activeData =
    data &&
    data.dealerships?.map((item: UserData) =>
      mapTableData(
        item,
        (event: any) => handleSelect(event, item),
        isActive ? item?.name!=='Sandbox'?optionsActive:optionsActive.slice(0, -1) : optionsSuspended,
        true
      )
    );
  const [tableData, setTableData] = useState<any>(activeData);

  //...............use effects..................
  useEffect(() => {
    refetch();
  }, [refetch, searchValue]);

  useEffect(() => {
    setTableHeader(getDealershipMenuHeaders(isActive));
    setTableData(activeData);

    setDealershipCount(data?.dealerships_count ?? 0);
  }, [data, isActive]);

  useEffect(() => {
    if (activeModal === "settings") {
      navigate(`/dealerships/${clickedRow.id}`);
    }
  }, [activeModal]);

  useEffect(() => {
    resetPagination();
  }, [isActive]);

  const resetPagination = () => {
    setPage(1);
    setPerPage(20);
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get("error");
    if (error) {
      const decodedError = decodeURIComponent(error);
      toast({
        description: decodedError ?? "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  }, []);

  return (
    <>
      <VStack w="100%" alignItems="flex-start" gap="0">
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Type here to search"
              value={searchValue}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
                setPage(1);
              }}
            />
            <HStack border="1px solid var(--grey-300)" borderRadius="0.5rem">
              <Button
                variant="none"
                padding="0.5rem 1rem"
                onClick={() => setIsActive(true)}
                borderRadius="0.5rem"
                h="100%"
                fontWeight="500"
                background={isActive ? "var(--secondary-600)" : "white"}
                color={isActive ? "white" : "var(--grey-900)"}
              >
                Active
              </Button>
              <Button
                variant="none"
                h="100%"
                padding="0.5rem 1rem"
                borderRadius="0.5rem"
                fontWeight="500"
                onClick={() => setIsActive(false)}
                color={!isActive ? "white" : "var(--grey-900)"}
                background={!isActive ? "var(--secondary-600)" : "white"}
              >
                Suspended
              </Button>
            </HStack>
          </HStack>
          <Button onClick={onOpen} padding="1.25rem">
            Create Dealership
          </Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Dealerships ({dealershipCount})
            </Text>

            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              tableHeight={370}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={dealershipCount}
              isLoadingData={isLoading}
            />
          </Box>
        </VStack>

        {isOpen && (
          <CreateDealership
            isOpen={isOpen}
            onClose={onClose}
            refetchParent={refetch}
          ></CreateDealership>
        )}
        {activeModal === "reActivate" && (
          <ReActivate
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            userId={clickedRow?.id}
            isFromDealershipMenu={true}
          />
        )}
        {activeModal === "suspend" && (
          <SuspendUser
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            userId={clickedRow?.id}
            isFromDealershipMenu={true}
          />
        )}
      </VStack>
    </>
  );
};

export default DealershipsPage;
