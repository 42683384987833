import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { Drawer, Input, Select } from "../../../components/common";
import MakeInput from "../../../components/common/MakeInput";
import ModelInput from "../../../components/common/ModelInput";
import NameInput from "../../../components/common/NameInput";
import YearInput from "../../../components/common/YearInput";
import { CustomSelect } from "../../../theme/components";
import { fetchDMS } from "../../../utils/api/dms-equity-mining";
import { loadDPUsersApi } from "../../../utils/api/users.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { loadOutboundLine } from "../bdc-campaign/CreateCampaign/utils";
import { ContactsDrawer } from "./ContactsDrawer";
import DateInput from "./DateInput";
import EmailContent from "./EmailContent";
import SmsContent from "./SmsContent";

const schema = (isSms: boolean) => {
  return yup.object().shape({
    name: yup.string().nullable().required("Enter name"),
    content: isSms
      ? yup.string().nullable().required("Enter Message").max(1599)
      : yup
          .string()
          .nullable()
          .required("Enter Message")
          .test(
            "max-text-length",
            "Content cannot exceed 2499 characters in plain text",
            function (value) {
              if (!value) return true; // if no content, validation passes

              // Create a temporary element to convert HTML to plain text
              const tempElement = document.createElement("div");
              tempElement.innerHTML = value;
              const textContent =
                tempElement.innerText || tempElement.textContent || "";

              // Check if the plain text length exceeds 2499 characters
              return textContent.length <= 2499;
            }
          ),
    year: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Year must be an integer"),
    make: yup.string().nullable(),
    model: yup.string().nullable(),
    startRange: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0")
      .when("endRange", (endRange, schema) =>
        schema.max(endRange - 1, "Start range must be smaller than end range")
      ),
    endRange: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0"),
    startRangeOfPayment: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0")
      .when("endRangeOfPayment", (endRange, schema) =>
        schema.max(
          endRange - 1,
          "Start range of payments must be smaller than end range"
        )
      ),
    endRangeOfPayment: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0"),
    // mmrValue: yup
    //   .number()
    //   .nullable()
    //   .transform((value, originalValue) => (originalValue === "" ? null : value))
    //   .when("mmrType", {
    //     is: (mmrType: any) => mmrType && mmrType !== "nothingSelected",
    //     then: (schema) => schema.required("Enter MMR Value"),
    //     otherwise: (schema) => schema,
    //   }),
    termRemaining: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Term remaining must be an integer"),
  });
};

const SalesDMSEquityFilter = (props: any) => {
  const { onClose, refetch, id = null, isSms } = props;
  const dId = useUserInfo("dealership")?.id;
  const [formData, setFormData] = useState(null);
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const options = [
    { label: "Loan", value: "loan" },
    { label: "Lease", value: "lease" },
    { label: "Cash", value: "cash" },
  ];
  const optionMMM = [
    { label: "Nothing Selected", value: "nothingSelected" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ];

  const purchaseMethodMapping: Record<string, string> = {
    loan: "Loan",
    lease: "Lease",
    cash: "Cash",
  };

  const { control, handleSubmit, watch, setValue, getValues, resetField } =
    useForm<any>({
      resolver: yupResolver(schema(isSms)),
    });
  const purchaseMethod = watch("purchaseMethod");
  const values = watch();
  useEffect(() => {
    setValue("date", {
      startDate: null,
      endDate: null,
      key: "selection",
      selected: false,
    });
  }, []);

  const loadActiveDPUsers = async (inputValue: string, role: string) => {
    try {
      const response = await loadDPUsersApi({
        dealership_id: dId,
        role_titles: role,
        status: "active",
        q: inputValue,
      });
      const options = response?.users?.map((user: any) => {
        return {
          value: user.id,
          label: user.full_name,
        };
      });
      return options;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };
  useEffect(() => {
    const fetchDefaultOption = async () => {
      const options = await loadOutboundLine("", dId);
      const defaultOption = options.find(
        (option: any) => option.default === true
      );
      if (defaultOption && !id) {
        setValue("line_id", defaultOption);
      }
    };

    fetchDefaultOption();
  }, [id, setValue]);

  const { isLoading } = useQuery({
    queryKey: ["fetchDMS", dId, id],
    queryFn: fetchDMS,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setValue("name", data?.data?.name);
      setValue("content", data?.data?.content);
      setValue("subject", data?.data?.subject);

      if (data?.data?.line?.id) {
        setValue("line_id", {
          value: data?.data?.line?.id,
          label: data?.data?.line?.phone_number,
        });
      }

      setAttachmentUrls(
        data?.data?.media_urls?.map((item: any) => ({
          url: item,
          filename: item,
        }))
      );
      const formData = data?.data?.filters;
      setValue("date", {
        startDate: formData?.start_date ? new Date(formData?.start_date) : null,
        endDate: formData?.end_date ? new Date(formData?.end_date) : null,
        key: "selection",
        selected: true,
      });

      setValue("year", formData?.year);
      setValue("make", formData?.make);
      setValue("model", formData?.model);
      setValue(
        "purchaseMethod",
        formData?.purchaseMethod?.split(",")?.map((item: any) => ({
          value: item,
          label: purchaseMethodMapping[item],
        }))
      );
      setValue("termRemaining", formData?.termRemaining);
      setValue("startRange", formData?.startRange);
      setValue("endRange", formData?.endRange);
      setValue("startRangeOfPayment", formData?.startRangeOfPayment);
      setValue("endRangeOfPayment", formData?.endRangeOfPayment);
      setValue("mmrType", formData?.mmrType);
      setValue("mmrValue", formData?.mmrValue);
      setValue(
        "salesPerson",
        formData?.sales_person?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      );
    },
  });

  const onSubmit = (data: any) => {
    const { date, purchaseMethod, salesPerson, ...rest } = data;
    const transformedData: any = {};
    if (salesPerson && salesPerson?.length > 0) {
      transformedData.salesPerson = salesPerson
        .map((item: any) => item.value)
        .join(",");
    }

    if (purchaseMethod && purchaseMethod?.length > 0) {
      transformedData.purchaseMethod = purchaseMethod
        .map((item: any) => item.value)
        .join(",");
    }

    if (
      date &&
      date?.selected &&
      date?.startDate &&
      date?.endDate &&
      String(date?.startDate) !== String(date?.endDate)
    ) {
      transformedData.start_date = date.startDate;
      transformedData.end_date = date.endDate;
    }
    setFormData({ ...transformedData, ...rest });

    onContactsOpen();
  };

  const handlePurchaseMethodChange = (value: any) => {
    setValue("purchaseMethod", value);

    const condForLoanAndLease = !value?.some(
      (i: Record<string, string>) => i.value === "loan" || i.value === "lease"
    );

    const condForLoan = !value?.some(
      (i: Record<string, string>) => i.value === "loan"
    );

    if (condForLoanAndLease) {
      resetField("termRemaining");
      resetField("startRangeOfPayment");
      resetField("endRangeOfPayment");
    }
    if (condForLoan) {
      resetField("startRange");
      resetField("endRange");
    }
  };
  const EmailContentComponent = (
    <Box className="dmsEquityMiningEditor">
      <EmailContent
        control={control}
        setValue={setValue}
        getValues={getValues}
        attachmentUrls={attachmentUrls}
        setAttachmentUrls={setAttachmentUrls}
      ></EmailContent>
    </Box>
  );
  const SMSContentComponent = (
    <Box className="attachmentViewerWrapper">
      <SmsContent
        control={control}
        setValue={setValue}
        getValues={getValues}
        attachmentUrls={attachmentUrls}
        setAttachmentUrls={setAttachmentUrls}
      ></SmsContent>
    </Box>
  );

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title={id ? "Edit DMS Equity Mining" : "Create DMS Equity Mining"}
      isLoading={id ? isLoading : false}
    >
      <Box as="form">
        <Box padding="1.25rem">
          <Box mb="1rem">
            <NameInput control={control} />
          </Box>
          <Box mb="1rem" width="100%">
            <DateInput control={control} label="Date Of Sale" />
          </Box>

          <Box mb="1rem">
            <YearInput control={control} />
          </Box>

          <Box mb="1rem">
            <MakeInput control={control} />
          </Box>

          <Box mb="1rem"></Box>

          <Box mb="1rem">
            <ModelInput control={control} />
          </Box>

          <Box mb="1rem">
            <Controller
              name="purchaseMethod"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  label="Purchase Method"
                  placeholder="Select Purchase Method"
                  options={options}
                  variant="default"
                  isMulti={true}
                  w="100%"
                  onChange={(value) => {
                    field.onChange(value);
                    handlePurchaseMethodChange(value);
                  }}
                />
              )}
            />
          </Box>

          <Box mb="1rem">
            <Controller
              name="termRemaining"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  placeholder="Enter Term Remaining (months)"
                  label="Term Remaining (months)"
                  error={error?.message || ""}
                  isDisabled={
                    !purchaseMethod?.some(
                      (i: Record<string, string>) =>
                        i.value === "loan" || i.value === "lease"
                    )
                  }
                  maxLength={50}
                  type="number"
                />
              )}
            />
          </Box>
          <Box mb="1rem">
            <VStack alignItems="flex-start">
              <Text>Range of Interest Rates (%)</Text>
              <HStack alignItems="flex-start" w="100%">
                <Controller
                  name="startRange"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      placeholder="Starting range"
                      label=""
                      error={error?.message || ""}
                      isDisabled={
                        !purchaseMethod?.some(
                          (i: Record<string, string>) => i.value === "loan"
                        )
                      }
                      type="number"
                    />
                  )}
                />
                <Controller
                  name="endRange"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      label=""
                      placeholder="Ending range"
                      error={error?.message || ""}
                      isDisabled={
                        !purchaseMethod?.some(
                          (i: Record<string, string>) => i.value === "loan"
                        )
                      }
                      type="number"
                    />
                  )}
                />
              </HStack>
            </VStack>
          </Box>
          <Box mb="1rem">
            <VStack alignItems="flex-start">
              <Text>Range of Payment</Text>
              <HStack alignItems="flex-start" w="100%">
                <Controller
                  name="startRangeOfPayment"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      placeholder="Starting range"
                      label=""
                      error={error?.message || ""}
                      isDisabled={
                        !purchaseMethod?.some(
                          (i: Record<string, string>) =>
                            i.value === "loan" || i.value === "lease"
                        )
                      }
                      type="number"
                    />
                  )}
                />

                <Controller
                  name="endRangeOfPayment"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      label=""
                      placeholder="Ending range"
                      error={error?.message || ""}
                      isDisabled={
                        !purchaseMethod?.some(
                          (i: Record<string, string>) =>
                            i.value === "loan" || i.value === "lease"
                        )
                      }
                      type="number"
                    />
                  )}
                />
              </HStack>
            </VStack>
          </Box>
          <Box mb="1rem">
            <Controller
              name="mmrType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Estimated MMR Value"
                  placeholder="Select Estimated MMR Value"
                  options={optionMMM}
                  variant="default"
                  borderRadius="0.5rem"
                  w="100%"
                  onChange={(value: any) => {
                    setValue("mmrType", value?.value);
                    if (value?.value === "nothingSelected") {
                      resetField("mmrValue");
                    }
                  }}
                  isDisabled
                />
              )}
            />
          </Box>
          {/* {mmrType && mmrType !== "nothingSelected" && (
            <Box mb="1rem">
              <Controller
                name="mmrValue"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    placeholder="Enter MMR Value"
                    label="MMR Value"
                    type="number"
                    error={error?.message || ""}
                    maxLength={10}
                  />
                )}
              />
            </Box>
          )} */}
          <Box mb="1rem">
            <Text>User</Text>
            <Controller
              name="salesPerson"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  styles={{
                    ...CustomSelect?.styles?.customSelect?.default,
                    height: "100%",
                  }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "")
                  }
                  placeholder="Select User"
                  defaultOptions
                  isMulti
                  value={value}
                  onChange={(value: any) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </Box>

          {isSms ? SMSContentComponent : EmailContentComponent}
        </Box>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
          background="white"
        >
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isDisabled={
              !(
                values?.name &&
                values?.content &&
                (isSms ? true : values?.subject)
              )
            }
          >
            Next
          </Button>
        </DrawerFooter>
      </Box>
      {isContactsOpen && formData && (
        <ContactsDrawer
          onClose={onClose}
          refetch={refetch}
          id={id}
          isContactsOpen={isContactsOpen}
          onContactsClose={onContactsClose}
          formData={formData}
          category="sales"
          attachmentUrls={attachmentUrls}
          isSms={isSms}
        ></ContactsDrawer>
      )}
    </Drawer>
  );
};

export default SalesDMSEquityFilter;
