import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Image,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ActionCableContext } from "../../App";
import PersonAvatar from "../../assets/images/personAvatar.svg";
import { SidebarMenu } from "../../config/constants/sidebar";
import {
  getUserDealerships,
  getUserImage,
} from "../../utils/api/dealership.api";
import { getNotificationCount } from "../../utils/api/header-notifications.api";
import { logoutapi } from "../../utils/api/login.api";
import { stopImpersonateUser } from "../../utils/api/users.api";
import {
  logout,
  update,
  useAuthDispatch,
} from "../../utils/context/AuthContext";
import { useTwilio } from "../../utils/hooks/useTwilio";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import Keypad from "../Keypad/Keypad";
import Emails from "../Notifications/Emails/Emails";
import { Messages } from "../Notifications/Messages/Messages";
import { Reminders } from "../Notifications/Reminders/Reminders";
import { Input, Popover } from "../common";
import {
  ChatIcon,
  LogoutIcon,
  NotificationBell,
  NotificationIcon,
  OpenPhoneIcon,
  PingingsIcon,
  SearchIcon,
  SendMsgIcon,
  SettingsIcon,
  TikIcon,
} from "../icons/svg";
import ExpandableModal from "./ExpandableModal";
import TooltipIfOverflow from "./TooltipIfOverflow";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Draggable from "react-draggable";
import { SERVICES } from "../../config";
import { disableOpenPhone } from "../../utils/api/dealership-setting.api";
import { getPermissions } from "../../utils/api/usersetting.api";
import { useBreadcrumb } from "../../utils/context/BreadCrumsContext/BreadcrumbContext";
import { NotificationContext } from "../../utils/context/NotificationContext/NotificationContext";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import MissedCalls from "../Notifications/MissedCall/MissedCalls";
import Notifications from "../Notifications/Notifications/Notifications";
import { Pings } from "../Notifications/Pings/Pings";

const getLeadCenterBreadCrumbs = (searchParam?: string) => {
  if (!searchParam) return;

  const leadCenterMapper: any = {
    priority_1: "Priority #1 Leads",
    priority_2: "Priority #2 Leads",
    priority_3: "Priority #3 Leads",
    unread_messages: "Unread Message Leads",
    new_leads: "New Leads",
    engaged_leads: "Engaged Leads",
    appt_conf: "Appointment Confirmations Leads",
  };

  return leadCenterMapper[searchParam];
};

const BTNStyle = {
  height: "100%",
  minWidth: "5rem",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  borderRight: "1px solid var(--grey-300)",
};
const Header = ({ list }: any) => {
  const { cable } = useContext(ActionCableContext);
  const [isLoadingLogout, setIsLoadingLogout] = useState(false); // Add loader state

  const location = useLocation();
  const containerRef = useRef(null);
  const dispatch = useAuthDispatch();
  const twilioDispatch = useTwilioDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const dealerId = useUserInfo("dealership");
  const token = useUserInfo("token");
  let user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : "";
  const { breadcrumb } = useBreadcrumb();

  const { data: isOpenPhone } = useQuery({
    queryKey: ["openPhone", user?.dealership?.id],
    queryFn: disableOpenPhone,
    refetchOnWindowFocus: false,
    retry: false,
  });

  // useEffect(() => {
  //   if (containerRef) {()
  //     containerRef.current.scrollTop = containerRef?.current?.scrollHeight;
  //   }
  // }, [containerRef]);
  useEffect(() => {
    try {
      if (!cable) return;
      cable.subscriptions.create(
        {
          channel: "NotificationChannel",
          dealership_id: dealerId?.id,
          user_id: user?.id,
          token: token,
        },
        {
          connected: () => {
            console.log("Connected to Notification channel");
          },
          disconnected: () => {
            console.log("Disconnected from Notification channel");
          },
          received: async (data: any) => {
            countRefetch();
            console.log("Data received:", data);
          },
        }
      );
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cable, token, dealerId]);

  const { onOpen, isOpen, onClose } = useDisclosure();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const {
    onOpen: onModalOpen,
    isOpen: isModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const {
    isOpen: ReminderOpen,
    onOpen: onReminderOpen,
    onClose: onReminderClose,
  } = useDisclosure();
  const {
    isOpen: isEmailOpen,
    onOpen: onEmailOpen,
    onClose: onEmailClose,
  } = useDisclosure();
  const {
    isOpen: isMessageOpen,
    onOpen: onMessageOpen,
    onClose: onMessageClose,
  } = useDisclosure();

  const {
    isOpen: isPingOpen,
    onOpen: onPingOpen,
    onClose: onPingClose,
  } = useDisclosure();
  const {
    isOpen: notificationOpen,
    onOpen: onNotificationOpen,
    onClose: onNotificationClose,
  } = useDisclosure();

  const {
    isOpen: missedCallOpen,
    onOpen: onMissedCallOpen,
    onClose: onMissedCallClose,
  } = useDisclosure();

  const [isWebChatOpen, setIsWebChatOpen] = useState<boolean>(false);
  const [isExpandChat, setIsExpandChat] = useState<boolean>(false);
  const [notiType, setNotiType] = useState<string>("");
  const [notiCount, setNotiCount] = useState<number>(0);
  const [chatView, setChatView] = useState<boolean>(false);

  const { devices, selectedCampaign } = useTwilioState();
  const [searchParam] = useSearchParams();
  const priority = searchParam.get("priority");
  const statKey = searchParam.get("statKey");

  const leadCenterBreadCumb = getLeadCenterBreadCrumbs(
    priority || statKey || ""
  );

  const handleStopCampaign = async () => {
    if (selectedCampaign) {
      try {
        await fetch(
          `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${selectedCampaign}/pause_resume_dialer?status=pause`,
          {
            body: JSON.stringify({ userId: user?.id }),
            method: "POST",
            keepalive: true,
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        await fetch(
          `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${selectedCampaign}/toggle_lock?lock_status=unlock&user_id=${user.id}`,
          {
            body: JSON.stringify({ userId: user?.id }),
            method: "POST",
            keepalive: true,
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        twilioDispatch({
          type: "SET_SELECTED_CAMPAIGN",
          payload: { selectedCampaign: "" },
        });
      } catch (error) {
        console.error("Error stopping the campaign: ", error);
      }
    }
  };

  const switchDealership = async (item: any) => {
    await handleStopCampaign();
    user.dealership = item;
    localStorage.setItem("currentUser", JSON.stringify(user));
    update(dispatch, { key: "user", value: user });
    window.location.replace("/");
  };

  const handleStopImpersonating = async () => {
    await handleStopCampaign();
    await stopImpersonateUser(dispatch);
    devices.forEach((device) => {
      device.device?.destroy();
    });
    navigate("/");
  };

  const handleLogout = async () => {
    setIsLoadingLogout(true);
    await handleStopCampaign();
    try {
      const logoutresponse = await logoutapi();
      if (logoutresponse) {
        logout(dispatch);
        <Navigate to="/login" />;
      }
    } catch (error) {
      toast({
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoadingLogout(false);
    }
  };
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["userDealerships"],
    queryFn: getUserDealerships,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });
  const { data: image } = useQuery({
    queryKey: ["user-image", dealerId?.id],
    queryFn: getUserImage,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: count, refetch: countRefetch } = useQuery({
    queryKey: ["notificationacount", dealerId?.id],
    queryFn: getNotificationCount,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: permissionsData, isFetching: isPermissionsLoading } = useQuery({
    queryKey: ["headerPermissions", user?.id, dealerId?.id],
    queryFn: getPermissions,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const isOpenPhoneAllowed = permissionsData?.data?.permissions?.some(
    (permission: any) =>
      permission.module_key === "open_phone" && permission.allowed
  );

  const expandModalOpen = (val: string, count: number) => {
    setNotiType(val);
    setNotiCount(count);
    onModalOpen();
  };

  useTwilio();

  return (
    <>
      <NotificationContext.Provider
        value={{ refetchNotifications: countRefetch }}
      >
        <HStack
          w="100%"
          position="absolute"
          top="0"
          left="0"
          px="1.5rem"
          height="4.3rem"
          background="white"
          borderBottom="1px solid var(--grey-300)"
          justify="space-between"
        >
          <HStack display="flex" alignItems="center" gap={2}>
            <Text
              textStyle="h6"
              cursor="pointer"
              fontWeight="700"
              color="var(--grey-900)"
              onClick={() =>
                navigate(
                  SidebarMenu?.find((item: any) =>
                    location?.pathname.startsWith(item?.path)
                  )?.path ?? "/"
                )
              }
            >
              {
                SidebarMenu?.find((item: any) =>
                  location?.pathname.startsWith(item?.path)
                )?.breadCrum
              }
            </Text>

            {leadCenterBreadCumb && (
              <>
                <KeyboardArrowRightIcon style={{ color: "var(--grey-900)" }} />
                <Text
                  textStyle="h6"
                  cursor="pointer"
                  fontWeight="500"
                  color="var(--grey-900)"
                >
                  {leadCenterBreadCumb}
                </Text>
              </>
            )}
            {location?.pathname.includes("/users/settings/") && (
              <>
                <KeyboardArrowRightIcon style={{ color: "var(--grey-900)" }} />
                <Text
                  textStyle="h6"
                  cursor="pointer"
                  fontWeight="500"
                  color="var(--grey-900)"
                >
                  {breadcrumb}
                </Text>
              </>
            )}
          </HStack>

          <HStack h="100%" gap="0">
            <HStack h="100%" zIndex="100">
              {list?.find(
                (item: any) => item?.display_name === "Emails" && item?.allowed
              ) && (
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  isOpen={isEmailOpen}
                  onClose={onEmailClose}
                  trigger={
                    <HStack onClick={onEmailOpen}>
                      <EmailIcon
                        style={{
                          width: "2rem",
                          height: "2rem",
                          color: "var(--grey-800)",
                        }}
                      />
                      <Tooltip
                        label={count?.data?.unread_email_count}
                        isDisabled={
                          String(count?.data?.unread_email_count).length <= 3
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color="var(--grey-900)"
                        >
                          {String(count?.data?.unread_email_count).length > 5
                            ? `${String(count?.data?.unread_email_count).slice(
                                0,
                                5
                              )}+`
                            : count?.data?.unread_email_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  contentStyle={{
                    width: "28rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {isEmailOpen && (
                    <Emails
                      onOpen={expandModalOpen}
                      onClose={onEmailClose}
                      count={count?.data?.total_email_count}
                    />
                  )}
                </Popover>
              )}
              {/* <Popover
            btnVariant="none"
            variant="custom"
            btnStyle={BTNStyle}
            trigger={
              <HStack>
                <VoicemailIcon />
                <Text textStyle="h5" fontWeight="500" color="var(--grey-900)">
                  12
                </Text>
              </HStack>
            }
            placement="bottom-start"
            contentStyle={{
              width: "25rem",
              borderRadius: "0.5rem",
            }}
          >
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack w="100%" justifyContent="space-between" padding="1rem">
                <Text textStyle="h5" fontWeight="500">
                  All Emails (6)
                </Text>
                <Text
                  textStyle="h6"
                  color="var(--primary-600)"
                  fontWeight="500"
                  cursor="pointer"
                >
                  Mark all as read
                </Text>
              </HStack>
              <VStack
                w="100%"
                alignItems="flex-start"
                gap="0.59rem"
                h="38rem"
                overflow="auto"
                padding="1rem"
                pt="0"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <HStack
                    padding="1rem"
                    w="100%"
                    key={item}
                    background="var(--grey-100)"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    alignItems="flex-start"
                  >
                    <Box
                      padding="0.5rem"
                      border="1px solid var(--grey-300)"
                      borderRadius="0.5rem"
                      background="white"
                      sx={{
                        svg: {
                          path: {
                            fill: "var(--secondary-600)",
                          },
                        },
                      }}
                    >
                      <VoicemailIcon />
                    </Box>
                    <VStack gap="0.25rem" w="100%" alignItems="flex-start">
                      <Text textStyle="h5" fontWeight="500">
                        From:{" "}
                        <Box as="span" color="var(--primary-600)">
                          Marvin Carranza
                        </Box>
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="500"
                        color="var(--grey-800)"
                      >
                        Do you have a trade?
                      </Text>
                      <Text textStyle="captionSmall" fontWeight="500">
                        Thursday 3 August 23
                        <Box as="span" color="var(--primary-600)">
                          • 6:06 pm
                        </Box>
                      </Text>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </Popover> */}
              {list?.find(
                (item: any) =>
                  item?.display_name === "Messages" && item?.allowed
              ) && (
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  trigger={
                    <HStack onClick={onMessageOpen}>
                      <ChatIcon />
                      <Tooltip
                        label={count?.data?.unread_messages_count}
                        isDisabled={
                          String(count?.data?.unread_messages_count).length <= 5
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color="var(--grey-900)"
                        >
                          {count?.data?.unread_messages_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  contentStyle={{
                    width: "25rem",
                    borderRadius: "0.5rem",
                  }}
                  onClose={onMessageClose}
                  isOpen={isMessageOpen}
                >
                  {isMessageOpen && (
                    <Messages
                      onOpen={expandModalOpen}
                      onClose={onMessageClose}
                      count={count?.data?.total_messages_count}
                    />
                  )}
                </Popover>
              )}

              {list?.find(
                (item: any) =>
                  item?.display_name === "Missed Calls" && item?.allowed
              ) && (
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  trigger={
                    <HStack onClick={onMissedCallOpen}>
                      <PhoneMissedIcon
                        style={{
                          color: "white",
                          background: count?.data?.unread_missed_call_count
                            ? "var(--red-600)"
                            : "#424242",
                          borderRadius: "0.1875rem",
                          padding: "0.25rem 0.35rem",
                          width: "1.75rem",
                          height: "1.5rem",
                        }}
                      />
                      <Tooltip
                        label={count?.data?.unread_missed_call_count}
                        isDisabled={
                          String(count?.data?.unread_missed_call_count)
                            .length <= 3
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color={
                            count?.data?.unread_missed_call_count
                              ? "var(--red-600)"
                              : "#424242"
                          }
                        >
                          {String(count?.data?.unread_missed_call_count)
                            .length > 5
                            ? `${String(
                                count?.data?.unread_missed_call_count
                              ).slice(0, 5)}+`
                            : count?.data?.unread_missed_call_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  isOpen={missedCallOpen}
                  onClose={onMissedCallClose}
                  contentStyle={{
                    width: "25rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {missedCallOpen && (
                    <MissedCalls
                      onOpen={expandModalOpen}
                      count={count?.data?.total_missed_call_count}
                      onClose={onMissedCallClose}
                    />
                  )}
                </Popover>
              )}
              {list?.find(
                (item: any) =>
                  item?.display_name === "Reminders" && item?.allowed
              ) && (
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  trigger={
                    <HStack onClick={onReminderOpen}>
                      <NotificationIcon />
                      <Tooltip
                        label={count?.data?.unread_reminder_count}
                        isDisabled={
                          String(count?.data?.unread_reminder_count).length <= 5
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color="var(--grey-900)"
                        >
                          {String(count?.data?.unread_reminder_count).length > 5
                            ? `${String(
                                count?.data?.unread_reminder_count
                              ).slice(0, 5)}+`
                            : count?.data?.unread_reminder_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  isOpen={ReminderOpen}
                  onClose={onReminderClose}
                  contentStyle={{
                    width: "25rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {ReminderOpen && (
                    <Reminders
                      onOpen={expandModalOpen}
                      count={count?.data?.total_reminder_count}
                    />
                  )}
                </Popover>
              )}
              <Box position="relative" zIndex={999} height="100%">
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  trigger={
                    <HStack onClick={onNotificationOpen}>
                      <NotificationBell />
                      <Tooltip
                        label={count?.data?.unread_notification_count}
                        isDisabled={
                          String(count?.data?.unread_notification_count)
                            .length <= 5
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color="var(--grey-900)"
                        >
                          {String(count?.data?.unread_notification_count)
                            .length > 5
                            ? `${String(
                                count?.data?.unread_notification_count
                              ).slice(0, 5)}+`
                            : count?.data?.unread_notification_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  isOpen={notificationOpen}
                  onClose={onNotificationClose}
                  contentStyle={{
                    width: "25rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {notificationOpen && (
                    <Notifications
                      onOpen={expandModalOpen}
                      count={count?.data?.total_notification_count}
                      onClose={onNotificationClose}
                    />
                  )}
                </Popover>
              </Box>
              {list?.find(
                (item: any) =>
                  item?.display_name === "Pingings" && item?.allowed
              ) && (
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={BTNStyle}
                  trigger={
                    <HStack onClick={onPingOpen}>
                      <PingingsIcon />
                      <Tooltip
                        label={count?.data?.unread_ping_count}
                        isDisabled={
                          String(count?.data?.unread_ping_count).length <= 5
                        }
                      >
                        <Text
                          textStyle="h5"
                          fontWeight="500"
                          color="var(--grey-900)"
                        >
                          {String(count?.data?.unread_ping_count).length > 5
                            ? `${String(count?.data?.unread_ping_count).slice(
                                0,
                                5
                              )}+`
                            : count?.data?.unread_ping_count}
                        </Text>
                      </Tooltip>
                    </HStack>
                  }
                  placement="bottom-start"
                  contentStyle={{
                    width: "25rem",
                    borderRadius: "0.5rem",
                  }}
                  isOpen={isPingOpen}
                  onClose={onPingClose}
                >
                  {isPingOpen && (
                    <Pings
                      onOpen={expandModalOpen}
                      count={count?.data?.total_ping_count}
                      onClose={onPingClose}
                      countRefetch={countRefetch}
                    />
                  )}
                </Popover>
              )}
            </HStack>
            <HStack
              px="1.4rem"
              h="100%"
              borderRight="1px solid var(--grey-300)"
              borderLeft="1px solid var(--grey-300)"
            >
              {/* <Button
              onClick={() => setIsWebChatOpen(true)}
              leftIcon={<OpenChatIcon />}
              background="var(--secondary-600)"
            >
              Chat
            </Button> */}

              {isOpenPhoneAllowed && (
                <Tooltip
                  hasArrow
                  label={
                    !isOpenPhone?.data?.within_hours
                      ? "Outside business hours, outbound calls are not permitted."
                      : devices.length
                      ? ""
                      : "No Line Exists!"
                  }
                  borderRadius="0.5rem"
                  placement="top"
                  background="var(--grey-900)"
                  padding="0.5rem"
                  fontSize="0.75rem"
                  fontWeight="600"
                  color="white"
                >
                  <Button
                    leftIcon={<OpenPhoneIcon />}
                    onClick={onOpen}
                    background="var(--secondary-600)"
                    border="1px solid var(--grey-300)"
                    isDisabled={!isOpenPhone?.data?.within_hours}
                  >
                    Open Phone
                  </Button>
                </Tooltip>
              )}
            </HStack>
            <Popover
              btnVariant="none"
              variant="custom"
              btnStyle={{ width: "max-content", paddingLeft: "1.4rem" }}
              onOpen={() => refetch()}
              trigger={
                <HStack
                  position="relative"
                  paddingRight="1.4rem"
                  onClick={() => setPopoverOpen(!popoverOpen)}
                >
                  {image?.data?.dealership_picture ? (
                    <Image
                      src={image?.data?.dealership_picture}
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                      alt="avatar"
                    />
                  ) : (
                    <AccountCircleIcon
                      style={{
                        width: "36px",
                        height: "36px",
                      }}
                    />
                  )}
                  <VStack gap="0" align="flex-start">
                    <Text
                      textStyle="h6"
                      fontWeight="700"
                      color="var(--grey-900)"
                    >
                      {user?.first_name}{" "}
                      {user?.last_name ? user?.last_name : ""}
                    </Text>
                    <Text
                      textStyle="h6"
                      fontWeight="500"
                      color="var(--grey-600)"
                    >
                      {user?.dealership?.name ?? ""}
                    </Text>
                  </VStack>
                  <ExpandMoreIcon
                    style={{ position: "absolute", bottom: "-5px", right: "0" }}
                  />
                </HStack>
              }
              placement="bottom-start"
              contentStyle={{
                width: "16rem",
                borderRadius: "0.5rem",
              }}
              isOpen={popoverOpen}
              onClose={() => setPopoverOpen(false)}
            >
              <VStack
                w="100%"
                alignItems="flex-start"
                position="relative"
                gap="0"
              >
                <VStack w="100%" gap="0.62rem" padding="0.88rem">
                  {image?.data?.dealership_picture ? (
                    <Image
                      src={image?.data?.dealership_picture}
                      width="61px"
                      height="61px"
                      alt="avatar"
                      objectFit="contain"
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <AccountCircleIcon
                      style={{
                        width: "61px",
                        height: "61px",
                      }}
                    />
                  )}
                  <VStack w="100%" gap="0.19rem">
                    <Text textStyle="h5" fontWeight="600">
                      {user?.first_name}{" "}
                      {user?.last_name ? user?.last_name : ""}
                    </Text>
                    <Text
                      textStyle="h6"
                      color="var(--grey-700)"
                      fontWeight="500"
                    >
                      {user?.dealership?.name ?? ""}
                    </Text>
                  </VStack>
                </VStack>
                <VStack w="100%" alignItems="flex-start" gap="0">
                  {(isLoading || isPermissionsLoading) && (
                    <HStack
                      w="100%"
                      padding="0.5rem 6.96rem"
                      borderBottom="1px solid var(--grey-300)"
                      background="var(--grey-100)"
                      justifyContent="space-between"
                    >
                      <HStack gap="0.69rem">
                        <Spinner />
                      </HStack>
                    </HStack>
                  )}
                  {data?.data?.dealerships?.map((item: any) => (
                    <HStack
                      w="100%"
                      padding="0.5rem 0.96rem"
                      borderBottom="1px solid var(--grey-300)"
                      background="var(--grey-100)"
                      justifyContent="space-between"
                      cursor="pointer"
                      onClick={() => {
                        switchDealership(item);
                      }}
                    >
                      <HStack gap="0.69rem">
                        {item?.dealership_image ? (
                          <Image
                            src={item?.dealership_image}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                              objectFit: "contain",
                            }}
                            alt="avatar"
                          />
                        ) : (
                          <AccountCircleIcon
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <Text textStyle="h6">
                          <TooltipIfOverflow char={20}>
                            {item?.name}
                          </TooltipIfOverflow>
                        </Text>
                      </HStack>
                      {item.id === dealerId?.id && <TikIcon />}
                    </HStack>
                  ))}
                  <HStack
                    w="100%"
                    padding="0.5rem 0.96rem"
                    borderBottom="1px solid var(--grey-300)"
                    justifyContent="space-between"
                  >
                    <HStack
                      gap="0.69rem"
                      onClick={!isLoadingLogout ? handleLogout : undefined} // Prevent click action if loading
                      cursor={isLoadingLogout ? "not-allowed" : "pointer"} // Change cursor style if loading
                      opacity={isLoadingLogout ? 0.6 : 1} // Dim the button if loading
                    >
                      <Box
                        padding="0.5rem"
                        border="1px solid var(--grey-300)"
                        borderRadius="50%"
                      >
                        {" "}
                        <LogoutIcon />
                      </Box>
                      <Text textStyle="h6" fontWeight="500">
                        Log out
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
                <Button
                  variant="none"
                  position="absolute"
                  top="0.5rem"
                  right="0.5rem"
                  sx={{
                    svg: {
                      width: "1.5rem",
                      height: "1.5rem",
                      path: {
                        fill: "var(--grey-800)",
                      },
                    },
                  }}
                  onClick={() => {
                    setPopoverOpen(false);
                    navigate("/users/settings");
                  }}
                >
                  <SettingsIcon />
                </Button>
              </VStack>
            </Popover>
          </HStack>
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement="bottom"
            id="phone-drawer"
            closeOnOverlayClick={false}
            closeOnEsc={false}
          >
            <DrawerContent padding="0">
              <DrawerBody padding="0">
                <Keypad onClose={onClose} showHeader />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <Drawer
            isOpen={isWebChatOpen}
            onClose={() => setIsWebChatOpen(false)}
            placement="bottom"
            id="chat-drawer"
          >
            <DrawerContent
              padding="0"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
            >
              <DrawerBody padding="0">
                <VStack w="100%" alignItems="flex-start" gap="0">
                  <HStack
                    justifyContent="space-between"
                    w="100%"
                    background="var(--secondary-600)"
                    borderTopRightRadius="0.5rem"
                    borderTopLeftRadius="0.5rem"
                    padding="0.69rem 1rem"
                    onClick={() => setIsExpandChat(!isExpandChat)}
                  >
                    <Text color="white" textStyle="h5" fontWeight="600">
                      Web Chat
                    </Text>
                    <Button
                      variant="none"
                      sx={{
                        svg: {
                          path: {
                            fill: "white",
                          },
                        },
                      }}
                    >
                      <ExpandMoreIcon
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                        }}
                      />
                    </Button>
                  </HStack>
                  {isExpandChat && (
                    <VStack w="100%" alignItems="flex-start" gap="0">
                      <Input
                        hasIcon={true}
                        isIconRight={false}
                        icon={<SearchIcon color="var(--grey-900)" />}
                        placeholder="Search Chat"
                        border="none"
                        background="var(--grey-200)"
                        borderRadius="0"
                      />
                      <VStack w="100%" h="30rem" overflow="auto">
                        {!chatView &&
                          [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                            <HStack
                              w="100%"
                              className="tab-wrapper"
                              cursor="pointer"
                              onClick={() => setChatView(true)}
                              key={item}
                              padding="1rem"
                              _hover={{
                                background: "var(--grey-100)",
                              }}
                            >
                              <HStack gap="0.72rem" w="100%">
                                <VStack
                                  justifyContent="center"
                                  alignItems="center"
                                  position="relative"
                                >
                                  <Box
                                    w="0.7rem"
                                    h="0.7rem"
                                    background="var(--secondary-600)"
                                    borderRadius="50%"
                                    border="1px solid white"
                                    position="absolute"
                                    right="2px"
                                    top="0"
                                  ></Box>
                                  <Image
                                    src={PersonAvatar}
                                    alt="avatar"
                                    style={{
                                      borderRadius: "50%",
                                      objectFit: "contain",
                                    }}
                                  />
                                </VStack>
                                <VStack alignItems="flex-start" gap="0.48rem">
                                  <Text
                                    textStyle="h5"
                                    fontWeight="600"
                                    className="chatter-name"
                                  >
                                    Alberto Aidan
                                  </Text>
                                  <Text
                                    textStyle="h6"
                                    fontWeight="500"
                                    color="var(--grey-600)"
                                  >
                                    What are your hours?
                                  </Text>
                                  <Text
                                    textStyle="h6"
                                    fontWeight="500"
                                    color="var(--grey-600)"
                                  >
                                    13 Jan 2023 - 6:06 PM
                                  </Text>
                                </VStack>
                              </HStack>
                              <Text
                                textStyle="h6"
                                fontWeight="600"
                                color="white"
                                background="var(--red-600)"
                                padding="0.24rem 0.36rem"
                                borderRadius="0.6rem"
                              >
                                10
                              </Text>
                            </HStack>
                          ))}
                        {chatView && (
                          <VStack
                            w="100%"
                            alignItems="center"
                            gap="0"
                            position="relative"
                          >
                            <HStack
                              w="100%"
                              padding="0.44rem 0.96rem"
                              borderBottom="1px solid var(--grey-300)"
                            >
                              <Button
                                variant="none"
                                onClick={() => setChatView(false)}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <ChevronLeftIcon />
                              </Button>
                              <HStack gap="0.72rem" w="100%">
                                <VStack
                                  justifyContent="center"
                                  alignItems="center"
                                  position="relative"
                                >
                                  <Box
                                    w="0.5rem"
                                    h="0.5rem"
                                    background="var(--secondary-600)"
                                    borderRadius="50%"
                                    border="1px solid white"
                                    position="absolute"
                                    right="2px"
                                    top="0"
                                  ></Box>
                                  <Image
                                    src={PersonAvatar}
                                    alt="avatar"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                    width="34px"
                                    height="34px"
                                  />
                                </VStack>

                                <Text
                                  textStyle="h5"
                                  fontWeight="600"
                                  fontSize="0.95781rem"
                                  className="chatter-name"
                                >
                                  Alberto Aidan
                                </Text>
                              </HStack>
                            </HStack>
                            <VStack
                              w="100%"
                              alignItems="flex-start"
                              height="325px"
                              overflow="auto"
                              ref={containerRef}
                            >
                              <HStack
                                gap="0.72rem"
                                w="100%"
                                padding="0.96rem"
                                alignItems="flex-start"
                              >
                                <VStack
                                  justifyContent="center"
                                  alignItems="center"
                                  position="relative"
                                  minW="11%"
                                >
                                  <Image
                                    src={PersonAvatar}
                                    alt="avatar"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                    width="38px"
                                    height="38px"
                                  />
                                </VStack>
                                <VStack alignItems="flex-start" gap="0.48rem">
                                  <HStack>
                                    <Text
                                      textStyle="h5"
                                      fontWeight="600"
                                      className="chatter-name"
                                    >
                                      Alberto Aidan
                                    </Text>
                                    <Text
                                      textStyle="h6"
                                      fontWeight="500"
                                      color="var(--grey-600)"
                                    >
                                      6:06 PM
                                    </Text>
                                  </HStack>
                                  <Text
                                    textStyle="h6"
                                    fontWeight="500"
                                    color="var(--grey-700)"
                                    lineHeight="1.19725rem"
                                  >
                                    I see you visited us but not purchased as of
                                    yet. It's my personal duty.
                                  </Text>
                                </VStack>
                              </HStack>
                              {[1, 2, 3].map((item) => (
                                <>
                                  <HStack w="100%" gap="1.5rem">
                                    <Box
                                      as="span"
                                      width="100%"
                                      height="1px"
                                      background="var(--grey-300)"
                                    ></Box>
                                    <Box
                                      as="span"
                                      fontSize="0.83806rem"
                                      fontWeight="500"
                                      color="var(--grey-600)"
                                      whiteSpace="nowrap"
                                    >
                                      25 SEP
                                    </Box>
                                    <Box
                                      as="span"
                                      width="100%"
                                      height="1px"
                                      background="var(--grey-300)"
                                    ></Box>
                                  </HStack>
                                  <HStack
                                    gap="0.72rem"
                                    w="100%"
                                    padding="0.96rem"
                                    alignItems="flex-start"
                                  >
                                    <VStack
                                      justifyContent="center"
                                      alignItems="center"
                                      position="relative"
                                      minW="11%"
                                    >
                                      <Image
                                        src={PersonAvatar}
                                        alt="avatar"
                                        style={{
                                          borderRadius: "50%",
                                          width: "38px",
                                          height: "38px",
                                        }}
                                      />
                                    </VStack>
                                    <VStack
                                      alignItems="flex-start"
                                      gap="0.48rem"
                                    >
                                      <HStack>
                                        <Text
                                          textStyle="h5"
                                          fontWeight="600"
                                          className="chatter-name"
                                        >
                                          Alberto Aidan
                                        </Text>
                                        <Text
                                          textStyle="h6"
                                          fontWeight="500"
                                          color="var(--grey-600)"
                                        >
                                          6:06 PM
                                        </Text>
                                      </HStack>
                                      <Text
                                        textStyle="h6"
                                        fontWeight="500"
                                        color="var(--grey-700)"
                                        lineHeight="1.19725rem"
                                      >
                                        Cars are not cpo and you charge
                                        additional thousands for it, and the
                                        fees are redicoulis! 995 doc few, for
                                        mv82, st801 and reassigned title! Cars
                                        are not cpo and you charge additional
                                        thousands for it,
                                      </Text>
                                    </VStack>
                                  </HStack>
                                </>
                              ))}
                            </VStack>
                            <VStack
                              w="100%"
                              alignItems="flex-start"
                              position="fixed"
                              bottom="0"
                              left="0"
                              border=" 1px solid var(--grey-300)"
                              background="var(--grey-100)"
                              padding="0.62rem 0.88rem"
                            >
                              <HStack w="100%" alignItems="flex-start">
                                <Input
                                  isTextarea={true}
                                  background="transparent"
                                  border="none"
                                  padding="0"
                                  placeholder="Write a message"
                                  _focusVisible={{
                                    outline: "none",
                                  }}
                                />
                                <Button
                                  variant="none"
                                  padding="0.47rem 0.35rem 0.47rem 0.63rem"
                                  background="var(--primary-600)"
                                  borderRadius="0.5rem"
                                  sx={{
                                    svg: {
                                      width: "0.875rem",
                                      height: "0.875rem",
                                    },
                                  }}
                                >
                                  <SendMsgIcon />
                                </Button>
                              </HStack>
                            </VStack>
                          </VStack>
                        )}
                      </VStack>
                    </VStack>
                  )}
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          {isModalOpen && (
            <ExpandableModal
              isOpen={isModalOpen}
              onClose={onModalClose}
              type={notiType}
              count={notiCount}
            />
          )}
        </HStack>
        {user?.impersonating && (
          <Draggable allowAnyClick defaultPosition={{ x: 0, y: 0 }}>
            <HStack
              position="fixed"
              bottom="0"
              left="50%"
              transform="translate(-50%)"
              background="white"
              padding="1rem"
              zIndex="999"
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              justifyContent="space-between"
              maxW="max-content"
              width="100%"
              cursor="grab"
            >
              <Button
                variant="primary"
                justifyContent="center"
                color="white"
                maxW="200px"
                width="100%"
                size="xl"
                onClick={handleStopImpersonating}
              >
                Stop Impersonating
              </Button>
            </HStack>
          </Draggable>
        )}
      </NotificationContext.Provider>
    </>
  );
};

export default Header;
