import { Box, Center, HStack, Spinner, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getHistory } from "../../../../../../utils/api/history.api";
import {
  useHistoryDispatch,
  useHistoryState,
} from "../../../../../../utils/context/HistoryContext";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import { Select } from "../../../../../common";
import SlideRange from "../../../../../common/SlideRange";
import SingleLeadCard from "../../Leads/SingleLeadCard";
import SingleAppointmentCard from "../Appointment/SingleAppointmentCard";
import SingleCallCard from "../Calls/SingleCallCard";
import SingleDealsCard from "../Deals/SingleDealsCard";
import SingleEmailCard from "../Emails/SingleEmailCard";
import Export from "../Export";
import SingleMeetingCard from "../Meeting/SingleMeetingCard";
import SingleMessageCard from "../Messages/SingleMessageCard";
import NoItem from "../NoItem";
import SingleRVMCard from "../RVM/SingleRVMCard";
import SingleContactCard from "./SingleContactCard";

type AllActivityProps = {
  clickedRow: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

const options: SelectOptionProps[] = [
  { label: "All Activity", value: "all" },
  { label: "Calls", value: "CallHistory" },
  { label: "Messages", value: "Message" },
  { label: "RVM", value: "rvm" },
  { label: "Emails", value: "EmailLog" },
  { label: "Appointments", value: "appointment" },
  { label: "Meetings", value: "meeting" },
  { label: "Deals", value: "process_sale" },
];

function AllActivity({ clickedRow, setClickedRecord }: AllActivityProps) {
  const dealership = useUserInfo("dealership");
  const [activities, setActivities] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState("30");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedType, setSelectedType] = useState("");

  const { shouldRefetch } = useHistoryState();
  const dispatch = useHistoryDispatch();

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["allActivity", clickedRow?.id, sliderValue, page, selectedType],
    queryFn: () => {
      const historyParams = ["appointment", "rvm", "meeting"].includes(
        selectedType
      )
        ? { sub_type: selectedType }
        : selectedType === "process_sale"
        ? { type: selectedType, sub_type: "sales" }
        : { type: selectedType === "all" ? "" : selectedType };
      return getHistory({
        dealershipId: dealership?.id,
        contactId: clickedRow?.id,
        days: sliderValue,
        enabled: clickedRow?.id !== undefined,
        page,
        ...historyParams,
      });
    },
    retry: false,
    onSuccess: (data) => {
      if (activities[0]?.contact_id !== clickedRow?.id) {
        setActivities(data.data.histories || []);
      } else {
        const filteredNewHistories = data?.data?.histories?.filter(
          (newHistory: any) =>
            !activities?.some((existing) => existing.id === newHistory.id)
        );

        setActivities((prev) => [...prev, ...filteredNewHistories]);
      }
      setTotalCount(data.data.histories_count);
    },
  });

  useEffect(() => {
    setPage(1);
    setActivities([]);
    refetch();
  }, [clickedRow?.id, refetch, sliderValue]);

  useEffect(() => {
    if (shouldRefetch) {
      setPage(1);
      setActivities([]);
      refetch();
    }

    dispatch({ type: "SET_SHOULD_REFETCH", payload: { shouldRefetch: false } });
  }, [dispatch, refetch, shouldRefetch]);

  const getActivityComponent = useCallback(
    (item: any) => {
      switch (item.record_type) {
        case "call":
          if (item.details) {
            return (
              <SingleCallCard item={item} setClickedRecord={setClickedRecord} />
            );
          }
          return <></>;
        case "email_log":
          if (item.details) {
            return (
              <SingleEmailCard
                item={item}
                setClickedRecord={setClickedRecord}
              />
            );
          }
          return <></>;

        case "rvm":
          if (item.details) {
            return (
              <SingleRVMCard item={item} setClickedRecord={setClickedRecord} />
            );
          }
          return <></>;

        case "contact":
          if (item.details) {
            return <SingleContactCard item={item} />;
          }
          return <></>;
        case "event":
          if (item.details) {
            return item?.sub_type === "appointment" ? (
              <SingleAppointmentCard
                item={item}
                setClickedRecord={setClickedRecord}
              />
            ) : (
              <SingleMeetingCard
                item={item}
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            );
          }
          return <></>;
        case "process_sale":
          if (item.details) {
            return (
              <SingleDealsCard
                item={item}
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            );
          }
          return <></>;
        case "message":
          if (item.details) {
            return (
              <SingleMessageCard
                item={item}
                setClickedRecord={setClickedRecord}
              />
            );
          }
          return <></>;
        case "lead":
          if (item.details) {
            return (
              <SingleLeadCard
                item={item?.details}
                setClickedRecord={setClickedRecord}
              />
            );
          }
          return <></>;

        default:
          return <></>;
      }
    },
    [setClickedRecord, clickedRow]
  );

  const handleSelect = (option: any) => {
    setSelectedType(option.value);
    setPage(1);
    setActivities([]);
    // refetch();
  };

  return (
    <>
      {" "}
      <HStack background="white" padding="1rem">
        <Export days={sliderValue} id={clickedRow?.id} />
        {/* <Dropdown options={options} onSelect={handleSelect} /> */}
        <Box width="50%" pos="relative" zIndex="99">
          <Select
            label=""
            onChange={handleSelect}
            placeholder="Filter By"
            options={options}
            variant="default"
            w="100%"
            value={selectedType}
          />
        </Box>
      </HStack>
      <HStack background="var(--grey-200)" padding="1rem" w="100%">
        <SlideRange handleClick={(value) => setSliderValue(value)} />
      </HStack>{" "}
      {isFetching && page === 1 && (
        <Center w="100%" h="calc(100vh - 375px)">
          <Spinner />
        </Center>
      )}{" "}
      {!!activities.length &&
      !!activities.filter((item) => !!item.details).length &&
      clickedRow ? (
        <div
          id="scrollableDivAll"
          style={{ height: "calc(100vh - 415px)", overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={activities.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={activities.length < totalCount}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDivAll"
          >
            <VStack
              alignItems="flex-start"
              gap="1rem"
              padding="1rem"
              background="var(--grey-200)"
            >
              {activities?.map((item) => {
                return getActivityComponent(item);
              })}
            </VStack>
          </InfiniteScroll>
        </div>
      ) : (
        !isLoading && !isFetching && <NoItem />
      )}
    </>
  );
}

export default AllActivity;
