"use client";

import { Box, Button, HStack, Text } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Input, PaginatedTable } from "../../../components/common";
import { SearchIcon } from "../../../components/icons/svg";
import { capitalize } from "../../../utils";
import { fetchContactListing } from "../../../utils/api/dms-equity-mining";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

export default function CommunicationLogs(props: any) {
  const { activeIndex, setActiveModal, clickedRow } = props;
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [searchValue, setSearchValue] = useState<string>("");

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchContactListing",
      {
        page,
        per_page: perPage,
        q: searchValue,
      },
      dId,
      clickedRow?.id,
    ],
    queryFn: fetchContactListing,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const salesHeader = [
    { id: 1, value: "Full Name", label: "fullName" },
    {
      id: 2,
      value: clickedRow?.message_campaigns_id ? "Phone Number" : "Email",
      label: clickedRow?.message_campaigns_id ? "phoneNo" : "email",
    },
    { id: 3, value: "Year", label: "year" },
    { id: 4, value: "Make", label: "make" },
    { id: 5, value: "Model", label: "model" },
    { id: 6, value: "Sold Date", label: "soldDate" },
    { id: 7, value: "Type", label: "type" },
    {
      id: 8,
      value: "Original Term",
      label: "originalTerm",
    },
    {
      id: 14,
      value: "Estimated Remaining",
      label: "estimatedRemaining",
    },
    { id: 9, value: "Monthly Payment", label: "monthlyPayment" },
    { id: 10, value: "Estimated Current Payoff", label: "estimatedPayoff" },
    { id: 12, value: "Sales Person", label: "salesPerson" },
    { id: 13, value: "Status", label: "status" },
  ];
  const servicesHeader = [
    { id: 1, value: "Full Name", label: "fullName" },
    { id: 2, value: "Phone Number", label: "phoneNo" },
    { id: 3, value: "Year", label: "year" },
    { id: 4, value: "Make", label: "make" },
    { id: 5, value: "Model", label: "model" },
    { id: 6, value: "Last closed R.O. ", label: "closedRO" },
    { id: 7, value: "Status", label: "status" },
  ];

  const salesData = data?.data?.sales_contacts?.map((item: any) => {
    return {
      fullName: item.full_name,
      phoneNo: item.phone_number,
      email: item.email,
      year: item.year,
      make: item.make,
      model: item.model,
      soldDate: item.sold_date,
      type: item.type,
      originalTerm: item.original_term,
      estimatedRemaining: item.estimated_remaining,
      monthlyPayment: item.monthly_payment,
      estimatedPayoff: item.estimated_current_payoff,
      salesPerson: item.sales_person,
      status: capitalize(item.status),
    };
  });
  const servicesData = data?.data?.services_contacts?.map((item: any) => {
    return {
      fullName: item.full_name,
      phoneNo: item.phone_number,
      year: item.year,
      make: item.make,
      model: item.model,
      closedRO: item.closed_ro,
      status: item.status ? "Sent" : "Not Sent",
    };
  });

  return (
    <>
      <HStack padding="1rem" w="100%" background="white">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => setActiveModal(null)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <HStack
        background="white"
        padding="1.5rem"
        w="100%"
        justifyContent="space-between"
      >
        <HStack w="100%">
          <Input
            maxW="320px"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
          />
        </HStack>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="flex-end"
        padding="1rem"
        w="100%"
      >
        <Text textStyle="caption" color="var(--grey-600)">
          Contacts Found: {data?.data?.contact_count}
        </Text>
      </HStack>
      <PaginatedTable
        header={activeIndex === 1 ? servicesHeader : salesHeader}
        data={activeIndex === 1 ? servicesData : salesData}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPage(1);
          setPerPage(Number(value?.label));
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.contact_count}
        isLoadingData={isLoading}
      />
    </>
  );
}
