import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import BrandListing from "../../../components/GlobalDashboard/BrandListing/BrandListing";
import CampaignListing from "../../../components/GlobalDashboard/CampaignListing/CampaignListing";
import { useLocation } from "react-router-dom";

const statusMap = {
  not_submitted: 0,
  approved: 1,
  pending: 2,
  rejected: 3,
};

export default function CampaignRegisterationPage() {
  const location = useLocation();
  const { state } = location as { state: { type?: string; status?: keyof typeof statusMap } };
  
  const mainTabDefaultValue = state?.type === "brand" ? 0 : state?.type === "campaign" ? 1 : 0;
  const subTabDefaultValue = state?.status ? statusMap[state?.status] ?? 0 : 0;
  
  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          isLazy
          defaultIndex={mainTabDefaultValue} 
        >
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Brand Registration</Tab>
            <Tab w="15rem">Campaign Registration</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              <Tabs variant="vertical" display="flex" height="100%" isLazy defaultIndex={mainTabDefaultValue === 0 ? subTabDefaultValue : 0}>
                <TabList w="15rem">
                  <Text
                    p="1rem"
                    textStyle="h6"
                    fontWeight="600"
                    color="var(--grey-500)"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    Brand Registration
                  </Text>
                  <Tab>Not Submitted</Tab>
                  <Tab>Approved</Tab>
                  <Tab>Pending</Tab>
                  <Tab>Rejected</Tab>
                </TabList>
                <TabPanels defaultValue={state?.status}>
                  <TabPanel>
                    <BrandListing status="not_submitted" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="approved" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="pending" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="rejected" />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>

            <TabPanel w="100%" h="100%">
              <Tabs variant="vertical" display="flex" height="100%" isLazy defaultIndex={mainTabDefaultValue === 1 ? subTabDefaultValue : 0}>
                <TabList width="15rem">
                  <Text
                    p="1rem"
                    textStyle="h6"
                    fontWeight="600"
                    color="var(--grey-500)"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    Campaign Registration
                  </Text>
                  <Tab>Not Submitted</Tab>
                  <Tab>Approved</Tab>
                  <Tab>Pending</Tab>
                  <Tab>Rejected</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <CampaignListing status="not_submitted" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="approved" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="pending" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="rejected" />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
