import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from "@chakra-ui/react"

export default ({ isOpen, setModalOpen, reason, headerText, bodyText, setReason, onSubmit }: any) => {
    return (
        <Modal isOpen={isOpen} onClose={() => setModalOpen(false)} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{headerText}</ModalHeader>
              <ModalBody>
                <Text>{bodyText}</Text>
                <Textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter reason"
                  mt={4}
                  maxLength={100}
                />
              </ModalBody>
              <ModalFooter>
                <Button variant="outline" onClick={() => setModalOpen(false)} marginRight="1rem">
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={onSubmit}
                  isDisabled={!reason.trim()}
                >
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
    )
}