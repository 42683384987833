import { Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { fetchHome } from "../../../utils/api/guest-mode";

export default function GuestMode() {
  const { id, dId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["fetchHome", { contact_uuid: id }, dId],
    queryFn: fetchHome,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
  });

  return (
    <VStack w="100%" mt="2.5rem" px={{ base: "1rem", md: "0" }}>
      <VStack
        w={{ base: "100%", md: "60%" }}
        alignItems="flex-start"
        background="#fff"
        borderRadius="0.5rem"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Text
          textStyle={{ md: "h2", base: "h4" }}
          textAlign="left"
          fontWeight="400"
          color="var(--primary-300)"
        >
          Welcome to {data?.data?.dealership_name}'s Private Portal!
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          This private and secure portal is designed to give you full control
          over your car-buying experience. Whether you’re exploring vehicles to
          buy or lease, checking your credit, valuing your current car or truck,
          or simply browsing our inventory, it’s all conveniently at your
          fingertips.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          To protect your privacy, we ask that you create a profile with us.
          Don’t worry—your information is safe and will never be sold or shared.
          Your activity in the portal is securely saved to your profile, so you
          can pause anytime and pick up right where you left off, stress-free.
          When you’re ready to visit the dealership, your saved preferences will
          help us make your experience quick and seamless.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          The menu links provide easy access to everything you can do here, and
          you’re free to navigate in whatever order you choose. If you ever need
          assistance or have questions, our team is just a call, email, or text
          away. You’ll find our contact details at the bottom of every page.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          Ready to begin? Exploring your options has never been easier—give it a
          try today!
        </Text>
      </VStack>
      {isLoading && <Spinner />}
    </VStack>
  );
}
